import axios from 'axios'
import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import useAiPolling from 'pared/Routes/renderer/chatBox/hooks/useAiPolling'
import useGroupSelector from 'pared/components/GroupSelector/hooks/useGroupSelector'
import {
  AI_FEEDBACK_EXPO_FAILED_TO_ANSWER,
  AI_FEEDBACK_USER_DISSATISFIED,
  AI_FEEDBACK_USER_SATISFIED,
} from 'pared/constants'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrandSettings } from 'pared/customer'
import ExpoLogo from 'pared/images/Expo/expo-icon-100x100.png'
import LocationDeliverySummary from 'pared/pages/Delivery/Summary'
import SalesPerLaborHourChart from 'pared/pages/v2/Salesmanship/SalesPerLaborHour'
import { getUser } from 'pared/utils/user'

import Summary from '../Summary'
import Table from '../Table'
import Trend from '../Trend'
import {
  FROM_EXPO,
  FROM_USER,
  NUMBER_OF_PREVIOUS_QUESTIONS_TO_CONSIDER_TOGETHER,
  PAGE_PADDING_LEFT,
  REQUEST_COGS_ANALYSIS,
  REQUEST_DAY_PART_ANALYSIS,
  REQUEST_DAY_PART_LABOR,
  REQUEST_LABOR_ANALYSIS,
  REQUEST_POSITION_LABOR,
  REQUEST_REVENUE_CENTER_ANALYSIS,
  REQUEST_SALES_ANALYSIS,
} from '../constants'
import useConfig from '../hooks/useConfig'
import { IMessage } from '../type'
import CannedQuestions from './CannedQuestions'
import ChatInput from './ChatInput'
import formatDate from './formatDate'
import generateAnswerMessagesFromExpoAnswer from './generateAnswerMessagesFromExpoAnswer'
import handleCannedQuestionAnswer from './responseHandler/handleCannedQuestionAnswer'
import handleDeliveryKpiRankings from './responseHandler/handleDeliveryKpiRankings'
import handleDeliveryKpiTrends from './responseHandler/handleDeliveryKpiTrends'
import handleDeliveryStoreEmployeeKpis from './responseHandler/handleDeliveryStoreEmployeeKpis'
import handleErrors from './responseHandler/handleErrors'
import handleForecastedResults from './responseHandler/handleForecastedResults'
import handleFssKpiRankings from './responseHandler/handleFssKpiRankings'
import handleFssKpiValues from './responseHandler/handleFssKpiValues'
import handleGuestKpiRankings from './responseHandler/handleGuestKpiRankings'
import handleGuestKpiTrends from './responseHandler/handleGuestKpiTrends'
import handleGuestSuggestions from './responseHandler/handleGuestSuggestions'
import handleInventoryKpiValues from './responseHandler/handleInventoryKpiValues'
import handleInventoryLocationSuggestions from './responseHandler/handleInventoryLocationSuggestions'
import handleKpiAnalyzerAnswers from './responseHandler/handleKpiAnalyzerAnswers'
import handleLossPreventionKpiTrends from './responseHandler/handleLossPreventionKpiTrends'
import handleLossPreventionKpiValues from './responseHandler/handleLossPreventionKpiValues'
import handleLossPreventionStoreEmployeeKpis from './responseHandler/handleLossPreventionStoreEmployeeKpis'
import handlePmixKpiValues from './responseHandler/handlePmixKpiValues'
import handlePurchaseTrends from './responseHandler/handlePurchaseTrends'
import handleSalesmanshipEmployeeKpis from './responseHandler/handleSalesmanshipEmployeeKpis'
import handleSalesmanshipKpiTrends from './responseHandler/handleSalesmanshipKpiTrends'
import handleSalesmanshipKpiValues from './responseHandler/handleSalesmanshipKpiValues'

const ChatboxWrapper = styled.div``

const ButtonsContainer = styled.div`
  font-family: Lexend-Regular;
  display: flex;
  flex-direction: column;
  padding: 10px ${PAGE_PADDING_LEFT + 20}px;
`

const ButtonRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 30px;
`

const OptionButton = styled.button<{ isSelected: boolean }>`
  font-family: Lexend-Regular;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  background-color: ${(props) => (props.isSelected ? '#007bff' : '#eeeeee')};
  color: ${(props) => (props.isSelected ? '#fff' : '#000')};
  border: none;
  border-radius: 5px;
  white-space: nowrap;
  display: inline-block;

  &:hover {
    background-color: #0056b3;
    color: #fff;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

const OptionButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
`

const DropdownContainer = styled.div`
  position: absolute;
  top: 110%;
  left: 0;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 5px 0;
  z-index: 999;
`

const DropdownItem = styled.div`
  font-family: Lexend-Regular;
  font-size: 16px;
  padding: 10px 15px;
  color: #000;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: #eeeeee;
  }
`

const ButtonGroupLabel = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
`

const MessageContainer = styled.div<{ messageFrom?: string }>`
  ${(props) => {
    if (props.messageFrom === FROM_USER) {
      return `background-color: #F1F1F1;`
    }
    return ''
  }}
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 30px 0;
`

const MessageFromContainer = styled.div`
  margin: 0 0 0 ${PAGE_PADDING_LEFT}px;
  width: 80px;

  @media ${MOBILE_WIDTH} {
    margin: 0 0 0 20px;
  }
`

const UserMessageContent = styled.div`
  padding: 8px 0 0 0;
  color: ${COLORS.Chalkboard};
  font-size: 18px;
  font-family: Lexend-Regular;
  font-style: normal;
  font-weight: 400;

  @media ${MOBILE_WIDTH} {
    width: 100%;
    padding: 8px 20px 0 0;
  }
`

const ExpoMessageContent = styled.div`
  padding: 8px 0 0 0;
  color: ${COLORS.Chalkboard};
  font-size: 18px;
  font-family: Lexend-Regular;
  font-style: normal;
  font-weight: 400;

  @media ${MOBILE_WIDTH} {
    margin: 0 20px;
    overflow: hidden;
  }
`

const TextOnlyMessage = styled.div`
  max-width: 800px;
`

const UserInitialIcon = styled.div`
  width: 45px;
  height: 45px;
  background: #f5b332;
  color: ${COLORS.Salt};
  font-size: 25px;
  font-family: Lexend-Regular;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
  text-align: center;
`

const LoadingStatus = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: italic;
  font-weight: 700;
  color: ${COLORS.Steel};
  padding: 25px 0 25px ${PAGE_PADDING_LEFT + 20}px;
`

const LogoImg = styled.img`
  height: 40px;
  width: 40px;
`

const UserFeedbackForAiAnswer = styled.div`
  padding: 20px 0 0 0;
  font-size: 14px;
  font-style: italic;

  span {
    color: ${COLORS.Link};
    padding: 0 10px;
    cursor: pointer;
  }
`

interface IMessagePaddingContent {
  question: string
  gptAnswer: any
  newId?: string
  cachedRequestId?: string
  cachedRequestProcessingUuid?: string
}

function padMsg(
  originalMessage: IMessage,
  {
    question,
    gptAnswer,
    newId,
    cachedRequestId,
    cachedRequestProcessingUuid,
  }: IMessagePaddingContent,
) {
  const paddedMsg = {
    ...originalMessage,
    question,
    gptAnswer,
    cachedRequestId,
    cachedRequestProcessingUuid,
  }
  if (newId) {
    paddedMsg.id = newId
  }
  return paddedMsg
}

interface IFeedback {
  feedback: string
}

interface LocationGroupCache {
  messages: IMessage[]
  userQuestions: string[]
  lastGptAnswer: any
}

// FIX ME: have a risk to conflict with apollo cache
const locationGroupCaches: { [locationGroupId: string]: LocationGroupCache } =
  {}

type UseGroupSelectorReturn = ReturnType<typeof useGroupSelector>
interface ChatBoxProps extends UseGroupSelectorReturn {}

const Chatbox: React.FC<ChatBoxProps> = ({ options, locationGroupId }) => {
  const config = useConfig()
  const showSalesAnalysis = config.chatBox?.hasSalesAnalysisButtonForCorp
  const showLaborAnalysis = config.chatBox?.hasLaborAnalysisButtonForCorp
  const showCogsAnalysis = config.chatBox?.hasCogsAnalysisButtonForCorp
  const [messagesBeforePolling, setMessagesBeforePolling] = useState<
    IMessage[]
  >([])
  const [messages, setMessages] = useState<IMessage[]>([])
  const [inputValue, setInputValue] = useState('')
  const [selectedSalesAnalysisOption, setSelectedSalesAnalysisOption] =
    useState<string>('')
  const [selectedLaborAnalysisOption, setSelectedLaborAnalysisOption] =
    useState<string>('')
  const [selectedDateRangeOption, setSelectedDateRangeOption] =
    useState<string>('')
  const [selectedCogsAnalysisOption, setSelectedCogsAnalysisOption] =
    useState<string>('')
  const [locationGroupName, setLocationGroupName] = useState<string | null>(
    null,
  )
  const [activeDropdownOption, setActiveDropdownOption] = useState<
    string | null
  >(null)
  const buttonsRowRef = useRef<HTMLDivElement | null>(null)
  const userQuestionsRef = useRef<string[]>([])
  const lastGptAnswerRef = useRef<any>(null)

  const specialRequestsMapping: { [key: string]: string } = {
    Sales: REQUEST_SALES_ANALYSIS,
    'Revenue Center': REQUEST_REVENUE_CENTER_ANALYSIS,
    Daypart: REQUEST_DAY_PART_ANALYSIS,
    Labor: REQUEST_LABOR_ANALYSIS,
    'Daypart Labor': REQUEST_DAY_PART_LABOR,
    Position: REQUEST_POSITION_LABOR,
    COGS: REQUEST_COGS_ANALYSIS,
  }

  useEffect(() => {
    const groupId = locationGroupId || 'default'

    if (locationGroupCaches[groupId]) {
      setMessages(locationGroupCaches[groupId].messages)
      userQuestionsRef.current = locationGroupCaches[groupId].userQuestions
      lastGptAnswerRef.current = locationGroupCaches[groupId].lastGptAnswer
    } else {
      locationGroupCaches[groupId] = {
        messages: [],
        userQuestions: [],
        lastGptAnswer: null,
      }
      setMessages([])
      userQuestionsRef.current = []
      lastGptAnswerRef.current = null
    }

    setSelectedSalesAnalysisOption('')
    setSelectedLaborAnalysisOption('')
    setSelectedCogsAnalysisOption('')
    setSelectedDateRangeOption('')
  }, [locationGroupId])

  const {
    isPolling,
    stagedInfo,
    aiResponse,
    requestId,
    requestProcessingUuid,
    startPolling,
    stopPolling,
  } = useAiPolling()

  useEffect(() => {
    function getTextById(id: string) {
      const item = options.find((option) => option.id === id)
      return item ? item.text : null
    }
    const name = locationGroupId ? getTextById(locationGroupId) : null
    setLocationGroupName(name)
  }, [locationGroupId, options])

  const salesAnalysisOptions = ['Sales', 'Revenue Center', 'Daypart']
  const laborAnalysisOptions = ['Labor', 'Position', 'Daypart Labor']
  const cogsAnalysisOptions = ['COGS']
  const dateRangeOptions = ['90 days', '30 days', '7 days', 'yesterday']

  const combinedAnalysisOptions = [
    ...(showSalesAnalysis ? salesAnalysisOptions : []),
    ...(showLaborAnalysis ? laborAnalysisOptions : []),
    ...(showCogsAnalysis ? cogsAnalysisOptions : []),
  ]

  const autoSubmitQuestion = (
    questionText: string,
    specialRequest?: string,
  ) => {
    setInputValue(questionText)
    handleFormSubmit(questionText, specialRequest)
  }

  const handleOptionClick = (option: string) => {
    setActiveDropdownOption((prev) => (prev === option ? null : option))
  }

  const handleDateRangeClick = (analysisOption: string, dateOption: string) => {
    if (salesAnalysisOptions.includes(analysisOption)) {
      setSelectedSalesAnalysisOption(analysisOption)
      setSelectedLaborAnalysisOption('')
      setSelectedCogsAnalysisOption('')
    } else if (laborAnalysisOptions.includes(analysisOption)) {
      setSelectedLaborAnalysisOption(analysisOption)
      setSelectedSalesAnalysisOption('')
      setSelectedCogsAnalysisOption('')
    } else if (cogsAnalysisOptions.includes(analysisOption)) {
      setSelectedCogsAnalysisOption(analysisOption)
      setSelectedSalesAnalysisOption('')
      setSelectedLaborAnalysisOption('')
    }
    setSelectedDateRangeOption(dateOption)
    setActiveDropdownOption(null)

    const timePeriodText =
      dateOption === 'yesterday'
        ? 'for yesterday'
        : `over the past ${dateOption}`

    let message = ''

    if (salesAnalysisOptions.includes(analysisOption)) {
      message = `${analysisOption} Analysis${
        locationGroupName ? ` for ${locationGroupName}` : ''
      } ${timePeriodText}`
    } else if (laborAnalysisOptions.includes(analysisOption)) {
      let analysisName = ''
      if (analysisOption === 'Labor') {
        analysisName = 'Labor Analysis'
      } else if (analysisOption === 'Daypart Labor') {
        analysisName = 'Daypart Labor'
      } else if (analysisOption === 'Position') {
        analysisName = 'Position Labor'
      }
      message = `${analysisName}${
        locationGroupName ? ` for ${locationGroupName}` : ''
      } ${timePeriodText}`
    } else if (analysisOption === 'COGS') {
      message = `COGS Analysis${
        locationGroupName ? ` for ${locationGroupName}` : ''
      } this period`
    }

    const specialRequest = specialRequestsMapping[analysisOption] || ''
    autoSubmitQuestion(message, specialRequest)
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        activeDropdownOption &&
        buttonsRowRef.current &&
        !buttonsRowRef.current.contains(event.target as Node)
      ) {
        setActiveDropdownOption(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [activeDropdownOption])

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight)
  }, [])

  const setAndCacheMessages = (newMessages: IMessage[]) => {
    const groupId = locationGroupId || 'default'

    if (!locationGroupCaches[groupId]) {
      locationGroupCaches[groupId] = {
        messages: [],
        userQuestions: [],
        lastGptAnswer: null,
      }
    }

    locationGroupCaches[groupId].messages = [...newMessages]
    setMessages(newMessages)
  }

  const { brand, brandId, brandLocationGroupId } = getBrandSettings()
  const user = getUser()
  const userInitial = (
    (user?.firstName || '').substring(0, 1) || 'u'
  ).toUpperCase()
  const userEmail = (user.email || '').trim()

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  async function recordUserFeedback(
    messageFromExpo: IMessage,
    { feedback }: IFeedback,
    shouldUpdateUi: boolean,
  ) {
    if (shouldUpdateUi) {
      const allMessages = [...messages]
      const messageCount = allMessages.length
      for (let mi = messageCount - 1; mi >= 0; --mi) {
        const msg = allMessages[mi]
        if (msg.id === messageFromExpo.id) {
          allMessages[mi] = {
            ...msg,
            feedback,
          }
          break
        }
      }
      setAndCacheMessages(allMessages)

      // Also update "messagesBeforePolling" to make sure the UI display correctly while getting the next polling result
      const allMessagesBeforePolling = [...messagesBeforePolling]
      const messageCountBeforePolling = allMessagesBeforePolling.length
      for (let mi = messageCountBeforePolling - 1; mi >= 0; --mi) {
        const msg = allMessagesBeforePolling[mi]
        if (msg.id === messageFromExpo.id) {
          allMessagesBeforePolling[mi] = {
            ...msg,
            feedback,
          }
          break
        }
      }
      setMessagesBeforePolling(allMessagesBeforePolling)
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_BE_BASE_URL}/record_feedback_for_ai_answer`,
        {
          brandId,
          userEmail,
          feedback,
          question: messageFromExpo.question,
          gptAnswer: messageFromExpo.gptAnswer || null,
          customerBrandCode: brand,
          userId: user.userId,
          userJwt: user.jwt,
          cachedRequestId: messageFromExpo.cachedRequestId || '',
          cachedRequestProcessingUuid:
            messageFromExpo.cachedRequestProcessingUuid || '',
        },
        {
          timeout: 60000,
        },
      )
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const handleFormSubmit = async (
    eventOrQuestion: FormEvent<HTMLFormElement> | string,
    specialRequest?: string,
  ) => {
    stopPolling()
    const groupId = locationGroupId || 'default'

    let question = inputValue.trim()

    if (typeof eventOrQuestion === 'string') {
      question = eventOrQuestion
    } else {
      eventOrQuestion.preventDefault()
      eventOrQuestion.stopPropagation()
    }

    if (!question) {
      return
    }

    const newMessage: IMessage = {
      id: uuidv4(),
      from: FROM_USER,
      content: question,
    }

    let newMessages = [...messages, newMessage]
    setAndCacheMessages(newMessages)
    setMessagesBeforePolling(newMessages)
    setInputValue('')

    const isExpoUser =
      userEmail.endsWith('@getexpo.com') || userEmail.endsWith('@pared.com')

    const previousQuestions = userQuestionsRef.current.slice(
      -NUMBER_OF_PREVIOUS_QUESTIONS_TO_CONSIDER_TOGETHER,
    )

    userQuestionsRef.current.push(question)
    locationGroupCaches[groupId].userQuestions = [...userQuestionsRef.current]
    startPolling({
      userId: user.userId,
      userJwt: user.jwt,
      requestHandler: 'aiStagedQuestionAnswering',
      requestParams: {
        brandId,
        isExpoUser,
        userEmail,
        question,
        previousQuestions,
        customerBrandCode: brand,
        userId: user.userId,
        lastGptAnswer: lastGptAnswerRef.current,
        ...(specialRequest && { specialRequest }),
      },
    })
  }

  function handleCannedQuestionClicked(questionText: string) {
    setInputValue(questionText)
    window.scrollTo(0, document.body.scrollHeight)
  }

  useEffect(() => {
    if (!aiResponse) {
      return
    }

    const expoAnswer = aiResponse?.expoAnswer || null
    const gptAnswer = aiResponse?.gptAnswer || null
    const error = aiResponse?.error || null

    if (!isPolling && gptAnswer) {
      const groupId = locationGroupId || 'default'
      lastGptAnswerRef.current = gptAnswer
      locationGroupCaches[groupId].lastGptAnswer = gptAnswer
      setSelectedSalesAnalysisOption('')
      setSelectedLaborAnalysisOption('')
      setSelectedCogsAnalysisOption('')
      setSelectedDateRangeOption('')
    }

    const generatedAnswerMessages = generateAnswerMessagesFromExpoAnswer(
      expoAnswer,
      {
        brandLocationGroupId,
        handleCannedQuestionClicked,
        requestProcessingUuid,
      },
    )

    const currentQuestion =
      userQuestionsRef.current?.[userQuestionsRef.current?.length - 1] || ''
    if (generatedAnswerMessages.length > 0) {
      const newMessages = [
        ...messagesBeforePolling,
        ...generatedAnswerMessages.map((answerMessage, index: number) => {
          return padMsg(answerMessage, {
            gptAnswer,
            question: currentQuestion,
            newId: `${answerMessage.id}-${messagesBeforePolling.length}-${index}`,
            cachedRequestId: requestId,
            cachedRequestProcessingUuid: requestProcessingUuid,
          })
        }),
      ]
      setAndCacheMessages(newMessages)

      // The request is completed
      if (!isPolling) {
        setMessagesBeforePolling(newMessages)
      }
    } else if (expoAnswer || error) {
      const feedback = AI_FEEDBACK_EXPO_FAILED_TO_ANSWER
      const answerMessage = padMsg(
        {
          feedback,
          id: uuidv4(),
          from: FROM_EXPO,
          content: `We don't have the answer for that right now, but we're working on it.`,
        },
        {
          gptAnswer,
          question: currentQuestion,
          cachedRequestId: requestId,
          cachedRequestProcessingUuid: requestProcessingUuid,
        },
      )
      const newMessages = [...messagesBeforePolling, answerMessage]
      setAndCacheMessages(newMessages)
      setMessagesBeforePolling(newMessages)
      recordUserFeedback(answerMessage, { feedback }, false)
    }
  }, [isPolling, aiResponse])

  let stagedInfoContent = null
  if (stagedInfo && stagedInfo.length > 0) {
    stagedInfoContent = stagedInfo.map((paragraph: string, index: number) => {
      if ((paragraph || '').trim() === '') {
        return <div key={`${index}-empty-line`}>&nbsp;</div>
      } else {
        return (
          <div key={`${index}-${paragraph.substring(0, 10)}`}>{paragraph}</div>
        )
      }
    })
  }

  return (
    <ChatboxWrapper>
      {config.chatBox?.hasCannedQuestions ? (
        <CannedQuestions onQuestionClicked={handleCannedQuestionClicked} />
      ) : null}
      <div className="chatbox">
        {messages.map((message) => (
          <MessageContainer key={message.id} messageFrom={message.from}>
            <MessageFromContainer>
              {message.from === FROM_EXPO ? (
                <LogoImg src={ExpoLogo} alt="Expo Logo" />
              ) : (
                <UserInitialIcon>{userInitial}</UserInitialIcon>
              )}
            </MessageFromContainer>
            {message.from === FROM_EXPO ? (
              <ExpoMessageContent>
                <div>{message.content}</div>
                {(isPolling &&
                  requestProcessingUuid ===
                    message.cachedRequestProcessingUuid) ||
                message.isFeedbackVisible === false ||
                message.feedback ===
                  AI_FEEDBACK_EXPO_FAILED_TO_ANSWER ? null : (
                  <UserFeedbackForAiAnswer>
                    {message.feedback === AI_FEEDBACK_USER_SATISFIED ? (
                      'Thank you for your feedback!'
                    ) : message.feedback === AI_FEEDBACK_USER_DISSATISFIED ? (
                      'Thank you for your feedback! We will continue to make improvements to better meet your needs as soon as possible.'
                    ) : (
                      <>
                        Did this answer your question?
                        <span
                          onClick={() => {
                            recordUserFeedback(
                              message,
                              {
                                feedback: AI_FEEDBACK_USER_SATISFIED,
                              },
                              true,
                            )
                          }}
                        >
                          Yes
                        </span>
                        or
                        <span
                          onClick={() => {
                            recordUserFeedback(
                              message,
                              {
                                feedback: AI_FEEDBACK_USER_DISSATISFIED,
                              },
                              true,
                            )
                          }}
                        >
                          No
                        </span>
                      </>
                    )}
                  </UserFeedbackForAiAnswer>
                )}
              </ExpoMessageContent>
            ) : (
              <UserMessageContent>
                <TextOnlyMessage>{message.content}</TextOnlyMessage>
              </UserMessageContent>
            )}
          </MessageContainer>
        ))}
      </div>

      {isPolling ? (
        <LoadingStatus>
          {stagedInfoContent
            ? stagedInfoContent
            : 'Loading... this may take a few moments'}
        </LoadingStatus>
      ) : null}

      {(showSalesAnalysis || showLaborAnalysis || showCogsAnalysis) && (
        <ButtonsContainer>
          <ButtonGroupLabel>
            {showSalesAnalysis && !showLaborAnalysis
              ? 'What are my Strengths and Opportunities in:'
              : showLaborAnalysis && !showSalesAnalysis
              ? 'What is my Labor Performance:'
              : 'What are my Strengths, Opportunities and Labor Performance:'}
          </ButtonGroupLabel>

          <ButtonRow ref={buttonsRowRef}>
            {combinedAnalysisOptions.map((option) => (
              <OptionButtonWrapper key={option}>
                <OptionButton
                  disabled={isPolling}
                  isSelected={
                    selectedSalesAnalysisOption === option ||
                    selectedLaborAnalysisOption === option ||
                    selectedCogsAnalysisOption === option
                  }
                  onClick={() => {
                    if (option === 'COGS') {
                      const specialRequest = specialRequestsMapping['COGS']
                      const message = `COGS Analysis${
                        locationGroupName ? ` for ${locationGroupName}` : ''
                      } this period`
                      autoSubmitQuestion(message, specialRequest)
                      setSelectedCogsAnalysisOption(option)
                      setActiveDropdownOption(null)
                    } else {
                      handleOptionClick(option)
                    }
                  }}
                >
                  {option}
                </OptionButton>
                {option !== 'COGS' && activeDropdownOption === option && (
                  <DropdownContainer>
                    {dateRangeOptions.map((dateOption) => (
                      <DropdownItem
                        key={dateOption}
                        onClick={() => handleDateRangeClick(option, dateOption)}
                      >
                        {dateOption}
                      </DropdownItem>
                    ))}
                  </DropdownContainer>
                )}
              </OptionButtonWrapper>
            ))}
          </ButtonRow>
        </ButtonsContainer>
      )}

      <ChatInput
        isLoading={isPolling}
        inputValue={inputValue}
        handleInputChange={handleInputChange}
        handleFormSubmit={handleFormSubmit}
      />
    </ChatboxWrapper>
  )
}

export default Chatbox
