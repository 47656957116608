import { useMemo, useState } from 'react'

export interface IDataType {
  farwestFinancialSalesCharts?: {
    options: {
      id: string
      parentId: string
      displayName: string
    }[]
    value: string[][]
  }
}

const useFinancialSalesCharts = (): IDataType => {
  const financialSalesChartsHook = useState([['net_sales']])

  return {
    farwestFinancialSalesCharts: useMemo(() => {
      const options = [
        {
          id: 'net_sales',
          parentId: 'root',
          displayName: 'Total',
        },
        {
          id: 'olo_take_out',
          parentId: 'root',
          displayName: 'Online (Pick Up)',
        },
        {
          id: 'online_dispatch_sales',
          parentId: 'root',
          displayName: 'Online (Dispatch)',
        },
        {
          id: 'walk_in_dine_in',
          parentId: 'root',
          displayName: 'Dine In - Walk In',
        },
        {
          id: 'walk_in_to_go',
          parentId: 'root',
          displayName: 'Take Out - Walk In',
        },
        {
          id: 'call_in_to_go',
          parentId: 'root',
          displayName: 'Take Out - Call in/Call Center',
        },
      ]

      return {
        value: financialSalesChartsHook[0],
        options: options,
        onChange: financialSalesChartsHook[1],
      }
    }, [financialSalesChartsHook]),
  }
}

export default useFinancialSalesCharts
