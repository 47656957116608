import buildItemMetricValuesHook from '../utils/buildItemMetricValuesHook'

export const dhWbListItemMetricValuesConfigs = {
  actualCogs: 'price',
  theoreticalCogs: 'price',
  totalNetSales: 'price',
  actualCogsPercent: 'percent',
  theoreticalCogsPercent: 'percent',
  cogsVariance: 'price',
  cogsVariancePercent: 'percent',
} as const

const useDhWbListItemMetricValues = buildItemMetricValuesHook({
  metrics: [
    'actual_cogs',
    'theoretical_cogs',
    'total_net_sales',
    'actual_cogs_percent',
    'theoretical_cogs_percent',
    'cogs_variance',
    'cogs_variance_percent',
  ],
  itemCategoryCodes: ['ALL_COGS_ITEMS'],
})

export default useDhWbListItemMetricValues
