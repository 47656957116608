import { getBrand } from 'pared/utils/brand'

interface IConfig {
  isSummaryHidden?: boolean
  isLaborPercentVisible?: boolean
}

export default (): IConfig => {
  const brand = getBrand()
  const defaultConfig = {
    isLaborPercentVisible: false,
  }

  switch (brand) {
    case 'mwb':
      return {
        isSummaryHidden: true,
        isLaborPercentVisible: true,
      }
    case 'burgerworks':
    case 'dh_wb':
      return {
        isSummaryHidden: false,
        isLaborPercentVisible: true,
      }

    default:
      return defaultConfig
  }
}
