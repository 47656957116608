import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { usePrevious } from 'react-use'

import useDate, {
  IDataType as IDateDataType,
  IOptionType as IDateOptionType,
} from '../common/date'
import { IGroupFilterDataType } from './types'
import useApi, { IApiKeyType } from './useApi'

interface IOptionType {
  key: IApiKeyType
  dateOptions?: IDateOptionType
}

export type { IGroupFilterDataType }
export type IVariablesType = { type: 'groupFilter' } & IOptionType

export interface IDataType extends IDateDataType {
  groupFilter?: {
    value: string[][]
    onChange: (ids: string[][]) => void
    options: {
      id: string
      parentId: string
      displayName: string
    }[]
    tableColumns:
      | Extract<
          IGroupFilterDataType,
          { type: 'listLocationGroup' | 'listLocation' }
        >['tableColumns']
      | []
    getInfo: <T extends IGroupFilterDataType['type']>(
      types: T[],
    ) => Extract<IGroupFilterDataType, { type: T }> | null
  }
}

export const tableConfigs = {
  [`<%- JSON(groupFilter?.tableColumns) %>`]: 'link',
  [`<%- JSON(groupFilter?.tableColumns.slice(0, 1)) %> `]: 'link',
  [`<%- JSON(groupFilter?.tableColumns.slice(1, 2)) %> `]: 'link',
} as const

const useGroupFilter = ({ key, dateOptions = {} }: IOptionType): IDataType => {
  const { date } = useDate(dateOptions)
  const { data, loading } = useApi(key, { date })
  const [value, setValue] = useState([] as string[][])
  const prevLoading = usePrevious(loading)
  const location = useLocation()
  const prevLocationPath = usePrevious(location.pathname)

  useEffect(() => {
    if (data) {
      if (prevLoading !== loading && !loading) setValue([data.defaultValue])
      else if (prevLocationPath !== location.pathname)
        setValue([data.defaultValue])
    }
  }, [loading, data, location])

  return {
    date,
    groupFilter: useMemo((): IDataType['groupFilter'] => {
      const id = value[0]?.slice(-1)[0]
      const selectedValue = data?.values.find((v) => v.id === id) as
        | IGroupFilterDataType
        | undefined

      return {
        value,
        onChange: setValue,
        options: data?.values || [],
        tableColumns:
          selectedValue && 'tableColumns' in selectedValue
            ? selectedValue.tableColumns
            : [],
        getInfo: <T extends IGroupFilterDataType['type']>(types: T[]) =>
          selectedValue && types.includes(selectedValue.type as T)
            ? (selectedValue as Extract<IGroupFilterDataType, { type: T }>)
            : null,
      }
    }, [data, value, setValue]),
  }
}

export const hooks = [['groupFilter', useGroupFilter]]
