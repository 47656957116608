import { useVariables } from '../../../variables'
import buildMetricTrendsHook from '../utils/buildMetricTrendsHook'

export const trendLocationGroupLossPreventionConfigs = {
  date: 'string',
  lossPreventionCompsVoids: 'price',
  lossPreventionCompsDeletes: 'price',
  lossPreventionCompsOverrings: 'price',
  lossPreventionCompsRefunds: 'price',
  lossPreventionCheckCountVoids: 'number',
} as const

const useCommonMetricValuesHook = buildMetricTrendsHook({
  groupFilterType: 'corporate',
  metrics: {
    groups: [
      'Expo Module - Loss Prevention Comps',
      'Expo Module - Loss Prevention Check Count',
    ],
  },
})

const useTrendLocationGroupLossPreventions = () => {
  const { variables } = useVariables()
  const dateRange = variables.lastTimeRange?.value[0][0] || ''

  return useCommonMetricValuesHook(dateRange)
}

export default useTrendLocationGroupLossPreventions
