import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { usePrevious } from 'react-use'

import { IColumnsType } from '../types'

export interface ISortedFieldOptionType<Data> {
  key: keyof Data | null
  direction: 'ascend' | 'descend'
}

export interface ISortedFieldReturnType<Data> {
  sortedField: ISortedFieldOptionType<Data>
  setSortedField: (options: ISortedFieldOptionType<Data>) => void
}

const useSortedField = <Data>(
  defaultSortedField: keyof Data | null,
  mergedBody: IColumnsType<Data>[],
  defaultSortingDirection: 'ascend' | 'descend' = 'descend',
): ISortedFieldReturnType<Data> => {
  const initialState = useMemo(
    () => ({
      key: defaultSortedField,
      direction: defaultSortingDirection,
    }),
    [defaultSortedField, defaultSortingDirection],
  )
  const [sortedField, setSortedField] =
    useState<ISortedFieldOptionType<Data>>(initialState)
  const prevInitialState = usePrevious(initialState)

  useEffect(() => {
    if (!_.isEqual(prevInitialState, initialState)) setSortedField(initialState)
  }, [initialState, setSortedField])

  useEffect(() => {
    const sortedKey = sortedField.key

    if (
      sortedKey &&
      sortedKey !== defaultSortedField &&
      !mergedBody.find(({ key }) => key === sortedKey)?.sorter
    )
      setSortedField({
        key: defaultSortedField,
        direction: 'descend',
      })
  }, [defaultSortedField, mergedBody, sortedField, setSortedField])

  return { sortedField, setSortedField }
}

export default useSortedField
