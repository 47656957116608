import { useMemo, useState } from 'react'

export interface IDataType {
  farwestFinancialCheckAverageCharts?: {
    options: {
      id: string
      parentId: string
      displayName: string
    }[]
    value: string[][]
  }
}

const useFinancialCheckAverageCharts = (): IDataType => {
  const farwestFinancialCheckAverageChartsHook = useState([['check_average']])

  return {
    farwestFinancialCheckAverageCharts: useMemo(() => {
      const options = [
        {
          id: 'check_average',
          parentId: 'root',
          displayName: 'Total',
        },
      ]

      return {
        value: farwestFinancialCheckAverageChartsHook[0],
        options: options,
        onChange: farwestFinancialCheckAverageChartsHook[1],
      }
    }, [farwestFinancialCheckAverageChartsHook]),
  }
}

export default useFinancialCheckAverageCharts
