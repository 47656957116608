import buildMetricTrendsHook from '../utils/buildMetricTrendsHook'

export const dhWbWeeklySalesConfigs = {
  date: 'string',
  netSalesWeeklyAverage: 'price',
} as const

const useCommonMetricValuesHook = buildMetricTrendsHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: ['net_sales_weekly_average'],
  },
})

const useDhWbWeeklySales = () => useCommonMetricValuesHook('business_week')

export default useDhWbWeeklySales
