import buildItemMetricValuesHook from '../utils/buildItemMetricValuesHook'

export const burgerworksListItemMetricValuesConfigs = {
  actualCogs: 'price',
} as const

const useburgerworksListItemMetricValues = buildItemMetricValuesHook({
  metrics: ['actual_cogs'],
  itemCategoryCodes: ['ALL_COGS_ITEMS'],
})

export default useburgerworksListItemMetricValues
