import React from 'react'
import styled from 'styled-components'

export interface IPropsType {
  children: React.ReactElement
  layout?: 'horizontal' | 'vertical' | 'space-between'
  gap?: string
  margin?: string
  maxWidth?: string
  minWidth?: string
}

export interface IConfigsType extends Omit<IPropsType, 'children'> {
  type: 'grid'
}

const Container = styled.div<Omit<IPropsType, 'children'>>`
  gap: ${({ gap }) => gap || '25px'};
  ${({ margin }) => (!margin ? '' : `margin: ${margin};`)}
  ${({ maxWidth }) => (!maxWidth ? '' : `max-width: ${maxWidth};`)}
  ${({ minWidth }) => (!minWidth ? '' : `min-width: ${minWidth};`)}
  ${({ layout = 'horizontal' }) => {
    switch (layout) {
      case 'horizontal':
        return 'display: flex'

      case 'space-between':
        return `
        display: flex;
        align-items: center;
        justify-content: space-between;
      `

      default:
        return ''
    }
  }}
`

const Grid = ({ children, ...props }: IPropsType) => (
  <Container {...props}>{children}</Container>
)

export default Grid
