import SendIcon from '@mui/icons-material/Send'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import { getBrandSettings } from 'pared/customer'
import { REQUEST_ASK_TABLE } from 'pared/pages/ExpoAi/constants'
import { getUser } from 'pared/utils/user'

import useCsvData, { ICsvDataArgsType } from '../downloadCsv/hooks/useCsvData'
import useItemV2 from '../downloadCsv/hooks/useItem'
import useAiPolling from './hooks/useAiPolling'

const ChatboxContainer = styled.div`
  font-family: 'Lexend-Regular';
  * {
    font-family: 'Lexend-Regular';
  }
`

const FormContainer = styled.form`
  display: flex;
  padding: 10px 0 10px 0;
  align-items: center;
`

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  width: 500px;
  margin-right: 10px;
`

const SelectWrapper = styled.div`
  font-family: 'Lexend-Regular';
  padding: 0 20px 0 10px;
`

const ButtonsContainer = styled.div`
  font-family: Lexend-Regular;
  display: flex;
  flex-direction: column;
  padding: 20px 0 10px 0;
`

const ButtonRow = styled.div`
  display: flex;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-left: 20px;
`

const ButtonGroupLabel = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
`

const ButtonWrapper = styled.div`
  font-family: Lexend-Regular;
  margin-right: 10px;
  padding: 10px 0;
`

const ButtonSmall = styled.button`
  font-family: Lexend-Regular;
  margin-right: 10px;
  padding: 10px 15px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  background-color: '#eeeeee';
  color: #000;

  &:hover {
    background-color: #0056b3;
    color: #fff;
  }
`

const SelectorContainer = styled.div`
  font-family: Lexend-Regular;
  padding: 0 0 10px 0;
`

const SelectWrapperSmall = styled.div`
  font-family: 'Lexend-Regular';
  padding: 0 20px 0 20px;
`

const SelectRow = styled.div`
  display: flex;
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
`

const SelectGroupLabel = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
`

const CategorySelect = styled(Select)`
  height: 40px;
` as typeof Select

const MenuItemSmall = styled(MenuItem)`
  padding: 8.5px;

  &.MuiSelect-select {
    padding: 8.5px;
  }
` as typeof MenuItem

const QuestionAnswerContainer = styled.div`
  padding: 20px;
  margin: 10px 0;
  background-color: ${COLORS.Ash};
`

const QuestionText = styled.div`
  font-weight: bold;
`

const AnswerText = styled.pre`
  white-space: pre-wrap;
`

export interface IPropsType extends ICsvDataArgsType<any> {
  type: 'chatbox-for-table'
  hasMessageBar?: boolean
  hasCannedItemQuestions?: boolean
  hasCategoryMenu?: boolean
}

const ChatboxForTable: React.FC<IPropsType> = (props) => {
  const {
    api,
    fields = [],
    sortedBy,
    hasMessageBar,
    hasCannedItemQuestions,
    hasCategoryMenu,
  } = props
  const [question, setQuestion] = useState('')
  const [currentQuestion, setCurrentQuestion] = useState('')
  const [questionAnswer, setQuestionAnswer] = useState<{
    question: string
    answer: string
  }>({ question: '', answer: '' })
  const [selectedModel, setSelectedModel] = useState('Anthropic')
  const [selectedCategory, setSelectedCategory] = useState('None')
  const [startDate, setStartDate] = useState<string | undefined>(undefined)
  const [endDate, setEndDate] = useState<string | undefined>(undefined)
  const [locationGroupIds, setLocationGroupIds] = useState<
    number[] | undefined
  >(undefined)
  const [step, setStep] = useState(0)
  const [isAiLoading, setIsAiLoading] = useState(false)

  const { brand, brandId } = getBrandSettings()
  const user = getUser()
  const userEmail = (user.email || '').trim()

  const { data, loading } = useItemV2({ startDate, endDate, locationGroupIds })

  //FIXME: Maybe we can directly use the data from useItemV2
  const csvData = useCsvData({ api, fields, sortedBy }, data)

  const csvDataAsJson: Record<string, any>[] = useMemo(() => {
    if (csvData && csvData.length > 0) {
      const headers = csvData[0]
      const rows = csvData.slice(1)
      return rows.map((row) => {
        const obj: Record<string, any> = {}
        row.forEach((value, index) => {
          obj[headers[index]] = value
        })
        return obj
      })
    } else {
      return []
    }
  }, [csvData])
  //FIXME END

  const {
    isPolling,
    stagedInfo,
    aiResponse,
    requestId,
    requestProcessingUuid,
    startPolling,
    stopPolling,
  } = useAiPolling()

  const predefinedQuestions = [
    {
      label: 'Strong Items',
      question:
        'What are the 10 Items that are most important to the business that takes into consideration financial impact, operational efficiency, and strategic considerations while minimizing disruption to core business operations.',
    },
    {
      label: 'Weak Items',
      question:
        'Recommend 10 Items to remove from the menu that takes into consideration financial impact, operational efficiency, and strategic considerations while minimizing disruption to core business operations.',
    },
    {
      label: 'Category Analysis',
      question:
        'Please create an analysis that allows me to see all menu categories and see the cumulative impact on key metrics like total revenue loss, affected check count, operational impact and percentage of total checks. I want to be able to easily visualize how removing certain categories would affect the business.',
    },
    {
      label: 'Additional Items',
      question: `Rank Categories of Burger Box, Salads, For the Kids, Chicken, ATF/LTO, Burgers and Breakfast from highest to lowest Avg # Add'l Items Ordered.`,
    },
  ]

  const handleQuestionSubmit = (questionText: string, label?: string) => {
    if (!questionText) return

    setIsAiLoading(true)
    setQuestionAnswer({ question: label || questionText, answer: '' })
    setCurrentQuestion(questionText)
    setQuestion('') // Clear the input field if coming from user input

    const questionWithContext = `Here are CSV data that have been parsed into the JSON format: ${
      csvDataAsJson.length ? JSON.stringify(csvDataAsJson) : 'No data available'
    }. Based on the data, would you please answer this question: ${questionText}`

    const isExpoUser = true

    startPolling({
      userId: user.userId,
      userJwt: user.jwt,
      requestHandler: 'aiStagedQuestionAnswering',
      requestParams: {
        brandId,
        isExpoUser,
        userEmail,
        question: questionText,
        previousQuestions: undefined,
        customerBrandCode: brand,
        userId: user.userId,
        lastGptAnswer: null,
        specialRequest: REQUEST_ASK_TABLE,
        questionWithContext,
        llmProviderName: selectedModel,
      },
    })
  }

  const onFormSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    handleQuestionSubmit(question)
  }

  useEffect(() => {
    if (!aiResponse) {
      return
    }

    const displayQuestion = questionAnswer.question
    const gptAnswer = aiResponse?.gptAnswer
    const error = aiResponse?.error

    if (gptAnswer !== null && gptAnswer !== undefined) {
      setQuestionAnswer({ question: displayQuestion, answer: gptAnswer })
      setIsAiLoading(false)
      stopPolling()
    } else if (error !== null && error !== undefined) {
      setQuestionAnswer({
        question: displayQuestion,
        answer: `Sorry, we don't have the answer for that right now.`,
      })
      setIsAiLoading(false)
      stopPolling()
    } else {
      // AI is still processing, do not stop polling
      // Optionally, handle stagedInfo here if needed
    }
  }, [aiResponse])

  return (
    <ChatboxContainer>
      {hasCannedItemQuestions ? (
        <ButtonsContainer>
          <ButtonGroupLabel>
            See more insights about the data in the table:
          </ButtonGroupLabel>
          <ButtonRow>
            {predefinedQuestions.map((item, index) => (
              <ButtonWrapper key={index}>
                <ButtonSmall
                  onClick={() =>
                    handleQuestionSubmit(item.question, item.label)
                  }
                  disabled={isAiLoading || isPolling}
                >
                  {item.label}
                </ButtonSmall>
              </ButtonWrapper>
            ))}
          </ButtonRow>
        </ButtonsContainer>
      ) : null}

      {hasCategoryMenu ? (
        <SelectorContainer>
          <SelectGroupLabel>
            See the impact of removing specific category from the menu:
          </SelectGroupLabel>
          <SelectRow>
            <SelectWrapperSmall>
              <CategorySelect
                value={selectedCategory}
                onChange={(event) => setSelectedCategory(event.target.value)}
              >
                <MenuItemSmall value="None">(Select a Category)</MenuItemSmall>
                <MenuItemSmall value="AI_Recommendation">
                  AI Recommendation
                </MenuItemSmall>
              </CategorySelect>
            </SelectWrapperSmall>
          </SelectRow>
        </SelectorContainer>
      ) : null}

      {hasMessageBar ? (
        <FormContainer onSubmit={onFormSubmit}>
          <InputGroup>
            <TextField
              fullWidth
              label="Ask questions about the data in the table"
              variant="outlined"
              value={question}
              onChange={(event) => setQuestion(event.target.value)}
            />
          </InputGroup>

          <SelectWrapper>
            <Select
              value={selectedModel}
              onChange={(event) => setSelectedModel(event.target.value)}
              displayEmpty
            >
              <MenuItem value="Anthropic">Claude</MenuItem>
              <MenuItem value="OpenAI">ChatGPT</MenuItem>
            </Select>
          </SelectWrapper>

          <ButtonWrapper>
            <Button
              variant="contained"
              size="large"
              type="submit"
              disabled={isAiLoading || isPolling}
            >
              Submit
            </Button>
          </ButtonWrapper>
        </FormContainer>
      ) : null}

      {questionAnswer.question && (
        <QuestionAnswerContainer>
          <QuestionText>{questionAnswer.question}</QuestionText>
          <br />
          {isAiLoading || isPolling ? (
            <div>
              {stagedInfo && stagedInfo.length > 0 ? (
                <div>
                  {stagedInfo.map((info, index) => (
                    <i>{info}</i>
                  ))}
                </div>
              ) : (
                <i>Loading...</i>
              )}
            </div>
          ) : (
            <AnswerText>{questionAnswer.answer}</AnswerText>
          )}
        </QuestionAnswerContainer>
      )}
    </ChatboxContainer>
  )
}

export default React.memo(ChatboxForTable)
