import buildMetricValuesHook from '../utils/buildMetricValuesHook'

export const dhWbListLocationDeliveryKpisConfigs = {
  deliverySumSubtotalDoordash: 'price',
  deliveryTotalOrderCountDoordash: 'number',
  deliverySumErrorChargesDoordash: 'price',
  deliveryAvgDasherWaitTimeDoordash: 'number',
  deliveryOrderWithAnyIssuePercentDoordash: 'percent',
  deliveryAvgCustomerReviewScoreDoordash: 'number',
  deliveryInaccurateOrderPercentDoordash: 'percent',
  deliveryCancelledOrderPercentDoordash: 'percent',
  deliveryDelayedOrderPercentDoordash: 'percent',
} as const

const useListLocationDeliveryKpis = buildMetricValuesHook({
  groupFilterType: 'store',
  metrics: {
    codes: [
      'delivery_avg_dasher_wait_time_doordash',
      'delivery_order_with_any_issue_percent_doordash',
      'delivery_avg_customer_review_score_doordash',
      'delivery_inaccurate_order_percent_doordash',
      'delivery_cancelled_order_percent_doordash',
      'delivery_delayed_order_percent_doordash',
    ],
    groups: ['Expo Module - Delivery Data'],
  },
})

export default useListLocationDeliveryKpis
