import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { IApiDataType } from '../../types'
import format from './format'

type IMetricDataType = Record<
  string,
  {
    name?: string
    unit: 'CENT' | 'PERCENTAGE' | 'DOLLAR' | 'COUNT'
    value: number
  }
>

type ListItemMetricValue = {
  itemName: string
  displayParentCategoryName: string
  metricData?: IMetricDataType
  metricSummaryData?: IMetricDataType
}

type IDataType = {
  listItemMetricValues: {
    nodes: ListItemMetricValue[]
  }
}

const itemQuery = gql`
  query listItemMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iQueryType: String!
    $iFilter: JSON!
  ) {
    listItemMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iQueryType: $iQueryType
      iFilter: $iFilter
    ) {
      nodes {
        itemName
        displayParentCategoryName
        metricData
        metricSummaryData
      }
    }
  }
`

const buildItemMetricValuesHook = (
  options: {
    metrics: string[]
    itemCodes?: string[]
    itemCategoryCodes?: string[]
  },
  handler: (data: Record<string, unknown>) => Record<string, unknown> = (
    data,
  ) => data,
) => {
  const useItemMetricValues = () => {
    const { startDate, endDate } = useDateFilter()
    const { groupFilter } = useGroupFilter()

    const iFilter = {
      item_codes: options.itemCategoryCodes
        ? []
        : options.itemCodes || ['ALL ITEMS'],
      item_category_codes: options.itemCategoryCodes,
      metrics: options.metrics,
      location_group_ids: groupFilter?.ids,
    }

    const { data, loading } = useQuery<IDataType>(itemQuery, {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iQueryType: 'ITEM',
        iFilter,
      },
      skip:
        !startDate || !endDate || !groupFilter || options.metrics.length === 0,
    })

    const processedData = useMemo((): IApiDataType => {
      if (!data) return null

      const nodes = data.listItemMetricValues.nodes.find(
        (n) => n.metricSummaryData,
      )

      return {
        ...handler(
          format({
            ...(nodes?.metricSummaryData || {}),
          }),
        ),
      }
    }, [data, handler])

    return {
      data: processedData,
      loading,
    }
  }

  return useItemMetricValues
}

export default buildItemMetricValuesHook
