import ExpoAiLogo from 'pared/images/Expo/expoai.png'

import { IConfigsType } from '../../types'

const dhExpoAi: IConfigsType = {
  dh_wb: {
    '/:brand/expo_ai': {
      variables: ['corporateGroup'],
      groupFilter: {
        api: 'corporateWithoutBreakdownFilter',
      },
      title: {
        type: 'title',
        title: 'Expo AI',
      },
      image: {
        type: 'image',
        src: ExpoAiLogo,
        alt: 'Expo AI',
        height: '40px',
      },
      exray: {
        type: 'ai-summary',
        fields: [
          {
            type: 'uDelivery',
            details: [
              {
                key: 'uDelivery',
                api: 'corporateExpoAiDeliverySummary',
                rowKey: 'issueType',
                columns: [
                  {
                    key: 'issueType',
                    title: 'Issue',
                    width: '200px',
                    sortable: true,
                    actionName: 'aiSummary',
                  },
                  {
                    key: 'storeCount',
                    title: 'Store Count',
                    width: '200px',
                    sortable: true,
                  },
                ],
              },
              {
                key: 'uDelivery',
                api: 'corporateExpoAiDeliveryLocationDetails',
                rowKey: 'groupInfo.id',
                hide: '<%- !aiSummary.uDelivery?.clickedId %>',
                groupInfo: {
                  width: '180px',
                  groupBy: {
                    width: '180px',
                  },
                },
                columns: [
                  {
                    key: 'issuePercent',
                    title:
                      '<%- aiSummary.uDelivery?.clickedId %> Order Percent',
                    decimal: 1,
                    sortable: true,
                  },
                ],
              },
            ],
          },
          {
            type: 'uLossPrevention',
            details: [
              {
                key: 'uLossPrevention',
                api: 'corporateExpoAiLossPreventionSummary',
                rowKey: 'type',
                columns: [
                  {
                    key: 'issueType',
                    title: 'Issue',
                    sortable: true,
                    actionName: 'aiSummary',
                  },
                  {
                    key: 'storeCount',
                    title: 'Store Count',
                    sortable: true,
                  },
                  {
                    key: 'annualOpportunityCost',
                    title: 'Annual Opportunity Cost',
                    sortable: true,
                  },
                ],
              },
              {
                key: 'uLossPrevention',
                api: 'corporateExpoAiLossPreventionLocationDetails',
                rowKey: 'groupInfo.id',
                hide: '<%- !aiSummary.uLossPrevention?.clickedId %>',
                groupInfo: {
                  width: '200px',
                  groupBy: {
                    width: '200px',
                  },
                },
                columns: [
                  {
                    key: 'annualOpportunityCost',
                    title: 'Discounting - Annual Opportunity Cost',
                    sortable: true,
                  },
                ],
              },
            ],
          },
          {
            type: 'labor',
            details: [
              {
                key: 'labor',
                api: 'corporateExpoAiLaborSummary',
                rowKey: 'timeOfDay',
                columns: [
                  {
                    key: 'timeOfDay',
                    title: 'Time Of Day',
                    sortable: true,
                    actionName: 'aiSummary',
                  },
                  {
                    key: 'storeCount',
                    title: 'Store Count',
                    sortable: true,
                  },
                  {
                    key: 'annualOpportunityCost',
                    title: 'Annual Opportunity Cost',
                    sortable: true,
                  },
                ],
              },
              {
                key: 'labor',
                api: 'corporateExpoAiLaborLocationDetails',
                rowKey: 'groupInfo.id',
                hide: '<%- !aiSummary.labor?.clickedId %>',
                groupInfo: {
                  width: '200px',
                  groupBy: {
                    width: '200px',
                  },
                },
                columns: [
                  {
                    key: 'annualOpportunityCost',
                    title:
                      '<%- aiSummary.labor?.clickedId %> - Annual Opportunity Cost',
                    sortable: true,
                  },
                ],
              },
            ],
          },
          {
            type: 'uSales',
            details: [
              {
                key: 'uSales',
                api: 'corporateExpoAiSalesSummary',
                rowKey: 'area',
                columns: [
                  {
                    key: 'area',
                    title: 'Area of Improvement',
                    sortable: true,
                    actionName: 'aiSummary',
                  },
                  {
                    key: 'storeCount',
                    title: 'Store Count',
                    sortable: true,
                  },
                  {
                    key: 'annualOpportunityCost',
                    title: 'Annual Opportunity Cost',
                    sortable: true,
                  },
                ],
              },
              {
                key: 'uSales',
                api: 'corporateExpoAiSalesLocationDetails',
                rowKey: 'groupInfo.id',
                hide: '<%- !aiSummary.uSales?.clickedId %>',
                groupInfo: {
                  width: '200px',
                  groupBy: {
                    width: '200px',
                  },
                },
                columns: [
                  {
                    key: 'annualOpportunityCost',
                    title:
                      '<%- aiSummary.uSales?.clickedId %> Annual Opportunity Cost',
                    sortable: true,
                  },
                ],
              },
            ],
          },
        ],
      },
      chatbox: {
        type: 'chatbox',
        hasSalesAnalysisButton: true,
      },
    },
  },
}

export default dhExpoAi
