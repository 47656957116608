import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

interface IAiPollingParams {
  userId?: number
  userJwt: string
  requestHandler: string
  requestParams: {
    brandId: number
    isExpoUser: boolean
    userEmail: string
    question: string
    lastGptAnswer: any
    customerBrandCode: string
    userId?: number
    locationId?: number
    previousQuestions?: string[]
    specialRequest?: string
    questionWithContext?: string
    llmProviderName?: string
  }
  requestCacheKey?: string
}

interface IAiResponse {
  expoAnswer: any
  gptAnswer: any
  machineDialect: any
  error: any
}

// Define the return type of the hook
interface IUsePollingReturn {
  isPolling: boolean
  stagedInfo: string[]
  aiResponse: IAiResponse | null
  requestId: string
  requestProcessingUuid: string
  startPolling: (params: IAiPollingParams) => void
  stopPolling: () => void
}

const POLLING_URL = `${process.env.REACT_APP_BE_BASE_URL}/cached_request`

function useAiPolling(): IUsePollingReturn {
  const [isPolling, setIsPolling] = useState(false)
  const [stagedInfo, setStagedInfo] = useState<string[]>([])
  const [aiResponse, setAiResponse] = useState<IAiResponse | null>(null)
  const [requestId, setRequestId] = useState<string>('')
  const [requestProcessingUuid, setRequestProcessingUuid] = useState<string>('')
  const paramsRef = useRef<IAiPollingParams | null>(null) // Use ref to store params across renders
  const timeoutId = useRef<number | null>(null)

  const startPolling = (params: IAiPollingParams) => {
    paramsRef.current = params
    setIsPolling(true)
    setRequestId('')
    setRequestProcessingUuid('')
    setStagedInfo([])
    setAiResponse(null)
  }

  const stopPolling = () => {
    setIsPolling(false)
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }
  }

  const poll = async () => {
    if (paramsRef.current) {
      let status: string = ''
      let isToStopPolling = false

      try {
        const response = await axios.post(POLLING_URL, paramsRef.current, {
          timeout: 60000,
        })

        const responseData = response?.data
        status = responseData?.status || ''
        const currentRequestCacheKey: string =
          responseData?.requestCacheKey || ''
        const currentRequestId: string = responseData?.requestId || ''
        const currentRequestProcessingUuid: string =
          responseData?.requestProcessingUuid || ''

        const result = responseData?.result
        const error: any = responseData?.error || null
        const machineDialect: any = result?.lastGptAnswer || null
        const gptAnswer: any =
          machineDialect?.machineDialect?.nativeGptAnswer ||
          machineDialect?.nativeGptAnswer ||
          null
        // FIX ME: should be machineDialect?.nativeGptAnswer || null

        let expoAnswer = null
        if (status === 'STAGING') {
          expoAnswer = result?.stagedAnswer
          if (
            Array.isArray(result?.stagedInfo) &&
            result.stagedInfo.length > 0
          ) {
            setStagedInfo(result.stagedInfo)
          } else {
            setStagedInfo([])
          }
        } else {
          expoAnswer = result?.answer || null
        }

        if (currentRequestCacheKey) {
          paramsRef.current = {
            ...paramsRef.current,
            requestCacheKey: currentRequestCacheKey,
          }
        }

        if (!requestId && currentRequestId) {
          setRequestId(currentRequestId)
        }

        if (!requestProcessingUuid && currentRequestProcessingUuid) {
          setRequestProcessingUuid(currentRequestProcessingUuid)
        }

        setAiResponse({
          expoAnswer,
          gptAnswer,
          machineDialect,
          error,
        })

        if (
          status === 'COMPLETED' ||
          status === 'CANCELLED' ||
          status === 'FAILED' ||
          (requestId && requestId !== currentRequestId) ||
          (requestProcessingUuid &&
            requestProcessingUuid !== currentRequestProcessingUuid)
        ) {
          isToStopPolling = true
        }
      } catch (error) {
        console.error('Polling error:', error)
        isToStopPolling = true
      }

      if (isToStopPolling) {
        stopPolling()
      }

      if (!isToStopPolling && isPolling) {
        timeoutId.current = setTimeout(poll, 2000) // Schedule next poll after 2 seconds
      }
    }
  }

  useEffect(() => {
    if (isPolling) {
      // Start polling only when isPolling is true
      poll()
    }

    return () => {
      if (timeoutId.current) {
        // Clean up on unmount
        clearTimeout(timeoutId.current)
      }
    }
  }, [isPolling])

  return {
    isPolling,
    stagedInfo,
    aiResponse,
    requestId,
    requestProcessingUuid,
    startPolling,
    stopPolling,
  }
}

export default useAiPolling
