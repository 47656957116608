import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'

import useDateRange from 'pared/components/DateRangeNumberSelector/hooks/useDateRange'
import { getDateRangeLabelV2 } from 'pared/utils/date'

import { IGroupFilterDataType, useVariables } from '../variables'

export { default as format } from '../utils/format'

export type { IGroupFilterDataType }
export type IGroupByType =
  | `last_${number}_${'days' | 'weeks' | 'months'}`
  | `business_${'month' | 'week'}`

export type IMetricDataType = Record<
  string,
  {
    name: string
    unit: 'CENT' | 'PERCENTAGE' | 'DOLLAR' | 'COUNT' | 'SECONDS' | 'SCORE'
    value: number
  }
>

type INodeType = Parameters<typeof getDateRangeLabelV2>[1] & {
  locationGroupId: number
  startDate: string
  endDate: string
  metricData: IMetricDataType
  metricSummaryData: IMetricDataType
}

type IDataType<
  T extends string =
    | 'trendLocationGroupMetricValues'
    | 'trendLocationMetricValues',
> = Record<
  T,
  {
    nodes: (T extends 'listLocationGroupMetricTrends'
      ? {
          locationGroupId: number
        }
      : {
          locationId: number
        }) &
      INodeType[]
  }
>

interface IVariablesType {
  iStartDate?: string
  iEndDate?: string
  iGroupBy?: string
  iFilter: {
    location_group_ids?: number[]
    location_ids?: number[]
    metrics?: string[]
    metric_groups?: string[]
    use_yoy?: boolean
    [key: string]: unknown
  }
  isLocationGroup: boolean
  useMetricData: boolean
  useMetricSummaryData: boolean
}

export type IMetricType = string | { key: string; type: 'yoy' }

const query = gql`
  query MetricTrends(
    $iStartDate: Date!
    $iEndDate: Date!
    $iGroupBy: String!
    $iFilter: JSON!
    $isLocationGroup: Boolean!
    $useMetricData: Boolean!
    $useMetricSummaryData: Boolean!
  ) {
    trendLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
      iGroupBy: $iGroupBy
    ) @include(if: $isLocationGroup) {
      nodes {
        locationGroupId
        groupByStartDate: startDate
        groupByEndDate: endDate
        businessYear
        businessWeek
        businessWeekOfMonth
        businessMonth
        metricData @include(if: $useMetricData)
        metricSummaryData @include(if: $useMetricSummaryData)
      }
    }

    trendLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
      iGroupBy: $iGroupBy
    ) @skip(if: $isLocationGroup) {
      nodes {
        locationId
        groupByStartDate: startDate
        groupByEndDate: endDate
        businessYear
        businessWeek
        businessWeekOfMonth
        businessMonth
        metricData @include(if: $useMetricData)
        metricSummaryData @include(if: $useMetricSummaryData)
      }
    }
  }
`

const defaultHandler = (variables: IVariablesType) => ({
  variables,
  skip:
    !variables.iStartDate ||
    !variables.iEndDate ||
    (!variables.iFilter.location_group_ids &&
      !variables.iFilter.location_ids) ||
    ((variables.iFilter.metrics || []).length === 0 &&
      (variables.iFilter.metric_groups || []).length === 0),
})

const useCommonMetricTrendsQuery = (
  groupFilterTypes: IGroupFilterDataType['type'][],
  fields: ('metricData' | 'metricSummaryData')[],
  handler: (variables: IVariablesType) => {
    variables: IVariablesType
    skip: boolean
  } = defaultHandler,
  dateRange: IGroupByType,
  metrics?: string[],
  metricGroups?: string[],
  useYoy?: true,
) => {
  const { variables } = useVariables()
  const { dateRangeGroupBy, dateRangeNumber } = useDateRange(dateRange)
  const dateFilterInfo = variables.date?.getInfo(0)

  // FIXME: We should use groupByNumber to the query variables and calculate on BE
  const endDate = useMemo(() => {
    const startOfToday = moment().startOf('day')

    if (
      dateFilterInfo &&
      dateFilterInfo.dateRange.endDate.isAfter(startOfToday)
    )
      return startOfToday

    return dateFilterInfo?.dateRange.endDate
  }, [dateFilterInfo])
  const startDate = useMemo(() => {
    switch (dateRangeGroupBy) {
      case 'last_x_days':
        return endDate?.clone().subtract(dateRangeNumber, 'days')
      case 'last_x_weeks':
        return endDate?.clone().subtract(dateRangeNumber, 'weeks')
      case 'last_x_periods':
        return endDate?.clone().subtract(dateRangeNumber, 'months')
      default:
        return dateFilterInfo?.dateRange.startDate
    }
  }, [dateRangeGroupBy, dateRangeNumber, endDate])

  const { filter, isLocationGroup } = useMemo((): {
    filter: { location_group_ids?: number[]; location_ids?: number[] }
    isLocationGroup: boolean
  } => {
    const groupFilterInfo = variables.groupFilter?.getInfo(groupFilterTypes)

    if (groupFilterInfo) {
      if ('locationGroupIds' in groupFilterInfo)
        return {
          filter: {
            location_group_ids: groupFilterInfo.locationGroupIds,
          },
          isLocationGroup: groupFilterInfo.type === 'listLocationGroup',
        }

      if ('locationId' in groupFilterInfo)
        return {
          filter: {
            location_ids: [groupFilterInfo.locationId],
          },
          isLocationGroup: false,
        }
    }

    return { filter: {}, isLocationGroup: false }
  }, [variables])

  return useQuery<IDataType, IVariablesType>(
    query,
    handler({
      iStartDate: startDate?.format('YYYY-MM-DD'),
      iEndDate: endDate?.format('YYYY-MM-DD'),
      iGroupBy: dateRange,
      iFilter: {
        ...filter,
        metrics,
        metric_grups: metricGroups,
        use_yoy: useYoy,
      },
      isLocationGroup,
      useMetricData: fields.includes('metricData'),
      useMetricSummaryData: fields.includes('metricSummaryData'),
    }),
  )
}

const useMetricTrendsQuery = (options: {
  groupFilterTypes: IGroupFilterDataType['type'][]
  fields: ('metricData' | 'metricSummaryData')[]
  dateRange: IGroupByType
  metrics?: IMetricType[]
  metricGroups?: IMetricType[]
  handler?: (variables: IVariablesType) => {
    variables: IVariablesType
    skip: boolean
  }
}) => {
  const { data, loading } = useCommonMetricTrendsQuery(
    options.groupFilterTypes,
    options.fields,
    options.handler,
    options.dateRange,
    options.metrics
      ?.map((m) => (typeof m === 'string' ? m : null))
      .filter(Boolean) as string[],
    options.metricGroups
      ?.map((m) => (typeof m === 'string' ? m : null))
      .filter(Boolean) as string[],
  )
  const { data: yoyData, loading: yoyLoading } = useCommonMetricTrendsQuery(
    options.groupFilterTypes,
    options.fields,
    options.handler,
    options.dateRange,
    options.metrics
      ?.map((m) => (typeof m !== 'string' && m.type === 'yoy' ? m.key : null))
      .filter(Boolean) as string[],
    options.metricGroups
      ?.map((m) => (typeof m !== 'string' && m.type === 'yoy' ? m.key : null))
      .filter(Boolean) as string[],
    true,
  )

  return {
    data: useMemo(
      () =>
        (
          data?.trendLocationGroupMetricValues ||
          data?.trendLocationMetricValues
        )?.nodes,
      [data],
    ),
    yoyData: useMemo(
      () =>
        (
          yoyData?.trendLocationGroupMetricValues ||
          yoyData?.trendLocationMetricValues
        )?.nodes,
      [yoyData],
    ),
    loading: loading || yoyLoading,
  }
}

export default useMetricTrendsQuery
