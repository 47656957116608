import COLORS from 'pared/constants/colors'

import { IConfigsType } from '../../types'

const burgerworksSystemwideOverviewConfig: IConfigsType = {
  burgerworks: {
    '/:brand/systemwide_overview': {
      disableAutoMargin: true,
      variables: [
        {
          type: 'groupFilter',
          key: 'corporateGroupFilter',
          dateOptions: {
            types: [
              'week',
              'period',
              'quarter',
              'year',
              'last_week',
              'this_week',
              'yesterday',
              'trailing_7_days',
              'trailing_90_days',
              'custom_date_range',
            ],
          },
        },
      ],
      title: {
        type: 'grid',
        layout: 'space-between',
        maxWidth: '1200px',
        minWidth: '800px',
        children: {
          title: {
            type: 'typography',
            apiOrContent: 'Systemwide Overview',
          },
          dateAndGroupFilter: {
            type: 'grid',
            gap: '8px',
            children: {
              groupFilter: {
                type: 'select',
                value: 'groupFilter.value',
                onChange: 'groupFilter.onChange',
                options: 'groupFilter.options',
              },
              dateFilter: {
                type: 'date-filter',
              },
            },
          },
        },
      },
      systemwideOverview: {
        type: 'list',
        api: 'burgerworksListSystemwideOverviewKpis',
        margin: '50px 0px',
        fieldWidth: '120px',
        fields: [
          {
            content: {
              key: 'netSales',
            },
            bottom: 'Net Sales',
          },
          {
            content: {
              key: 'netSalesSssPercent',
            },
            bottom: 'SSS %',
          },
          {
            content: {
              key: 'checkCount',
            },
            bottom: 'Check Count',
          },
          {
            content: {
              key: 'checkAverage',
              decimal: 2,
            },
            bottom: 'Check Average',
          },
          {
            width: '125px',
            content: {
              key: 'laborTotalCostPercent',
            },
            bottom: 'Variable Labor %',
          },
          {
            width: '125px',
            content: {
              key: 'averageWeeklySales',
            },
            bottom: 'Average Weekly Sales',
          },
        ],
      },
      breakdownByStoreTitle: {
        type: 'typography',
        apiOrContent: 'Breakdown by Store',
        margin: '80px 0px 0px',
      },
      breakdownByStore: {
        type: 'table-v2',
        api: 'burgerworksSystemwideOverview',
        sorting: [
          {
            id: '<%- groupFilter?.tableColumns[0]?.key %>',
            desc: true,
          },
        ],
        columns: [
          {
            key: '<%- JSON(groupFilter?.tableColumns) %>',
            hide: '<%- _.isUndefined(groupFilter) %>',
            sortable: true,
          },
          {
            key: 'sales',
            title: 'Net Sales',
            align: 'center',
            children: [
              {
                key: 'netSales',
                title: '$',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Weekly Sales',
            title: 'Weekly Sales',
            align: 'center',
            children: [
              {
                key: 'averageWeeklySales',
                title: '$',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'variableLabor',
            title: 'Variable Labor',
            align: 'center',
            children: [
              {
                key: 'laborTotalCostPercent',
                title: '%',
                align: 'center',
                sortable: true,
              },
            ],
          },
        ],
      },
      revenueCenterAndDayPart: {
        type: 'grid',
        margin: '80px 0px 0px',
        children: {
          revenueCenter: {
            type: 'grid',
            layout: 'vertical',
            children: {
              title: {
                type: 'typography',
                apiOrContent: 'Revenue Center',
              },
              table: {
                type: 'table-v2',
                api: 'locationGroupRevenueCenter',
                columns: [
                  {
                    key: 'name',
                    title: 'Revenue Center',
                    sortable: true,
                  },
                  {
                    key: 'sales',
                    title: 'Net Sales',
                    unit: 'dollar',
                    sortable: true,
                  },
                  {
                    key: 'percentSales',
                    title: '% Net Sales',
                    sortable: true,
                  },
                  {
                    key: 'checkCount',
                    title: 'Check Count',
                    sortable: true,
                  },
                  {
                    key: 'checkAverage',
                    title: 'Check Average',
                    unit: 'dollar',
                    decimal: 2,
                    sortable: true,
                  },
                ],
              },
            },
          },
          dayPart: {
            type: 'grid',
            layout: 'vertical',
            children: {
              title: {
                type: 'typography',
                apiOrContent: 'Day Part',
              },
              table: {
                type: 'table-v2',
                api: 'locationGroupDayPart',
                columns: [
                  {
                    key: 'name',
                    title: 'Day Part',
                    sortable: true,
                  },
                  {
                    key: 'sales',
                    title: 'Sales',
                    unit: 'dollar',
                    sortable: true,
                  },
                  {
                    key: 'percentSales',
                    title: '% Net Sales',
                    sortable: true,
                  },
                  {
                    key: 'checkCount',
                    title: 'Check Count',
                    sortable: true,
                  },
                  {
                    key: 'checkAverage',
                    title: 'Check Average',
                    unit: 'dollar',
                    decimal: 2,
                    sortable: true,
                  },
                ],
              },
            },
          },
        },
      },
      averageWeeklySalesTitle: {
        type: 'typography',
        apiOrContent: 'Average Weekly Sales',
        margin: '80px 0px 0px',
      },
      averageWeeklySales: {
        type: 'line-chart',
        api: 'burgerworksWeeklySales',
        width: 1100,
        height: 350,
        label: 'date',
        datasets: [
          {
            key: 'netSalesWeeklyAverage',
            label: 'Net Sales',
            borderColor: COLORS.Basil,
            backgroundColor: COLORS.Basil,
          },
        ],
        scaleTypes: {
          y: {
            type: 'price',
          },
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              title: {
                text: '$',
              },
            },
          },
        },
      },
      map: {
        type: 'map',
        api: 'listGeoInfo',
        margin: '80px 0px 0px',
      },
    },
  },
}

export default burgerworksSystemwideOverviewConfig
