import ExpoAiLogo from 'pared/images/Expo/expoai.png'

import { IConfigsType } from '../../types'

const aceHome: IConfigsType = {
  ace_pop: {
    '/:brand/scorecard': {
      groupFilter: {
        api: 'locationFilter',
      },
      title: {
        type: 'title',
        title: 'locationInfo',
        subTitle: 'storeSubtitle',
      },
      image: {
        type: 'image',
        src: ExpoAiLogo,
        alt: 'Expo AI',
        height: '40px',
      },
      exray: {
        type: 'ai-summary',
        fields: [
          'sales',
          'labor',
          'foodCost',
          'lossPrevention',
          'delivery',
          'guest',
        ],
      },
      // chatbox: {
      //   type: 'chatbox',
      // },
    },
  },
  // removed this block when the QA is finished
  'ace_pop-only-for-qa': {
    '/:brand/scorecard': {
      groupFilter: {
        api: 'locationFilter',
      },
      title: {
        type: 'title',
        title: 'locationInfo',
        subTitle: 'storeSubtitle',
      },
      image: {
        type: 'image',
        src: ExpoAiLogo,
        alt: 'Expo AI',
        height: '40px',
      },
      exray: {
        type: 'ai-summary',
        fields: [
          'sales',
          'labor',
          'foodCost',
          'lossPrevention',
          'delivery',
          'guest',
        ],
      },
      chatbox: {
        type: 'chatbox',
      },
    },
  },
}

export default aceHome
