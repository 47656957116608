import { useHistory } from 'react-router-dom'

import { LargeScreen, SmallScreen } from 'pared/components/responsive'

import WebLargeMain from './WebLargeMain'

interface IProps {
  isLoading: boolean
  onSignIn: (email: string, password: string) => Promise<boolean>
  errorMessage: string
  brand: string
}

function Main({ isLoading, onSignIn, errorMessage, brand }: IProps) {
  const history = useHistory<{ from?: string } | undefined>()
  const handleSignIn = async (email: string, password: string) => {
    const isSuccess = await onSignIn(email, password)

    if (isSuccess) {
      if (history.location.state?.from)
        history.replace(history.location.state.from)
    }
  }

  return (
    <>
      <LargeScreen>
        <WebLargeMain
          isLoading={isLoading}
          onSignIn={handleSignIn}
          errorMessage={errorMessage}
          brand={brand}
        />
      </LargeScreen>
      <SmallScreen>
        <WebLargeMain
          isLoading={isLoading}
          onSignIn={handleSignIn}
          errorMessage={errorMessage}
          brand={brand}
        />
      </SmallScreen>
    </>
  )
}

export default Main
