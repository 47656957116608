import { useVariables } from '../../../variables'
import buildMetricTrendsHook from '../utils/buildMetricTrendsHook'

export const dhWbTrendLocationGroupSalesmanshipKpisConfigs = {
  date: 'string',
  checkAverage: 'price',
  itemAttachmentRateDesserts: 'percent',
  itemAttachmentRateBeverages: 'percent',
  itemAttachmentRateBacon: 'percent',
  itemAttachmentRateCheese: 'percent',
  itemAttachmentRateJalapeno: 'percent',
  upsizedCountPercentage: 'percent',
} as const

const useCommonMetricValuesHook = buildMetricTrendsHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: ['check_average', 'upsized_count_percentage'],
    groups: ['Expo Module - Item Attachment Rate'],
  },
})

const useDhWbTrendLocationGroupSalesmanshipKpis = () => {
  const { variables } = useVariables()
  const dateRange = variables.lastTimeRange?.value[0][0] || ''
  return useCommonMetricValuesHook(dateRange)
}

export default useDhWbTrendLocationGroupSalesmanshipKpis
