import { useMemo } from 'react'

import { corporateGroupTableConfigs } from '../../../../variables'
import buildMetricValuesHook from '../../utils/buildMetricValuesHook'

const commonLossPreventionDataConfigs = {
  grossSales: 'price',

  discountComps100DiscEmp: 'price',
  discountCompsPercent100DiscEmp: 'percent',
  discountCompsPercent100DiscEmpHeatmap: 'number',

  discountComps100Remake: 'price',
  discountCompsPercent100Remake: 'percent',
  discountCompsPercent100RemakeHeatmap: 'number',

  discountCompsOpenDiscounts: 'price',
  discountCompsPercentOpenDiscounts: 'percent',
  discountCompsPercentOpenDiscountsHeatmap: 'number',

  discountCompsOtherSCombined: 'price',
  discountCompsPercentOtherSCombined: 'percent',
  discountCompsPercentOtherSCombinedHeatmap: 'number',

  discountCompsAllWithPurch: 'price',
  discountCompsPercentAllWithPurch: 'percent',
  discountCompsPercentAllWithPurchHeatmap: 'number',

  discountCompsAllNonPurchPromos: 'price',
  discountCompsPercentAllNonPurchPromos: 'percent',
  discountCompsPercentAllNonPurchPromosHeatmap: 'number',

  discountCompsTotal: 'price',
  discountCompsPercentTotal: 'percent',
  discountCompsPercentTotalHeatmap: 'number',

  discountCheckCountTotal: 'number',

  lossPreventionCompsVoids: 'price',
  lossPreventionCheckCountVoids: 'number',
  lossPreventionCheckCountPercentVoids: 'percent',

  lossPreventionCompsCashOverOrShort: 'price',
} as const

export const fwWingstopLossPreventionConfigs = {
  ...corporateGroupTableConfigs,
  ...commonLossPreventionDataConfigs,
} as const

const useCommonLossPreventionData = buildMetricValuesHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: [
      'gross_sales',
      'discount_comps_total',
      'discount_check_count_total',
      'discount_comps_percent_total',
    ],
    groups: [
      'Expo Module - Discount Comps',
      'Expo Module - Discount Check Count',
      'Expo Module - Discount Comps Percent',
      'Expo Module - Loss Prevention Comps',
      'Expo Module - Loss Prevention Check Count',
      'Expo Module - Loss Prevention Check Count Percent',
    ],
  },
})

const useFwWingstopLossPrevention = () => {
  const { data: commonData, loading } = useCommonLossPreventionData()

  return {
    data: useMemo(() => {
      const keys = Object.keys(commonData?.[0] || {}) || []

      const keyInfo = keys
        .filter((k) => Object.keys(commonLossPreventionDataConfigs).includes(k))
        .reduce(
          (r, k) => {
            const kpiData = commonData?.map((cd) => cd[k] as number) || []
            const sortedData = [...kpiData].sort((a, b) => a - b)

            return {
              ...r,
              [k]: {
                sortedData,
              },
            }
          },
          {} as {
            [k: string]: {
              sortedData: number[]
            }
          },
        )

      const newData =
        commonData?.map((cd) => {
          const location = cd.location as { link?: string }

          return {
            ...cd,
            location: {
              ...location,
              link: location?.link?.replace('store_detail', 'loss_prevention'),
            },
            ...Object.keys(cd).reduce((r, c) => {
              if (Object.keys(commonLossPreventionDataConfigs).includes(c)) {
                const sorted = keyInfo[c].sortedData
                const rank =
                  sorted.findIndex((sortedItem) => sortedItem === cd[c]) + 1
                return {
                  ...r,
                  [`${c}Heatmap`]: ((rank - 1) / (sorted.length - 1)) * 100,
                }
              } else {
                return r
              }
            }, {}),
          }
        }) || []

      return newData
    }, [commonData, loading]),
    loading,
  }
}

export default useFwWingstopLossPrevention
