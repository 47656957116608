import styled from 'styled-components'

import Format, { IPropsType as IFormatPropsType } from './Format'
import useApi, { IApiKeyType, configs } from './hooks/useApi'

type IFieldType<
  V extends Record<string, Pick<IFormatPropsType, 'type'>['type']>,
> = {
  [K in keyof V]: Omit<
    Extract<
      IFormatPropsType,
      {
        type: V[K]
      }
    >,
    'value' | 'type'
  > & {
    key: K
  }
}

type IFieldsType = {
  [K in keyof typeof configs]: IFieldType<typeof configs[K]>
}

export interface IPropsType<K extends IApiKeyType = IApiKeyType> {
  type: 'list'
  api: K
  margin?: string
  fieldWidth?: string
  fields: {
    width?: string
    top?: string | IFieldsType[K][keyof IFieldsType[K]]
    content: IFieldsType[K][keyof IFieldsType[K]]
    bottom?: string | IFieldsType[K][keyof IFieldsType[K]]
  }[]
}

export type IConfigsType = {
  [K in IApiKeyType]: IPropsType<K>
}[IApiKeyType]

const Container = styled.table<{ loading: boolean; margin?: string }>`
  text-align: center;

  ${({ margin }) =>
    margin
      ? `margin: ${margin};`
      : `
          &:not(:first-child) {
            margin: 20px 0px 0px;
          }
        `}

  tbody td {
    font-family: ${({ loading }) =>
      loading ? 'Lexend-Regular' : 'Lexend-SemiBold'};
    font-size: ${({ loading }) => (loading ? '18px' : '24px')};
    font-weight: ${({ loading }) => (loading ? '400' : '700')};
  }

  thead td,
  tfoot td {
    font-family: Lexend-Regular;
    font-size: 14px;
    font-weight: 400;
  }
`

const Col = styled.col<{ width?: string }>`
  ${({ width }) =>
    width
      ? `
          min-width: ${width};
        `
      : `
          min-width: 120px;
          max-width: 150px;
        `}
`

const List = ({ api, margin, fieldWidth, fields }: IPropsType) => {
  const { data, loading } = useApi(api)

  return (
    <Container loading={loading} margin={margin}>
      <colgroup>
        {fields.map(({ width }: IConfigsType['fields'][number], index) => (
          <Col key={index} span={1} width={width || fieldWidth} />
        ))}
      </colgroup>

      {!fields.some((f) => f.top) ? null : (
        <thead>
          <tr>
            {fields.map(({ top, content }: IConfigsType['fields'][number]) => {
              if (!top) return <th key={content.key} />

              const { key, ...config } =
                typeof top !== 'string'
                  ? top
                  : {
                      key: top,
                    }
              const type =
                typeof top !== 'string'
                  ? configs[api][key as keyof typeof data]
                  : 'string'
              const value = typeof top !== 'string' ? data?.[key] : top

              return (
                <Format
                  {...config}
                  key={content.key}
                  type={type}
                  value={value}
                />
              )
            })}
          </tr>
        </thead>
      )}

      <tbody>
        <tr>
          {fields.map(
            ({
              content: { key, ...config },
            }: IConfigsType['fields'][number]) => (
              <Format
                {...config}
                key={key}
                type={configs[api][key as keyof typeof data]}
                value={data?.[key]}
              />
            ),
          )}
        </tr>
      </tbody>

      {!fields.some((f) => f.bottom) ? null : (
        <tfoot>
          <tr>
            {fields.map(
              ({ content, bottom }: IConfigsType['fields'][number]) => {
                if (!bottom) return <th key={content.key} />

                const { key, ...config } =
                  typeof bottom !== 'string'
                    ? bottom
                    : {
                        key: bottom,
                      }
                const type =
                  typeof bottom !== 'string'
                    ? configs[api][key as keyof typeof data]
                    : 'string'
                const value = typeof bottom !== 'string' ? data?.[key] : bottom

                return (
                  <Format
                    {...config}
                    key={content.key}
                    type={type}
                    value={value}
                  />
                )
              },
            )}
          </tr>
        </tfoot>
      )}
    </Container>
  )
}

export default List
