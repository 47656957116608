import { useMemo } from 'react'

import { getBusinessLabel } from 'pared/customer'
import { getBrand } from 'pared/utils/brand'
import { getUser } from 'pared/utils/user'

export interface ILocationGroupType {
  id: number
  name: string
}

interface IConfig {
  locationGroupTypes: ILocationGroupType[]
  shouldHideGroups: boolean
  isDirectorBreakdownVisible: boolean
}

const defaultConfig = {
  locationGroupTypes: [],
  shouldHideGroups: true,
  isDirectorBreakdownVisible: true,
}

const useConfig = (): IConfig => {
  const brand = getBrand()
  const user = getUser()
  return useMemo(() => {
    switch (brand) {
      case 'cava':
        return {
          ...defaultConfig,
          locationGroupTypes: [
            {
              id: -3,
              name: 'RD',
            },
            {
              id: -4,
              name: 'Garden',
            },
            {
              id: -5,
              name: 'Multi-Unit',
            },
            {
              id: -6,
              name: getBusinessLabel('director'),
            },
          ],
          shouldHideGroups: Boolean(user.isStoreLevelUser),
          isDirectorBreakdownVisible: false,
        }
      case 'supreme_bk':
        return {
          ...defaultConfig,
          locationGroupTypes: [
            {
              id: -3,
              name: 'Division',
            },
            {
              id: -4,
              name: getBusinessLabel('director'),
            },
          ],
          shouldHideGroups: false,
          isDirectorBreakdownVisible: false,
        }
      case 'mbn_bk':
        return {
          ...defaultConfig,
          locationGroupTypes: [
            {
              id: -3,
              name: 'DO',
            },
            {
              id: -4,
              name: getBusinessLabel('director'),
            },
          ],
          shouldHideGroups: false,
          isDirectorBreakdownVisible: false,
        }

      case 'timoney_bk':
      case 'demo_bk':
      case 'irmg_bk':
        return {
          ...defaultConfig,
          locationGroupTypes: [
            {
              id: -4,
              name: getBusinessLabel('director'),
            },
          ],
          shouldHideGroups: false,
          isDirectorBreakdownVisible: false,
        }

      case 'jsc_bk':
      case 'jsc_bk_nso':
        return {
          ...defaultConfig,
          locationGroupTypes: [
            {
              id: -5,
              name: 'Comp or Non-Comp',
            },
            {
              id: -3,
              name: 'Region',
            },
            {
              id: -4,
              name: getBusinessLabel('director'),
            },
          ],
          shouldHideGroups: false,
          isDirectorBreakdownVisible: false,
        }
      case 'irmg_pop':
      case 'ghai_pop':
        return {
          ...defaultConfig,
          locationGroupTypes: [
            {
              id: -3,
              name: 'Area Director',
            },
            {
              id: -4,
              name: getBusinessLabel('director'),
            },
          ],
          shouldHideGroups: false,
          isDirectorBreakdownVisible: false,
        }
      case 'supreme_pop':
        return {
          ...defaultConfig,
          locationGroupTypes: [
            {
              id: -2,
              name: getBusinessLabel('director'),
            },
            {
              id: -3,
              name: 'Regional Manager',
            },
            {
              id: -4,
              name: 'State',
            },
            {
              id: -5,
              name: 'District',
            },
          ],
          shouldHideGroups: false,
          isDirectorBreakdownVisible: false,
        }
      case 'wingitnorth':
        return {
          ...defaultConfig,
          locationGroupTypes: [
            {
              id: -3,
              name: getBusinessLabel('director'),
            },
          ],
          shouldHideGroups: Boolean(user.isStoreLevelUser),
          isDirectorBreakdownVisible: false,
        }
      case 'bibibop':
        return {
          ...defaultConfig,
          locationGroupTypes: [
            {
              id: -3,
              name: 'Comp or Non-Comp',
            },
            {
              id: -4,
              name: getBusinessLabel('director'),
            },
            {
              id: -5,
              name: 'Region',
            },
            {
              id: -6,
              name: 'Market',
            },
            {
              id: -7,
              name: 'Multi-Unit Manager',
            },
          ],
          shouldHideGroups: false,
          isDirectorBreakdownVisible: false,
        }
      case 'mwb':
        return {
          ...defaultConfig,
          locationGroupTypes: [
            {
              id: -4,
              name: getBusinessLabel('director'),
            },
            {
              id: -5,
              name: 'Comp or Non-Comp',
            },
          ],
          shouldHideGroups: false,
          isDirectorBreakdownVisible: false,
        }
      case 'burgerworks':
        return {
          ...defaultConfig,
          locationGroupTypes: [
            {
              id: -3,
              name: 'Area Coach',
            },
            {
              id: -4,
              name: getBusinessLabel('director'),
            },
            {
              id: -5,
              name: 'Comp or Non-Comp',
            },
          ],
          shouldHideGroups: false,
          isDirectorBreakdownVisible: false,
        }
      case 'eddiemerlots':
      case 'eddiemerlots_nso':
      case 'sullivans':
      case 'sullivans_nso':
        return {
          ...defaultConfig,
          locationGroupTypes: [
            {
              id: -3,
              name: getBusinessLabel('director'),
            },
          ],
          shouldHideGroups: false,
          isDirectorBreakdownVisible: false,
        }

      case 'fw_wingstop':
        return {
          ...defaultConfig,
          locationGroupTypes: [
            {
              id: -2,
              name: getBusinessLabel('director'),
            },
            {
              id: -3,
              name: 'RO',
            },
            {
              id: -4,
              name: 'Market',
            },
            {
              id: -5,
              name: 'State',
            },
            {
              id: -6,
              name: 'Comp or Non-Comp',
            },
            {
              id: -7,
              name: 'Core or Non-Core',
            },
          ],
          shouldHideGroups: false,
          isDirectorBreakdownVisible: false,
        }
      case 'ace_pop':
        return {
          ...defaultConfig,
          locationGroupTypes: [
            {
              id: -2,
              name: getBusinessLabel('director'),
            },
            {
              id: -3,
              name: 'State',
            },
            {
              id: -4,
              name: 'Legacy or Acquisition',
            },
          ],
          shouldHideGroups: false,
        }

      default:
        return defaultConfig
    }
  }, [brand])
}

export default useConfig
