import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { getBrandLocationGroupId } from 'pared/utils/brand'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { useVariables } from '../../../variables'
import { IApiType } from '../../types'

const QUERY_NAME = 'listLocationGroupMetricValues'
const query = gql`
  query LocationGroupDayPart(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    ${QUERY_NAME}(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        metricSummaryData
      }
    }
  }
`

export const locationGroupDayPartConfigs = {
  name: 'string',
  sales: 'price',
  checkCount: 'number',
  checkAverage: 'price',
  percentSales: 'percent',
  salesPerHour: 'price',
} as const

const useLocationGroupDayPart = (): IApiType => {
  const dateFilter = useDateFilter()
  const groupFilter = useGroupFilter()
  const brandLocationGroupId = getBrandLocationGroupId()
  const { variables } = useVariables()

  const { startDate, endDate } = useMemo(() => {
    const dateFilterInfo = variables.date?.getInfo(0)

    return {
      startDate: dateFilterInfo?.dateRange.startDateStr || dateFilter.startDate,
      endDate: dateFilterInfo?.dateRange.endDateStr || dateFilter.endDate,
    }
  }, [variables, dateFilter])
  const locationGroupIds = useMemo(() => {
    const groupFilterInfo = variables.groupFilter?.getInfo([
      'listLocation',
      'listLocationGroup',
    ])

    return (
      groupFilterInfo?.locationGroupIds ||
      (groupFilter.hasGroupBy
        ? groupFilter.groupFilter?.ids
        : [brandLocationGroupId])
    )
  }, [variables, groupFilter, groupFilter])

  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_group_ids: locationGroupIds,
        metric_groups: [
          'Expo Module - Day Part Sales',
          'Expo Module - Day Part Check Count',
          'Expo Module - Day Part Check Average',
          'Expo Module - Day Part Sales Per Hour',
          'Expo Module - Fundamental Sales',
        ],
      },
    },
    skip: !startDate || !endDate || !locationGroupIds,
  })

  return {
    data: useMemo(() => {
      const nodes = data?.[QUERY_NAME].nodes as
        | {
            locationGroupId: string
            metricSummaryData: {
              [key: string]: {
                name: string
                unit: string
                value: number
              }
            }
          }[]
        | undefined

      if (!nodes) return null
      const mappedData: {
        [dayPart: string]: {
          [kpi: string]: number | string
        }
      } = {}
      const metricSummaryData = nodes.find(
        (n) => n.metricSummaryData,
      )?.metricSummaryData
      let netSales = 0

      for (const key in metricSummaryData) {
        if (key === 'net_sales') {
          if (metricSummaryData[key].unit === 'CENT') {
            netSales = metricSummaryData[key].value / 100
          } else {
            netSales = metricSummaryData[key].value
          }
          continue
        }

        const kpiAndDayPart = key.split('day_part_')[1]
        let kpi = ''
        let dayPart = ''
        if (kpiAndDayPart.indexOf('sales_per_hour') === 0) {
          kpi = 'salesPerHour'
          dayPart = kpiAndDayPart.split('sales_per_hour_')[1]
        } else if (kpiAndDayPart.indexOf('sales') === 0) {
          kpi = 'sales'
          dayPart = kpiAndDayPart.split('sales_')[1]
        } else if (kpiAndDayPart.indexOf('check_count') === 0) {
          kpi = 'checkCount'
          dayPart = kpiAndDayPart.split('check_count_')[1]
        } else if (kpiAndDayPart.indexOf('check_average') === 0) {
          kpi = 'checkAverage'
          dayPart = kpiAndDayPart.split('check_average_')[1]
        }

        if (!mappedData[dayPart]) {
          mappedData[dayPart] = {
            name: metricSummaryData[key].name.split(' - ')[1],
            [kpi]: metricSummaryData[key].value,
          }
        } else {
          mappedData[dayPart][kpi] = metricSummaryData[key].value
        }
      }

      const rows = Object.values(mappedData).map((n) => {
        return {
          ...n,
          percentSales: (100 * (n.sales as number)) / netSales,
          id: n.name as string,
          parentId: 'root',
        }
      })

      return rows
    }, [groupFilter, data]),
    loading,
  }
}

export default useLocationGroupDayPart
