import { gql, useMutation } from '@apollo/client'
import CircularProgress from '@material-ui/core/CircularProgress'
import _ from 'lodash'
import { useEffect } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { getUser, signInUser } from 'pared/utils/user'

const MUTATION_AUTHENTICATE_USER_WITH_TOKEN = gql`
  mutation AuthenticateUserWithToken(
    $iEmail: String!
    $iAuthenticationToken: String!
  ) {
    authenticateWithToken(
      input: { iEmail: $iEmail, iAuthenticationToken: $iAuthenticationToken }
    ) {
      jwtToken
    }
  }
`

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`

const SignInMagicLink = () => {
  const [authenticateUserWithToken] = useMutation(
    MUTATION_AUTHENTICATE_USER_WITH_TOKEN,
  )
  const history = useHistory()
  const user = getUser()
  const hasUserSignedIn = Boolean(user.jwt)

  useEffect(() => {
    if (hasUserSignedIn) return
    ;(async () => {
      const urlParams = new URLSearchParams(history.location.search)
      const token = urlParams.get('token')
      const email = urlParams.get('email')?.trim().toLowerCase()

      if (!token || !email) {
        history.replace('./sign_in')
        return
      }

      try {
        const { data } = await authenticateUserWithToken({
          variables: {
            iEmail: email,
            iAuthenticationToken: token,
          },
        })

        if (!data?.authenticateWithToken?.jwtToken)
          throw Error('Fail to log in with the authentication token.')

        await signInUser(data.authenticateWithToken.jwtToken, email)
        history.replace('./change_password')
      } catch (e) {
        history.replace('./sign_in', {
          signInMagicLinkError:
            (e as Error).message ||
            'Fail to log in with the authentication token.',
        })
      }
    })()
  }, [authenticateUserWithToken, history, hasUserSignedIn])

  if (hasUserSignedIn) return <Redirect to="./change_password" />

  return (
    <Container>
      <CircularProgress size={50} />
    </Container>
  )
}

export default SignInMagicLink
