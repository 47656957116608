import { useMemo } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

import useBrands from 'pared/layouts/hooks/useBrands'
import IrmgBkBankAccountLinkForm from 'pared/pages/Plaid'
import { getUser } from 'pared/utils/user'

import ResetProvider from './ResetProvider'
import { AdvancedFilterProvider } from './advancedFilter'
import AiSummary, { IPropsType as IAiSummaryPropsType } from './aiSummary'
import AiSummaryBox, {
  IPropsType as IAiSummaryBoxPropsType,
} from './aiSummaryBox'
import Chart, { IPropsType as IChartPropsType } from './chart'
import ChatBox, { IPropsType as IChatboxPropsType } from './chatBox'
import ChatBoxForTable, {
  IPropsType as IChatboxForTablePropsType,
} from './chatBoxForTable'
import DateFilter, {
  DateFilterProvider,
  IPropsType as IDateFilterPropsType,
  ITempPropsType as ITempDateFilterPropsType,
  getTypeMapping,
} from './dateFilter'
import DownloadCsv, { IPropsType as IDownloadCsvPropsType } from './downloadCsv'
import Grid, { IPropsType as IGridConfigsType } from './grid'
import {
  GroupFilterProvider,
  IPropsType as IGroupFilterPropsType,
} from './groupFilter'
import useAccessiblePages from './hooks/useAccessiblePages'
import useRoutes from './hooks/useRoutes'
import Image, { IPropsType as IImagePropsType } from './image'
import List, { IPropsType as IListPropsType } from './list'
import LocationSalesPerLaborHour, {
  IPropsType as ILocationSalesPerLaborHourType,
} from './locationSalesPerLaborHour'
import Map, { IPropsType as IMapPropsType } from './map'
import Modal, { IPropsType as IModalPropsType } from './modal'
import OrganizationChart, {
  IPropsType as IOrgChartType,
} from './organizationChart'
import Select, { IPropsType as ISelectPropsType } from './select'
import Table, { IPropsType as ITablePropsType } from './table'
import TableV2, { IPropsType as ITableV2PropsType } from './tableV2'
import TempTable, {
  IPropsType as ITempTablePropsType,
} from './tempCustomizedTable'
import TempDownloadCsv, {
  IPropsType as ITempDownloadCsvPropsType,
} from './tempDownloadCsv'
import Title from './title'
import TrackerFrom from './trackerForm'
import TrackerSubtitble, {
  IPropsType as ITrackerSubtitblePropsType,
} from './trackerSubtitle'
import { IBlockConfigsType, IRouteType } from './types'
import Typography, { IPropsType as ITypographyPropsType } from './typography'
import UploadReport, {
  IPropsType as IUploadReportPropsType,
} from './uploadReport'
import VariablesProvider, { IVariablesType } from './variables'

const getLeafRoutes = <D extends Extract<IRouteType, { default?: boolean }>>(
  routes: IRouteType[],
): D[] =>
  routes.reduce<D[]>((result, r) => {
    if ('children' in r) {
      return [...result, ...getLeafRoutes<D>(r.children)]
    } else {
      return [...result, r as D]
    }
  }, [])

// TODO: should be removed after all pages are changed to use renderer
export const useRenderInfo = () => {
  const { brand } = useBrands()
  const routes = useRoutes()
  const user = getUser()

  return useMemo(() => {
    const accessibleLinks = getLeafRoutes(routes)
    const defaultRoute = accessibleLinks.find((r) => r.default)

    return {
      rendererPath: `/${brand}(${accessibleLinks
        .filter((r) => ('useOriginal' in r ? !r.useOriginal : true))
        .map((r) => r.link.replace(/\/:brand/, '').replace(/\/:id/, ''))
        .join('|')})`,
      defaultPath: !defaultRoute
        ? null
        : defaultRoute.link.replace(/:brand/, brand),
    }
  }, [brand, routes, user])
}

const Container = styled.div<{ noMargin: boolean }>`
  ${({ noMargin }) =>
    noMargin
      ? ''
      : `
    &:not(:first-child) {
      margin: 80px 0px 0px;
    }
  `}
`

const Blocks = ({
  pageConfigs,
  groupFilter,
  dateFilter,
  noMargin: parentNoMargin,
  disableTitle,
}: {
  pageConfigs: Record<string, IBlockConfigsType>
  groupFilter?: IGroupFilterPropsType
  dateFilter?: ITempDateFilterPropsType
  noMargin?: boolean
  disableTitle?: boolean
}) => (
  <>
    {Object.keys(pageConfigs).map((key, index, keys) => {
      const {
        title,
        advancedFilter,
        badge,
        subTitle,
        titleMargin,
        ...blockConfigs
      } = pageConfigs[key]
      // --- Fix Me ---
      const noMargin = (() => {
        const type = pageConfigs[keys[index - 1]]?.type

        return (
          parentNoMargin ||
          type === 'download-csv' ||
          type === 'temp-download-csv' ||
          type === 'title' ||
          type === 'chatbox-for-table'
        )
      })()
      const titleProps = {
        first: disableTitle ? false : index === 0,
        groupFilterConfigs: groupFilter,
        dateFilterConfigs: dateFilter,
        advancedFilterConfigs: advancedFilter,
        badgeConfigs: badge,
        subTitleConfigs: subTitle,
        marginConfigs: titleMargin,
      }
      // -------------

      switch (blockConfigs.type) {
        /* Layout components */
        case 'grid':
          return (
            <Container key={key} noMargin={noMargin}>
              <Grid {...(blockConfigs as Omit<IGridConfigsType, 'children'>)}>
                <Blocks
                  pageConfigs={blockConfigs.children}
                  noMargin
                  disableTitle
                />
              </Grid>
            </Container>
          )

        case 'modal':
          return (
            <Modal
              {...(blockConfigs as Omit<IModalPropsType, 'children'>)}
              key={key}
            >
              <Blocks
                pageConfigs={blockConfigs.children}
                noMargin
                disableTitle
              />
            </Modal>
          )

        /* Block components */
        case 'title':
          return (
            <Container key={key} noMargin={noMargin || Boolean(titleMargin)}>
              <Title {...titleProps}>{title}</Title>
            </Container>
          )

        case 'typography':
          return (
            <Typography {...(blockConfigs as ITypographyPropsType)} key={key} />
          )

        case 'date-filter':
          return (
            <DateFilter {...(blockConfigs as IDateFilterPropsType)} key={key} />
          )

        case 'image':
          return <Image {...(blockConfigs as IImagePropsType)} key={key} />

        case 'select':
          return <Select {...(blockConfigs as ISelectPropsType)} key={key} />

        case 'list':
          return (
            <Container key={key} noMargin={noMargin}>
              <Title {...titleProps}>{title}</Title>

              <List {...(blockConfigs as IListPropsType)} />
            </Container>
          )

        case 'table':
          return (
            <Container key={key} noMargin={noMargin}>
              <Title {...titleProps}>{title}</Title>

              <Table {...(blockConfigs as ITablePropsType)} />
            </Container>
          )

        case 'table-v2':
          return (
            <Container key={key} noMargin={noMargin}>
              <Title {...titleProps}>{title}</Title>

              <TableV2 {...(blockConfigs as ITableV2PropsType)} />
            </Container>
          )

        case 'temp-table':
          return (
            <Container key={key} noMargin={noMargin}>
              <Title {...titleProps}>{title}</Title>

              <TempTable {...(blockConfigs as ITempTablePropsType)} />
            </Container>
          )

        case 'map':
          return (
            <Container key={key} noMargin={noMargin}>
              <Title {...titleProps}>{title}</Title>

              {!noMargin ? (
                <Map {...(blockConfigs as IMapPropsType)} margin="0px" />
              ) : (
                <Map {...(blockConfigs as IMapPropsType)} />
              )}
            </Container>
          )

        case 'download-csv':
          return (
            <Container key={key} noMargin={noMargin}>
              <Title {...titleProps}>{title}</Title>

              <DownloadCsv {...(blockConfigs as IDownloadCsvPropsType)} />
            </Container>
          )

        case 'temp-download-csv':
          return (
            <Container key={key} noMargin={noMargin}>
              <Title {...titleProps}>{title}</Title>

              <TempDownloadCsv
                {...(blockConfigs as ITempDownloadCsvPropsType)}
              />
            </Container>
          )

        case 'org-chart':
          return (
            <Container key={key} noMargin={noMargin}>
              <Title {...titleProps}>{title}</Title>

              <OrganizationChart {...(blockConfigs as IOrgChartType)} />
            </Container>
          )

        case 'location-sales-per-labor-hour':
          return (
            <Container key={key} noMargin={noMargin}>
              <Title {...titleProps}>{title}</Title>

              <LocationSalesPerLaborHour
                {...(blockConfigs as ILocationSalesPerLaborHourType)}
              />
            </Container>
          )

        case 'tracker-subtitle':
          return (
            <Container key={key} noMargin={noMargin}>
              <Title {...titleProps}>{title}</Title>

              <TrackerSubtitble
                {...(blockConfigs as ITrackerSubtitblePropsType)}
              />
            </Container>
          )

        case 'tracker-form':
          return <TrackerFrom key={key} />

        case 'irmg_cc-bank-account-link-form':
          return <IrmgBkBankAccountLinkForm key={key} />

        case 'ai-summary':
          return (
            <AiSummary {...(blockConfigs as IAiSummaryPropsType)} key={key} />
          )

        case 'ai-summary-box':
          return (
            <AiSummaryBox
              {...(blockConfigs as IAiSummaryBoxPropsType)}
              key={key}
            />
          )

        case 'chatbox':
          return <ChatBox {...(blockConfigs as IChatboxPropsType)} key={key} />

        case 'chatbox-for-table':
          return (
            <ChatBoxForTable
              {...(blockConfigs as IChatboxForTablePropsType)}
              key={key}
            />
          )

        case 'upload-reports':
          return (
            <Container key={key} noMargin={noMargin}>
              <Title {...titleProps}>{title}</Title>

              <UploadReport {...(blockConfigs as IUploadReportPropsType)} />
            </Container>
          )

        case 'bubbleWithLabels-chart':
        case 'line-chart':
        case 'bar-chart':
        case 'pie-chart':
          return (
            <Container key={key} noMargin={noMargin}>
              <Title {...titleProps}>{title}</Title>

              <Chart {...(blockConfigs as IChartPropsType)} />
            </Container>
          )
      }
    })}
  </>
)

const Renderer = () => {
  const accessiblePages = useAccessiblePages()

  return (
    <ResetProvider>
      <Switch>
        {Object.keys(accessiblePages).map((key) => {
          const {
            dateFilter,
            groupFilter,
            variables,
            variableTest,
            disableAutoMargin,
            ...pageConfigs
          } = accessiblePages[key]
          const types: IVariablesType[] =
            variables?.map((v) =>
              // FIXME: remove after removing date filter
              typeof v === 'string' && v === 'date' && dateFilter
                ? {
                    type: 'date',
                    types: dateFilter.types?.map((t) =>
                      getTypeMapping(t, dateFilter.isDailyCustom),
                    ),
                    defaultType: !dateFilter.defaultType
                      ? dateFilter.defaultType
                      : getTypeMapping(
                          dateFilter.defaultType,
                          dateFilter.isDailyCustom,
                        ),
                    startDate: dateFilter.startDate,
                    endDate: dateFilter.endDate,
                  }
                : v,
            ) || []
          const hasDateConfig =
            Boolean(dateFilter) ||
            types.some((t) =>
              typeof t === 'string' ? t === 'date' : t.type === 'date',
            )

          return (
            <Route key={key} path={key}>
              <DateFilterProvider hasDateConfig={hasDateConfig}>
                <GroupFilterProvider>
                  <AdvancedFilterProvider>
                    <VariablesProvider test={variableTest} types={types}>
                      <Blocks
                        pageConfigs={
                          pageConfigs as Record<string, IBlockConfigsType>
                        }
                        dateFilter={dateFilter}
                        groupFilter={groupFilter}
                        noMargin={disableAutoMargin}
                      />
                    </VariablesProvider>
                  </AdvancedFilterProvider>
                </GroupFilterProvider>
              </DateFilterProvider>
            </Route>
          )
        })}
      </Switch>
    </ResetProvider>
  )
}

export default Renderer
