import useFarwestCheckAverage, {
  farwestCheckAverageConfigs,
} from './useCheckAverage'
import useFarwestSales, { farwestSalesConfigs } from './useSales'
import useFarwestSss, { farwestSssConfigs } from './useSss'

export const configs = {
  farwestSales: farwestSalesConfigs,
  farwestSss: farwestSssConfigs,
  farwestCheckAverage: farwestCheckAverageConfigs,
}

export const api = {
  farwestSales: useFarwestSales,
  farwestSss: useFarwestSss,
  farwestCheckAverage: useFarwestCheckAverage,
}
