import Link from '@material-ui/core/Link'
import MaterialTypography from '@material-ui/core/Typography'
import _ from 'lodash'
import { useMemo } from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'

import { useVariables } from '../variables'
import useApi, { IApiKeyType } from './hooks/useApi'

export interface IPropsType<K extends IApiKeyType = IApiKeyType> {
  type: 'typography'
  apiOrContent: K | string
  hide?: `<%- ${string} %>`
  variant?: 'h5' | 'body1' | 'body2' | 'link'
  margin?: string
  lineHeight?: string
  onClick?: string
}

interface ITypographyType
  extends Omit<IPropsType, 'type' | 'apiOrContent' | 'onClick'> {
  onClick: () => void
}

export type IConfigsType = {
  [K in IApiKeyType]: IPropsType<K>
}[IApiKeyType]

const StyledTypography = styled(MaterialTypography)<ITypographyType>`
  &.MuiTypography-root {
    ${({ lineHeight }) => (lineHeight ? `line-height: ${lineHeight};` : '')}
    font-family: Lexend-Regular;

    &.MuiTypography-h5 {
      font-family: Lexend-SemiBold;
      font-weight: 700;
      color: ${COLORS.Chalkboard};
    }

    ${({ margin }) =>
      margin
        ? `margin: ${margin};`
        : `
            &:not(:first-child) {
              margin: 20px 0px 0px;
            }
          `}
  }
`

const StyledLink = styled(Link)<ITypographyType>`
  &.MuiTypography-root {
    margin: ${({ margin }) => margin || '20px 0px 0px'};
    ${({ lineHeight }) => (lineHeight ? `line-height: ${lineHeight};` : '')}
    font-family: Lexend-Regular;
    cursor: pointer;
  }
`

const Typography = ({
  apiOrContent,
  hide: originHide,
  variant = 'h5',
  ...props
}: IPropsType) => {
  const { data, loading } = useApi(apiOrContent)
  const { template, variables } = useVariables()
  const hide = useMemo(
    () => (!originHide ? false : template(originHide) === 'true'),
    [originHide, template],
  )
  const onClick = useMemo(
    () => (props.onClick && _.get(variables, props.onClick)) as () => void,
    [props],
  )

  if (loading || hide) return null

  switch (variant) {
    case 'link':
      return (
        <StyledLink
          {...props}
          dangerouslySetInnerHTML={{ __html: data || '' }}
          onClick={onClick}
        />
      )

    default:
      return (
        <StyledTypography
          {...props}
          variant={variant}
          dangerouslySetInnerHTML={{ __html: data || '' }}
          onClick={onClick}
        />
      )
  }
}

export default Typography
