import useJscCorporateDevelopmentFeedback from './useJscCorporateDevelopmentFeedback'
import useJscCorporateDevelopmentPlan from './useJscCorporateDevelopmentPlan'
import useJscDevelopmentPlan from './useJscDevelopmentPlan'
import useJscDevelopmentalFeedback from './useJscDevelopmentalFeedback'

export type IApiKeyType = keyof typeof api

const api = {
  jscDevelopmentalFeedback: useJscDevelopmentalFeedback,
  jscCorporateDevelopmentFeedback: useJscCorporateDevelopmentFeedback,
  jscCorporateDevelopmentPlan: useJscCorporateDevelopmentPlan,
  jscDevelopmentPlan: useJscDevelopmentPlan,
}

const useApi = (apiName: IApiKeyType | null) => {
  if (!apiName) return { data: null, loading: false }

  return api[apiName]?.()
}

export default useApi
