import { IApiDataType } from '../types'
import { api as bbbApi, configs as bbbConfigs } from './bbb'
import {
  api as burgerworksApi,
  configs as burgerworksConfigs,
} from './burgerworks'
import { api as commonApi, configs as commonConfigs } from './common'
import { api as dhWbApi, configs as dhWbConfigs } from './dhWb'
import { api as farwestApi, configs as farwestConfigs } from './farwest'

export type IApiKeyType = keyof typeof api

export const configs = {
  ...bbbConfigs,
  ...commonConfigs,
  ...farwestConfigs,
  ...burgerworksConfigs,
  ...dhWbConfigs,
}

const api = {
  ...bbbApi,
  ...commonApi,
  ...farwestApi,
  ...burgerworksApi,
  ...dhWbApi,
}

const useApi = (
  apiName: IApiKeyType,
): {
  data: IApiDataType
  loading: boolean
} => api[apiName]()

export default useApi
