import useTrendLocationDeliveryKpis, {
  dhWbTrendLocationDeliveryConfigs,
} from './useTrendLocationDeliveryKpis'
import useTrendLocationGroupDelivery, {
  trendLocationGroupDeliveryConfigs,
} from './useTrendLocationGroupDelivery'
import useTrendLocationGroupGuest, {
  trendLocationGroupGuestConfigs,
} from './useTrendLocationGroupGuest'
import useTrendLocationGroupSalesmanshipKpis, {
  dhWbTrendLocationGroupSalesmanshipKpisConfigs,
} from './useTrendLocationGroupSalesmanshipKpis'
import useTrendLocationGuestKpis, {
  dhWbTrendLocationGuestConfigs,
} from './useTrendLocationGuestKpis'
import useTrendLocationSalesmanshipKpis, {
  dhWbTrendLocationSalesmanshipKpisConfigs,
} from './useTrendLocationSalesmanshipKpis'
import useDhWbWeeklySales, { dhWbWeeklySalesConfigs } from './useWeeklySales'

export const configs = {
  dhWbWeeklySales: dhWbWeeklySalesConfigs,
  dhWbTrendLocationGroupDelivery: trendLocationGroupDeliveryConfigs,
  dhWbTrendLocationGroupSalesmanshipKpis:
    dhWbTrendLocationGroupSalesmanshipKpisConfigs,
  dhWbTrendLocationSalesmanshipKpis: dhWbTrendLocationSalesmanshipKpisConfigs,
  dhWbTrendLocationDeliveryKpis: dhWbTrendLocationDeliveryConfigs,
  dhWbTrendLocationGroupGuest: trendLocationGroupGuestConfigs,
  dhWbTrendLocationGuestKpis: dhWbTrendLocationGuestConfigs,
}

export const api = {
  dhWbWeeklySales: useDhWbWeeklySales,
  dhWbTrendLocationGroupDelivery: useTrendLocationGroupDelivery,
  dhWbTrendLocationGroupSalesmanshipKpis: useTrendLocationGroupSalesmanshipKpis,
  dhWbTrendLocationSalesmanshipKpis: useTrendLocationSalesmanshipKpis,
  dhWbTrendLocationDeliveryKpis: useTrendLocationDeliveryKpis,
  dhWbTrendLocationGroupGuest: useTrendLocationGroupGuest,
  dhWbTrendLocationGuestKpis: useTrendLocationGuestKpis,
}
