import buildMetricTrendsHook from '../utils/v2/buildMetricTrendsHook'

export const burgerworksWeeklySalesConfigs = {
  date: 'string',
  netSalesWeeklyAverage: 'price',
} as const

export default buildMetricTrendsHook({
  groupFilterTypes: ['listLocation', 'listLocationGroup'],
  dateRange: 'business_week',
  metrics: ['net_sales_weekly_average'],
})
