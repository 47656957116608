import { IConfigsType } from '../../types'

const dhRankingsConfig: IConfigsType = {
  dh_wb: {
    '/:brand/rankings': {
      variables: [
        'corporateGroup',
        {
          type: 'rankings',
          defaultValue: ['financials', 'netSales'],
          options: [
            // financials
            { id: 'financials', parentId: 'root', displayName: 'Financials' },
            {
              id: 'netSales',
              parentId: 'financials',
              displayName: 'Net Sales',
              info: {
                type: 'price',
                fields: [
                  {
                    key: 'price0',
                    title: 'Net Sales',
                    metricCode: 'netSales',
                  },
                ],
              },
            },
            {
              id: 'daypart',
              parentId: 'financials',
              displayName: 'Daypart',
              info: {
                type: 'daypart',
                fields: [
                  {
                    key: 'price0',
                    title: 'Lunch',
                    metricCode: 'dayPartSalesLunch',
                  },
                  {
                    key: 'price1',
                    title: 'Dinner',
                    metricCode: 'dayPartSalesDinner',
                  },
                  {
                    key: 'price2',
                    title: 'Breakfast',
                    metricCode: 'dayPartSalesBreakfast',
                  },
                  {
                    key: 'price3',
                    title: 'Early Bird',
                    metricCode: 'dayPartSalesEarlyBird',
                  },
                  {
                    key: 'price4',
                    title: 'Evening Snack',
                    metricCode: 'dayPartSalesEveningSnack',
                  },
                  {
                    key: 'price5',
                    title: 'Afternoon Snack',
                    metricCode: 'dayPartSalesAfternoonSnack',
                  },
                ],
              },
            },
            {
              id: 'sss',
              parentId: 'financials',
              displayName: 'SSS',
              info: {
                type: 'price + percent',
                fields: [
                  {
                    key: 'price0',
                    title: '$ SSS',
                    metricCode: 'netSalesSssVariance',
                  },
                  {
                    key: 'percent0',
                    title: 'SSS%',
                    metricCode: 'netSalesSssPercent',
                  },
                ],
              },
            },
            {
              id: 'hourlyLaborPercent',
              parentId: 'financials',
              displayName: 'Hourly Labor Percent',
              info: {
                type: 'percent',
                fields: [
                  {
                    key: 'percent0',
                    title: 'Hourly Labor Percent',
                    metricCode: 'laborTotalCostPercent',
                  },
                ],
              },
            },

            // salesmanship
            {
              id: 'salesmanship',
              parentId: 'root',
              displayName: 'Salesmanship',
            },
            {
              id: 'speedOfService',
              parentId: 'salesmanship',
              displayName: 'Speed Of Service',
              info: {
                type: 'minutes + percent',
                fields: [
                  {
                    key: 'number0',
                    title: 'Speed of Service',
                    metricCode: 'sosAvg',
                  },
                  {
                    key: 'percent0',
                    title: 'Goal A %',
                    metricCode: 'sosGoalPercent',
                  },
                ],
              },
            },
            {
              id: 'salesPerLaborHour',
              parentId: 'salesmanship',
              displayName: 'Sales Per Labor Hour',
              info: {
                type: 'price',
                fields: [
                  {
                    key: 'price0',
                    title: 'Sales per Labor Hour',
                    metricCode: 'salesPerLaborHour',
                  },
                ],
              },
            },
            {
              id: 'checkAvg',
              parentId: 'salesmanship',
              displayName: 'Check Avg',
              info: {
                type: 'price',
                fields: [
                  {
                    key: 'price0',
                    title: 'Check Avg',
                    metricCode: 'checkAverage',
                  },
                ],
              },
            },
            {
              id: 'dessertsAttachmentRate',
              parentId: 'salesmanship',
              displayName: 'Desserts Attachment Rate',
              info: {
                type: 'percent',
                fields: [
                  {
                    key: 'percent0',
                    title: 'Desserts Attachment Rate',
                    metricCode: 'itemAttachmentRateDesserts',
                  },
                ],
              },
            },
            {
              id: 'beveragesAttachmentRate',
              parentId: 'salesmanship',
              displayName: 'Beverages Attachment Rate',
              info: {
                type: 'percent',
                fields: [
                  {
                    key: 'percent0',
                    title: 'Beverages Attachment Rate',
                    metricCode: 'itemAttachmentRateBeverages',
                  },
                ],
              },
            },
            {
              id: 'baconAttachmentRate',
              parentId: 'salesmanship',
              displayName: 'Bacon Attachment Rate',
              info: {
                type: 'percent',
                fields: [
                  {
                    key: 'percent0',
                    title: 'Bacon Attachment Rate',
                    metricCode: 'itemAttachmentRateBacon',
                  },
                ],
              },
            },
            {
              id: 'cheeseAttachmentRate',
              parentId: 'salesmanship',
              displayName: 'Cheese Attachment Rate',
              info: {
                type: 'percent',
                fields: [
                  {
                    key: 'percent0',
                    title: 'Cheese Attachment Rate',
                    metricCode: 'itemAttachmentRateCheese',
                  },
                ],
              },
            },
            {
              id: 'jalapenoAttachmentRate',
              parentId: 'salesmanship',
              displayName: 'Jalapeno Attachment Rate',
              info: {
                type: 'percent',
                fields: [
                  {
                    key: 'percent0',
                    title: 'Jalapeno Attachment Rate',
                    metricCode: 'itemAttachmentRateJalapeno',
                  },
                ],
              },
            },

            // loss prevention
            {
              id: 'lossPrevention',
              parentId: 'root',
              displayName: 'Loss Prevention',
            },
            {
              id: 'discounts',
              parentId: 'lossPrevention',
              displayName: 'Discounts',
              info: {
                type: 'price + percent',
                fields: [
                  {
                    key: 'price0',
                    title: '$',
                    metricCode: 'discountCompsTotal',
                  },
                  {
                    key: 'percent0',
                    title: '% of Sales',
                    metricCode: 'discountCompsPercentTotal',
                  },
                ],
              },
            },

            // guest
            { id: 'guest', parentId: 'root', displayName: 'Guest' },
            {
              id: 'googleRating',
              parentId: 'guest',
              displayName: 'Google Rating',
              info: {
                type: 'number + number',
                fields: [
                  {
                    key: 'number0',
                    title: 'Avg Rating',
                    metricCode: 'googleRating',
                  },
                  {
                    key: 'number1',
                    title: 'Rating Count',
                    metricCode: 'googleRatingCount',
                  },
                ],
              },
            },
            {
              id: 'yelpRating',
              parentId: 'guest',
              displayName: 'Yelp Rating',
              info: {
                type: 'number + number',
                fields: [
                  {
                    key: 'number0',
                    title: 'Avg Rating',
                    metricCode: 'yelpRating',
                  },
                  {
                    key: 'number1',
                    title: 'Rating Count',
                    metricCode: 'yelpRatingCount',
                  },
                ],
              },
            },
            {
              id: 'smg',
              parentId: 'guest',
              displayName: 'SMG',
              info: {
                type: 'count + number',
                fields: [
                  {
                    key: 'number0',
                    title: 'Total Surveys',
                    metricCode: 'smgTotalSurveyCount',
                  },
                  {
                    key: 'number1',
                    title: 'Overall Satisfaction Rating',
                    metricCode: 'smgOverallSatisfactionRating',
                  },
                ],
              },
            },

            // delivery
            { id: 'delivery', parentId: 'root', displayName: 'Delivery' },
            {
              id: 'deliverySales',
              parentId: 'delivery',
              displayName: 'Delivery Sales',
              info: {
                type: 'price',
                fields: [
                  {
                    key: 'price0',
                    title: '$',
                    metricCode: 'deliverySumSubtotalDoordash',
                  },
                ],
              },
            },
            {
              id: 'orderWithAnyIssues',
              parentId: 'delivery',
              displayName: '% Order w / Any Issues',
              info: {
                type: 'percent',
                fields: [
                  {
                    key: 'percent0',
                    title: '% Orders w/ Any Issues',
                    metricCode: 'deliveryOrderWithAnyIssuePercentDoordash',
                  },
                ],
              },
            },
            {
              id: 'combinedStarRating',
              parentId: 'delivery',
              displayName: 'Combined Star Rating',
              info: {
                type: 'number',
                fields: [
                  {
                    key: 'number0',
                    title: 'Combined Star Rating',
                    metricCode: 'deliveryAvgCustomerReviewScoreDoordash',
                  },
                ],
              },
            },
          ],
        },
      ],
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
        ],
      },
      title: {
        type: 'title',
        title: 'Rankings',
      },
      filterBy: {
        type: 'select',
        value: 'rankings.value',
        onChange: 'rankings.onChange',
        options: 'rankings.options',
        label: 'Rank By',
      },
      downloadRankings: {
        type: 'download-csv',
        api: 'dhRankings',
        fileName: 'Ranking',
        message: 'Download Ranking as CSV',
        fields: [
          {
            key: 'rowIndex',
            title: 'Rank',
          },
          'groupInfo',

          // type = price
          {
            key: 'price0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "price" %>',
          },

          // type = percent
          {
            key: 'percent0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "percent" %>',
          },

          // type = number
          {
            key: 'number0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "number" %>',
          },

          // type = count
          {
            key: 'number0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "count" %>',
          },

          // type = number + number
          {
            key: 'number0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "number + number" %>',
          },
          {
            key: 'number1',
            title: '<%- rankings.info?.fields[1].title %>',
            hide: '<%- rankings.info?.type !== "number + number" %>',
          },

          // type = count + number
          {
            key: 'number0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "count + number" %>',
          },
          {
            key: 'number1',
            title: '<%- rankings.info?.fields[1].title %>',
            hide: '<%- rankings.info?.type !== "count + number" %>',
          },

          // type = minutes + percent
          {
            key: 'number0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "minutes + percent" %>',
          },
          {
            key: 'percent0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "minutes + percent" %>',
          },

          // type = price + percent
          {
            key: 'price0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "price + percent" %>',
          },
          {
            key: 'percent0',
            title: '<%- rankings.info?.fields[1].title %>',
            hide: '<%- rankings.info?.type !== "price + percent" %>',
          },

          // type = daypart
          {
            key: 'price0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "daypart" %>',
          },
          {
            key: 'price1',
            title: '<%- rankings.info?.fields[1].title %>',
            hide: '<%- rankings.info?.type !== "daypart" %>',
          },
          {
            key: 'price2',
            title: '<%- rankings.info?.fields[2].title %>',
            hide: '<%- rankings.info?.type !== "daypart" %>',
          },
          {
            key: 'price3',
            title: '<%- rankings.info?.fields[3].title %>',
            hide: '<%- rankings.info?.type !== "daypart" %>',
          },
          {
            key: 'price4',
            title: '<%- rankings.info?.fields[4].title %>',
            hide: '<%- rankings.info?.type !== "daypart" %>',
          },
          {
            key: 'price5',
            title: '<%- rankings.info?.fields[5].title %>',
            hide: '<%- rankings.info?.type !== "daypart" %>',
          },
        ],
      },
      rankings: {
        type: 'table-v2',
        api: 'dhRankings',
        columns: [
          {
            key: 'rowIndex',
            title: 'Rank',
            align: 'center',
          },
          {
            key: '<%- JSON(corporateGroup?.tableColumns.slice(0, 1)) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            width: 'initial',
          },

          // type = price
          {
            key: 'price0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "price" %>',
            decimal: 2,
            align: 'center',
            sortable: true,
          },

          // type = percent
          {
            key: 'percent0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "percent" %>',
            decimal: 2,
            align: 'center',
            sortable: true,
          },

          // type = number
          {
            key: 'number0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "number" %>',
            decimal: 2,
            align: 'center',
            sortable: true,
          },

          // type = count
          {
            key: 'number0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "count" %>',
            align: 'center',
            sortable: true,
          },

          // type = number + number
          {
            key: 'number0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "number + number" %>',
            decimal: 2,
            align: 'center',
            sortable: true,
          },
          {
            key: 'number1',
            title: '<%- rankings.info?.fields[1].title %>',
            hide: '<%- rankings.info?.type !== "number + number" %>',
            align: 'center',
          },

          // type = count + number
          {
            key: 'number0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "count + number" %>',
            align: 'center',
            sortable: true,
          },
          {
            key: 'number1',
            title: '<%- rankings.info?.fields[1].title %>',
            hide: '<%- rankings.info?.type !== "count + number" %>',
            align: 'center',
            sortable: true,
            decimal: 1,
          },

          // type = minutes + percent
          {
            key: 'number0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "minutes + percent" %>',
            decimal: 2,
            format: `<%- Math.floor(value).toString() + ":" + Math.floor(((value - Math.floor(value)) * 60)).toString().padStart(2, '0') %>`,
            align: 'center',
            sortable: true,
          },
          {
            key: 'percent0',
            title: '<%- rankings.info?.fields[1].title %>',
            hide: '<%- rankings.info?.type !== "minutes + percent" %>',
            decimal: 2,
            align: 'center',
            sortable: true,
          },

          // type = price + percent
          {
            key: 'price0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "price + percent" %>',
            decimal: 2,
            align: 'center',
            sortable: true,
          },
          {
            key: 'percent0',
            title: '<%- rankings.info?.fields[1].title %>',
            hide: '<%- rankings.info?.type !== "price + percent" %>',
            decimal: 2,
            align: 'center',
            sortable: true,
          },

          // type = daypart
          {
            key: 'price0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "daypart" %>',
            decimal: 2,
            align: 'center',
            sortable: true,
          },
          {
            key: 'price1',
            title: '<%- rankings.info?.fields[1].title %>',
            hide: '<%- rankings.info?.type !== "daypart" %>',
            decimal: 2,
            align: 'center',
            sortable: true,
          },
          {
            key: 'price2',
            title: '<%- rankings.info?.fields[2].title %>',
            hide: '<%- rankings.info?.type !== "daypart" %>',
            decimal: 2,
            align: 'center',
            sortable: true,
          },
          {
            key: 'price3',
            title: '<%- rankings.info?.fields[3].title %>',
            hide: '<%- rankings.info?.type !== "daypart" %>',
            decimal: 2,
            align: 'center',
            sortable: true,
          },
          {
            key: 'price4',
            title: '<%- rankings.info?.fields[4].title %>',
            hide: '<%- rankings.info?.type !== "daypart" %>',
            decimal: 2,
            align: 'center',
            sortable: true,
          },
          {
            key: 'price5',
            title: '<%- rankings.info?.fields[5].title %>',
            hide: '<%- rankings.info?.type !== "daypart" %>',
            decimal: 2,
            align: 'center',
            sortable: true,
          },
        ],
      },
    },
  },
}

export default dhRankingsConfig
