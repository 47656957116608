export const NUMBER_OF_PREVIOUS_QUESTIONS_TO_CONSIDER_TOGETHER = 10

// data type
export const VALUE_TYPE_DOLLAR_AMOUNT = 'DOLLAR_AMOUNT'
export const VALUE_TYPE_CENT_AMOUNT = 'CENT_AMOUNT'
export const VALUE_TYPE_FLOAT = 'FLOAT'
export const VALUE_TYPE_PERCENT = 'PERCENT'
export const VALUE_TYPE_COUNT = 'COUNT'
export const VALUE_TYPE_STRING = 'STRING'
export const VALUE_TYPE_SECOND = 'SECOND'
export const VALUE_TYPE_HOUR = 'HOUR'

// cell type
export const CLICKABLE_BUTTON = 'clickableBtn'
export const LINK_BUTTON = 'linkBtn'

// unit: 'px'
export const PAGE_PADDING_LEFT = 50

export const FROM_USER = 'user'
export const FROM_EXPO = 'expo'

// special requests (must be consistent with the BE)
export const REQUEST_ASK_TABLE = 'ask referenced table'
export const REQUEST_SALES_ANALYSIS = 'get sales analysis'
export const REQUEST_DAY_PART_ANALYSIS = 'get day part analysis'
export const REQUEST_REVENUE_CENTER_ANALYSIS = 'get revenue center analysis'
export const REQUEST_LABOR_ANALYSIS = 'get labor analysis'
export const REQUEST_DAY_PART_LABOR = 'get daypart labor'
export const REQUEST_POSITION_LABOR = 'get position labor'
export const REQUEST_COGS_ANALYSIS = 'get COGS analysis'
