import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'

import { useDateFilter } from '../../../../dateFilter'
import { useGroupFilter } from '../../../../groupFilter'
import { IApiDataType } from '../../../types'

interface IDataType {
  listLocationGuestComments: {
    nodes: {
      date: string
      reviewSource: string
      reviewContent: string
      ratingScore: number
    }[]
  }
}

const query = gql`
  query ListLocationGuestComments(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    listLocationGuestComments(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        reviewSource
        reviewContent
        ratingScore
        date
      }
    }
  }
`

export const dhWbListLocationGuestCommentDataConfigs = {
  reviewSource: 'string',
  reviewContent: 'string',
  originalDate: 'string',
  date: 'string',
  ratingScore: 'stars',
} as const

const useListLocationGuestCommentData = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iLocationId: groupFilter?.ids?.[0] || 0,
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return null

      const listLocationGuestCommentsNodes =
        data.listLocationGuestComments.nodes

      return (
        listLocationGuestCommentsNodes.map((n, i) => ({
          id: `${n.date}-${n.reviewSource}-${i}`,
          parentId: 'root',
          originalDate: n.date,
          date: moment(n.date).fromNow(),
          reviewSource: _.startCase(n.reviewSource),
          reviewContent: n.reviewContent,
          ratingScore: n.ratingScore,
        })) || []
      )
    }, [data]),
    loading: loading,
  }
}

export default useListLocationGuestCommentData
