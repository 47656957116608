import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import calc from '../../../utils/calc'
import { useVariables } from '../../../variables'
import { IApiDataType } from '../../types'
import buildMetricValuesHook from '../utils/v2/buildMetricValuesHook'

export const burgerworksListSystemwideOverviewKpisConfigs = {
  netSales: 'price',
  checkCount: 'number',
  checkAverage: 'price',
  netSalesSssPercent: 'percent',
  laborTotalCostPercent: 'percent',
  averageWeeklySales: 'price',
} as const

type IMetricDataType = Record<
  string,
  {
    name: string
    unit: 'CENT' | 'PERCENTAGE' | 'DOLLAR' | 'COUNT'
    value: number
  }
>

type IDataType<T extends string = 'listLocationGroupAverageWeeklySales'> =
  Record<
    T,
    {
      nodes: Record<'metricSummaryData', IMetricDataType>[]
    }
  >

const query = gql`
  query ListLocationGroupAverageWeeklySales(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupAverageWeeklySales(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        metricSummaryData
      }
    }
  }
`

const useCommonBurgerworksSystemwideOverview = buildMetricValuesHook({
  groupFilterTypes: ['listLocationGroup', 'listLocation'],
  metrics: [
    'net_sales',
    { key: 'net_sales', type: 'yoy' },
    'check_count',
    'check_average',
    'labor_total_cost_percent',
  ],
  handler: (data) => ({
    ...data,
    netSalesSssPercent:
      calc(
        calc(calc(data.netSales, '-', data.yoyNetSales), '/', data.yoyNetSales),
        '*',
        100,
      ) || null,
  }),
})

const useBurgerworksListSystemwideOverviewKpis = () => {
  const { data: commonData, loading: commonLoading } =
    useCommonBurgerworksSystemwideOverview()
  const { variables } = useVariables()
  const dateFilterInfo = variables.date?.getInfo(0)
  const groupFilterInfo = variables.groupFilter?.getInfo([
    'listLocationGroup',
    'listLocation',
  ])
  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iStartDate: dateFilterInfo?.dateRange.startDateStr,
      iEndDate: dateFilterInfo?.dateRange.endDateStr,
      iFilter: {
        location_group_ids: groupFilterInfo?.locationGroupIds,
        metrics: ['net_sales'],
      },
    },
    skip: !dateFilterInfo || !groupFilterInfo,
  })

  return {
    data: useMemo((): IApiDataType => {
      if (!commonData) return commonData

      const nodes = data?.listLocationGroupAverageWeeklySales?.nodes
      const averageWeeklySales = nodes?.find((n) => n.metricSummaryData)
        ?.metricSummaryData?.averageWeeklySales?.value

      return {
        ...commonData,
        averageWeeklySales: averageWeeklySales
          ? averageWeeklySales * 100
          : null,
      }
    }, [commonData, data]),
    loading: commonLoading || loading,
  }
}

export default useBurgerworksListSystemwideOverviewKpis
