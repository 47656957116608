export interface IGuestConfig {
  acr: {
    isVisible: boolean
    hideSurveyCount?: boolean
  }
  momentfeed: boolean
  yelp: boolean
  facebook: boolean
  delivery: boolean
  google: boolean
  yext: boolean
  opsComps: boolean
  allRating: {
    isVisible: boolean
    displayName?: string
  }
  isUpdatedWeekly: boolean
  smg?: {
    isVisible: boolean
  }
  opentable?: {
    isVisible: boolean
  }
  kpiDisplayName?: string
  smgMwb?: {
    isVisible: boolean
  }
  useSimpleAverage?: boolean
  hideKpis?: boolean
  hideRatingChart?: boolean
  hideBreakdown?: boolean
  decimal: number
}

export function getGuestConfig(brand: string): IGuestConfig {
  switch (brand) {
    case 'demo_bk':
    case 'irmg_bk':
    case 'supreme_bk':
    case 'timoney_bk':
    case 'mbn_bk':
      return {
        acr: {
          isVisible: true,
        },
        momentfeed: false,
        yelp: true,
        facebook: false,
        delivery: true,
        opsComps: false,
        allRating: {
          isVisible: false,
        },
        isUpdatedWeekly: true,
        google: true,
        yext: false,
        decimal: 1,
      }

    case 'ghai_pop':
      return {
        acr: {
          isVisible: true,
          hideSurveyCount: true,
        },
        momentfeed: false,
        yelp: true,
        facebook: false,
        delivery: true,
        opsComps: false,
        allRating: {
          isVisible: false,
        },
        isUpdatedWeekly: true,
        google: true,
        yext: false,
        decimal: 1,
      }

    case 'supreme_pop':
    case 'ace_pop':
      return {
        acr: {
          isVisible: true,
          hideSurveyCount: true,
        },
        momentfeed: false,
        yelp: true,
        facebook: false,
        delivery: false,
        opsComps: false,
        allRating: {
          isVisible: false,
        },
        isUpdatedWeekly: true,
        google: true,
        yext: false,
        decimal: 1,
      }

    case 'irmg_pop':
    case 'viking_bk':
    case 'sandbox_bk':
    case 'demo_pop':
      return {
        acr: {
          isVisible: false,
        },
        momentfeed: false,
        yelp: true,
        facebook: false,
        delivery: true,
        opsComps: false,
        allRating: {
          isVisible: false,
        },
        isUpdatedWeekly: true,
        google: true,
        yext: false,
        decimal: 1,
      }

    case 'jsc_bk':
    case 'jsc_bk_nso':
      return {
        acr: {
          isVisible: true,
        },
        momentfeed: false,
        yelp: true,
        facebook: false,
        delivery: true,
        opsComps: false,
        allRating: {
          isVisible: false,
        },
        isUpdatedWeekly: false,
        google: true,
        yext: false,
        decimal: 1,
      }

    case 'rmg': // Romano's Macaroni Grill
    case 'rmg_nso': // RMG NSO
    case 'sullivans': // Sullivan's
    case 'sullivans_nso': // Sullivan's NSO
    case 'eddiemerlots': // Eddie Merlot's
    case 'eddiemerlots_nso':
    case 'qsr_shack': // QSR Demo
      return {
        acr: {
          isVisible: false,
        },
        momentfeed: true,
        yelp: true,
        facebook: true,
        delivery: true,
        opsComps: true,
        allRating: {
          isVisible: false,
        },
        isUpdatedWeekly: true,
        opentable: {
          isVisible: true,
        },
        kpiDisplayName: 'Social',
        useSimpleAverage: true,
        google: true,
        yext: false,
        decimal: 1,
      }
    case 'cava':
      return {
        acr: {
          isVisible: false,
        },
        momentfeed: false,
        yelp: true,
        facebook: false,
        delivery: false,
        opsComps: false,
        allRating: {
          isVisible: true,
          displayName: 'Yext',
        },
        isUpdatedWeekly: false,
        google: true,
        yext: false,
        decimal: 1,
      }
    case 'jitb':
      return {
        acr: {
          isVisible: false,
        },
        momentfeed: false,
        yelp: true,
        facebook: false,
        delivery: true,
        opsComps: false,
        allRating: {
          isVisible: false,
        },
        isUpdatedWeekly: true,
        google: true,
        yext: false,
        decimal: 1,
      }
    case 'wingitnorth':
      return {
        acr: {
          isVisible: false,
        },
        momentfeed: false,
        yelp: true,
        facebook: false,
        delivery: true,
        opsComps: false,
        allRating: {
          isVisible: false,
        },
        isUpdatedWeekly: false,
        smg: {
          isVisible: true,
        },
        google: true,
        yext: false,
        decimal: 1,
      }
    case 'lfr_ef':
    case 'lfr_tr':
    case 'lfr_sn':
    case 'lfr_lv':
    case 'lfr_vb':
    case 'lfr_tu':
    case 'lfr_ws':
    case 'lfr_jn':
    case 'lfr_mm':
    case 'lfr_ol':
    case 'lfr_dt':
    case 'lfr_uv':
    case 'lfr_bth':
      return {
        acr: {
          isVisible: false,
        },
        momentfeed: false,
        yelp: true,
        facebook: false,
        delivery: true,
        opsComps: false,
        allRating: {
          isVisible: false,
        },
        isUpdatedWeekly: true,
        google: true,
        yext: false,
        decimal: 1,
      }
    case 'mwb':
      return {
        acr: {
          isVisible: false,
        },
        momentfeed: false,
        yelp: false,
        facebook: false,
        delivery: true,
        opsComps: false,
        allRating: {
          isVisible: false,
        },
        isUpdatedWeekly: false,
        smgMwb: {
          isVisible: true,
        },
        google: true,
        yext: false,
        decimal: 1,
      }
    case 'burgerworks':
    case 'dh_wb':
      return {
        acr: {
          isVisible: false,
        },
        momentfeed: false,
        yelp: true,
        facebook: false,
        delivery: true,
        opsComps: false,
        allRating: {
          isVisible: false,
        },
        isUpdatedWeekly: false,
        smgMwb: {
          isVisible: true,
        },
        google: true,
        yext: false,
        decimal: 1,
      }
    case 'bibibop':
      return {
        acr: {
          isVisible: false,
        },
        momentfeed: false,
        yelp: false,
        facebook: false,
        delivery: true,
        opsComps: false,
        allRating: {
          isVisible: false,
        },
        isUpdatedWeekly: false,
        google: false,
        yext: true,
        decimal: 2,
      }
    case 'fw_wingstop':
      return {
        acr: {
          isVisible: false,
        },
        momentfeed: false,
        yelp: false,
        facebook: false,
        delivery: false,
        opsComps: false,
        allRating: {
          isVisible: false,
        },
        isUpdatedWeekly: false,
        smg: {
          isVisible: true,
        },
        hideKpis: true,
        hideRatingChart: true,
        hideBreakdown: true,
        google: true,
        yext: false,
        decimal: 1,
      }
    default:
      return {
        acr: {
          isVisible: false,
        },
        momentfeed: false,
        yelp: true,
        facebook: false,
        delivery: true,
        opsComps: true,
        allRating: {
          isVisible: false,
        },
        isUpdatedWeekly: true,
        google: true,
        yext: false,
        decimal: 1,
      }
  }
}
