import { useMemo } from 'react'

import { corporateGroupTableConfigs } from '../../../../variables'
import buildMetricValuesHook from '../../utils/buildMetricValuesHook'
import { DISCOUNT_TYPES } from './constants'

const commonOpsCompsBreakdownDataDataConfigs = {
  discountName: 'string',
  discountComps: 'price',
  discountCheckCount: 'number',
  discountCompsRank: 'number',
} as const

export const dhWbLocationOpsCompsBreakdownDataConfigs = {
  ...corporateGroupTableConfigs,
  ...commonOpsCompsBreakdownDataDataConfigs,
} as const

const useCommonOpsCompsBreakdownDataData = buildMetricValuesHook({
  groupFilterType: 'store',
  metrics: {
    groups: [
      'Expo Module - Discount Comps',
      'Expo Module - Discount Check Count',
    ],
  },
})

const useLocationOpsCompsBreakdownData = () => {
  const { data: commonData, loading } = useCommonOpsCompsBreakdownDataData()

  return {
    data: useMemo(() => {
      if (!commonData?.[0]) return null

      const discountTypeSummary = DISCOUNT_TYPES.reduce(
        (sum, d) => {
          const discountComps = commonData[0]?.[d.compsKey] as number
          if (discountComps) {
            return [
              ...sum,
              {
                discountName: d.discountName,
                discountComps,
                discountCheckCount: commonData[0]?.[d.checkCountKey] as number,
              },
            ]
          }

          return sum
        },
        [] as {
          discountName: string
          discountComps: number
          discountCheckCount: number
        }[],
      )
        .sort((a, b) => b.discountComps - a.discountComps)
        .map((d, idx) => ({
          ...d,
          discountCompsRank: idx + 1,
          id: d.discountName,
          parentId: 'root',
        }))

      return discountTypeSummary
    }, [commonData, loading]),
    loading,
  }
}

export default useLocationOpsCompsBreakdownData
