import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import { getBrandLocationGroupId } from 'pared/utils/brand'

import { useDateFilter } from '../../../dateFilter'
import { useVariables } from '../../../variables'
import { IApiDataType } from '../../types'

interface ICurrentDataType {
  trendLocationGroupMetricValues: {
    nodes: {
      businessMonth: number
      businessWeek: number
      businessWeekOfMonth: number
      metricData: Record<
        string,
        {
          name: string
          unit: string
          value: number
        }
      >
    }[]
  }
}

interface IPriorDataType {
  trendPriorYearLocationGroupMetricValues: {
    nodes: {
      businessMonth: number
      businessWeek: number
      businessWeekOfMonth: number
      metricData: Record<
        string,
        {
          name: string
          unit: string
          value: number
        }
      >
    }[]
  }
}

interface IReturnType {
  period: string
  value: number | null
}

const currentYearQuery = gql`
  query trendLocationGroupMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iGroupBy: String!
    $iFilter: JSON!
  ) {
    trendLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iFilter: $iFilter
    ) {
      nodes {
        businessMonth
        businessWeek
        businessWeekOfMonth
        metricData
      }
    }
  }
`

const priorYearQuery = gql`
  query trendPriorYearLocationGroupMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iGroupBy: String!
    $iFilter: JSON!
  ) {
    trendPriorYearLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iFilter: $iFilter
    ) {
      nodes {
        businessMonth
        businessWeek
        businessWeekOfMonth
        metricData
      }
    }
  }
`

export const farwestSssConfigs = {
  period: 'string',
  value: 'percent',
  valueLastYear: 'percent',
} as const

const useFarwestSss = () => {
  const { variables } = useVariables()
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const brandLocationGroupId = getBrandLocationGroupId()

  const groupBy = variables.farwestTimeinterval?.value[0][0] || 'business_month'

  const dateList =
    groupBy === 'business_week'
      ? variables.date?.getCalendar('week', 'year')?.ids || [[]]
      : variables.date?.getCalendar('period', 'year')?.ids || [[]]

  const metrics = variables.farwestFinancialSssCharts?.value[0][1]

  const { data: currentData, loading: currentLoading } =
    useQuery<ICurrentDataType>(currentYearQuery, {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iGroupBy: groupBy,
        iFilter: {
          location_group_ids: !hasGroupBy
            ? [brandLocationGroupId]
            : groupFilter?.ids,
          intersected_location_group_ids: groupFilter?.intersectedIds,
          metrics: [`${metrics}_percentage_diff_to_last_year`],
        },
        intersected_location_group_ids: groupFilter?.intersectedIds,
      },
      skip: !startDate || !endDate || !variables.date,
    })

  const { data: priorData, loading: priorLoading } = useQuery<IPriorDataType>(
    priorYearQuery,
    {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iGroupBy: groupBy,
        iFilter: {
          location_group_ids: !hasGroupBy
            ? [brandLocationGroupId]
            : groupFilter?.ids,
          intersected_location_group_ids: groupFilter?.intersectedIds,
          metrics: [`${metrics}_percentage_diff_to_last_year`],
        },
        intersected_location_group_ids: groupFilter?.intersectedIds,
      },
      skip: !startDate || !endDate || !variables.date,
    },
  )

  const loading = currentLoading && priorLoading

  return {
    data: useMemo((): IApiDataType => {
      if (
        !currentData?.trendLocationGroupMetricValues.nodes ||
        !priorData?.trendPriorYearLocationGroupMetricValues.nodes ||
        !metrics
      )
        return null

      const metricData = currentData?.trendLocationGroupMetricValues.nodes.map(
        (item): IReturnType => {
          return {
            period:
              groupBy === 'business_month'
                ? `P${item.businessMonth}`
                : `P${item.businessMonth}W${item.businessWeekOfMonth}`,
            value:
              item.metricData?.[`${metrics}_percentage_diff_to_last_year`]
                .value * 100,
          }
        },
      )
      const priorMetricData =
        priorData?.trendPriorYearLocationGroupMetricValues.nodes.map(
          (item): IReturnType => {
            return {
              period:
                groupBy === 'business_month'
                  ? `P${item.businessMonth}`
                  : `P${item.businessMonth}W${item.businessWeekOfMonth}`,
              value:
                item.metricData?.[`${metrics}_percentage_diff_to_last_year`]
                  .value * 100,
            }
          },
        )

      return dateList
        .map((date) => {
          const currentData = metricData.find((d) => d.period === date)
          const sameDaylyData = priorMetricData.find((d) => d.period === date)

          return {
            period: date,
            value: currentData?.value,
            valueLastYear: sameDaylyData?.value,
          }
        })
        .filter((d) => d.period)
    }, [currentData, priorData, startDate, endDate]),
    loading,
  }
}

export default useFarwestSss
