import useBurgerworksListItemMetricValues, {
  burgerworksListItemMetricValuesConfigs,
} from './useListItemMetricValues'
import useListLocationDeliveryKpis, {
  burgerworksListLocationDeliveryConfigs,
} from './useListLocationDeliveryKpis'
import useListLocationGroupDeliveryKpis, {
  burgerworksListLocationGroupDeliveryConfigs,
} from './useListLocationGroupDeliveryKpis'
import useBurgerworksListLocationGroupGuestKpis, {
  burgerworksListLocationGroupGuestKpisConfigs,
} from './useListLocationGroupGuestKpis'
import useBurgerworksListLocationGroupLossPreventionKpis, {
  burgerworksListLocationGroupLossPreventionKpisConfigs,
} from './useListLocationGroupLossPreventionKpis'
import useBurgerworksListLocationGroupSalesmanshipKpis, {
  burgerworksListLocationGroupSalesmanshipKpisConfigs,
} from './useListLocationGroupSalesmanshipKpis'
import useListLocationGuestKpis, {
  burgerworksListLocationGuestKpisConfigs,
} from './useListLocationGuestKpis'
import useBurgerworksListLocationLossPreventionKpis, {
  burgerworksListLocationLossPreventionKpisConfigs,
} from './useListLocationLossPreventionKpis'
import useBurgerworksListStorePerformanceKpis, {
  burgerworksListStorePerformanceKpisConfigs,
} from './useListStorePerformanceKpis'
import useBurgerworksListSystemwideOverviewKpis, {
  burgerworksListSystemwideOverviewKpisConfigs,
} from './useListSystemwideOverviewKpisKpis'

export const configs = {
  burgerworksListLocationGroupSalesmanshipKpis:
    burgerworksListLocationGroupSalesmanshipKpisConfigs,
  burgerworksListLocationGroupLossPreventionKpis:
    burgerworksListLocationGroupLossPreventionKpisConfigs,
  burgerworksListLocationLossPreventionKpis:
    burgerworksListLocationLossPreventionKpisConfigs,
  burgerworksListSystemwideOverviewKpis:
    burgerworksListSystemwideOverviewKpisConfigs,
  burgerworksListStorePerformanceKpis:
    burgerworksListStorePerformanceKpisConfigs,
  burgerworksListLocationGroupDeliveryKpis:
    burgerworksListLocationGroupDeliveryConfigs,
  burgerworksListLocationDeliveryKpis: burgerworksListLocationDeliveryConfigs,
  burgerworksListLocationGroupGuestKpis:
    burgerworksListLocationGroupGuestKpisConfigs,
  burgerworksListLocationGuestKpis: burgerworksListLocationGuestKpisConfigs,
  burgerworksListItemMetricValues: burgerworksListItemMetricValuesConfigs,
}

export const api = {
  burgerworksListLocationGroupSalesmanshipKpis:
    useBurgerworksListLocationGroupSalesmanshipKpis,
  burgerworksListLocationGroupLossPreventionKpis:
    useBurgerworksListLocationGroupLossPreventionKpis,
  burgerworksListLocationLossPreventionKpis:
    useBurgerworksListLocationLossPreventionKpis,
  burgerworksListSystemwideOverviewKpis:
    useBurgerworksListSystemwideOverviewKpis,
  burgerworksListStorePerformanceKpis: useBurgerworksListStorePerformanceKpis,
  burgerworksListLocationGroupDeliveryKpis: useListLocationGroupDeliveryKpis,
  burgerworksListLocationDeliveryKpis: useListLocationDeliveryKpis,
  burgerworksListLocationGroupGuestKpis:
    useBurgerworksListLocationGroupGuestKpis,
  burgerworksListLocationGuestKpis: useListLocationGuestKpis,
  burgerworksListItemMetricValues: useBurgerworksListItemMetricValues,
}
