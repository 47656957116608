import { useMemo } from 'react'

import { IApiDataType } from '../../../types'
import buildLocationEmployeeMetricValuesHook from '../../utils/buildLocationEmployeeMetricValuesHook'

const commonLossPreventionDataConfigs = {
  checkCount: 'number',
  lossPreventionOverringCheckCount: 'number',
  lossPreventionOverringAmount: 'price',
  lossPreventionDeleteCheckCount: 'number',
  lossPreventionDeleteAmount: 'price',
  lossPreventionVoidCheckCount: 'number',
  lossPreventionVoidAmount: 'price',
  lossPreventionRefundCheckCount: 'number',
  lossPreventionRefundAmount: 'price',
  lossPreventionDeleteCheckCountPercent: 'percent',
  lossPreventionVoidCheckCountPercent: 'percent',
  lossPreventionOverringCheckCountPercent: 'percent',
  lossPreventionRefundCheckCountPercent: 'percent',
} as const

export const dhWbListEmployeeLossPreventionDataConfigs = {
  ...commonLossPreventionDataConfigs,
  employeeName: 'string',
} as const

const useCommonEmployeeDiscountData = buildLocationEmployeeMetricValuesHook({
  codes: ['check_count'],
  groups: [
    'Expo Module - Loss Prevention Comps',
    'Expo Module - Loss Prevention Check Count',
    'Expo Module - Loss Prevention Check Count Percent',
  ],
})

const useListEmployeeLossPreventionData = () => {
  const { data: commonData, loading } = useCommonEmployeeDiscountData()

  return {
    data: useMemo((): IApiDataType => {
      if (!commonData) return null

      const keys = Object.keys(commonData?.[0] || {}) || []

      const keyInfo = keys
        .filter((k) => Object.keys(commonLossPreventionDataConfigs).includes(k))
        .reduce(
          (r, k) => {
            const kpiData = commonData?.map((cd) => cd[k] as number) || []
            const sortedData = [...kpiData].sort((a, b) => a - b)

            return {
              ...r,
              [k]: {
                sortedData,
              },
            }
          },
          {} as {
            [k: string]: {
              sortedData: number[]
            }
          },
        )

      const newData =
        commonData?.map((cd) => {
          return {
            ...cd,
            ...Object.keys(cd).reduce((r, c) => {
              if (Object.keys(commonLossPreventionDataConfigs).includes(c)) {
                const sorted = keyInfo[c].sortedData
                const rank =
                  sorted.findIndex((sortedItem) => sortedItem === cd[c]) + 1
                return {
                  ...r,
                  [`${c}Heatmap`]: ((rank - 1) / (sorted.length - 1)) * 100,
                }
              } else {
                return r
              }
            }, {}),
          }
        }) || []

      return newData
    }, [commonData, loading]),
    loading: loading,
  }
}

export default useListEmployeeLossPreventionData
