import * as commonApi from './common'
import { IApiDataType, IApiOptionType } from './types'

export type IApiKeyType = keyof typeof api

const api = {
  ...commonApi,
}

const useApi = (
  apiName: IApiKeyType,
  option: IApiOptionType,
): {
  data: IApiDataType
  loading: boolean
} => api[apiName](option)

export default useApi
