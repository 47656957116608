import _ from 'lodash'
import { useMemo } from 'react'

import useMetricValuesQuery, {
  IGroupFilterDataType,
  IMetricType,
  format,
} from '../../../../hooks/useMetricValuesQuery'
import { useVariables } from '../../../../variables'
import { IApiDataType } from '../../../types'

const buildMetricValuesHook = ({
  handler = (data) => data,
  hasSummary,
  ...options
}: {
  groupFilterTypes: IGroupFilterDataType['type'][]
  metrics?: IMetricType[]
  metricGroups?: IMetricType[]
  handler?: (data: Record<string, unknown>) => Record<string, unknown>
  hasSummary?: boolean
}) => {
  const useMetricValues = () => {
    const { data, yoyData, loading } = useMetricValuesQuery({
      ...options,
      fields: hasSummary ? ['metricData', 'metricSummaryData'] : ['metricData'],
    })
    const { variables } = useVariables()

    return {
      data: useMemo((): IApiDataType => {
        if (!data) return null

        const groupFilterInfo = variables.groupFilter?.getInfo([
          'listLocation',
          'listLocationGroup',
        ])
        const summary = {}

        const newData = data.map((d) => {
          const id = (() => {
            if ('locationId' in d) return d.locationId
            if ('locationGroupId' in d) return d.locationGroupId
            return -1
          })()
          const yoyD = yoyData?.find((yd) =>
            'locationId' in yd
              ? yd.locationId === id
              : yd.locationGroupId === id,
          )
          const tableRow = (() => {
            if (groupFilterInfo) {
              if ('locations' in groupFilterInfo)
                return groupFilterInfo.locations[id]?.tableRow
              if ('locationGroups' in groupFilterInfo)
                return groupFilterInfo.locationGroups[id]?.tableRow
            }
            return {}
          })()

          if (d.metricSummaryData && hasSummary)
            Object.assign(
              summary,
              d.metricSummaryData,
              _.mapKeys(
                yoyD?.metricSummaryData || {},
                (_, key) => `yoy_${key}`,
              ),
            )

          return {
            ...tableRow,
            ...handler(
              format({
                ...d.metricData,
                ..._.mapKeys(yoyD?.metricData || {}, (_, key) => `yoy_${key}`),
              }),
            ),
            id: id.toString(),
            parentId: hasSummary ? 'summary' : 'root',
          }
        })

        return [
          ...(!hasSummary
            ? []
            : [
                {
                  ...handler(format(summary)),
                  ...(!groupFilterInfo?.tableColumns
                    ? {}
                    : {
                        [groupFilterInfo.tableColumns[0].key]: {
                          label: 'TOTAL',
                        },
                      }),
                  id: 'summary',
                  parentId: 'root',
                },
              ]),
          ...newData,
        ]
      }, [data, yoyData, variables]),
      loading,
    }
  }

  return useMetricValues
}

export default buildMetricValuesHook
