import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import calc from '../../../utils/calc'
import { IApiDataType } from '../../types'
import buildMetricValuesHook from '../utils/buildMetricValuesHook'

const query = gql`
  query averageWeeklySales(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationAverageWeeklySales(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        metricSummaryData
      }
    }
  }
`

type IMetricDataType = Record<
  string,
  {
    name: string
    unit: 'CENT' | 'PERCENTAGE' | 'DOLLAR' | 'COUNT'
    value: number
  }
>

type IDataType<T extends string = 'listLocationAverageWeeklySales'> = Record<
  T,
  {
    nodes: Record<'metricSummaryData', IMetricDataType>[]
  }
>

export const dhWbListStorePerformanceKpisConfigs = {
  netSales: 'price',
  checkCount: 'number',
  checkAverage: 'price',
  netSalesSssPercent: 'percent',
  averageWeeklySales: 'price',
  cogsPercentage: 'percent',
} as const

const useCommonStorePerformanceKpis = buildMetricValuesHook(
  {
    groupFilterType: 'store',
    metrics: {
      codes: [
        'net_sales',
        { key: 'net_sales', type: 'yoy' },
        'check_count',
        'check_average',
        'cogs_percentage',
      ],
    },
  },
  (data) => ({
    ...data,
    netSalesSssPercent:
      calc(
        calc(calc(data.netSales, '-', data.yoyNetSales), '/', data.yoyNetSales),
        '*',
        100,
      ) || null,
  }),
)

const useListStorePerformanceKpis = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()

  const { data: metricsData, loading: metricLoading } =
    useCommonStorePerformanceKpis()
  const { data: avgWeeklySalesData, loading: avgWeeklySalesLoading } =
    useQuery<IDataType>(query, {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iFilter: {
          location_ids: groupFilter?.ids,
          metrics: ['net_sales'],
        },
      },
      skip: !startDate || !endDate || !groupFilter,
    })

  return {
    data: useMemo((): IApiDataType => {
      if (!metricsData || !avgWeeklySalesData) return null

      const averageWeeklySalesNodes =
        avgWeeklySalesData.listLocationAverageWeeklySales?.nodes.find(
          (n) => n.metricSummaryData,
        )
      const avgWeeklySalesOutput = Object.entries(
        averageWeeklySalesNodes?.metricSummaryData || {},
      ).reduce((result, [key, value]) => {
        return {
          ...result,
          [_.camelCase(key)]: value.value * 100,
        }
      }, {})

      return {
        ...metricsData,
        ...avgWeeklySalesOutput,
      }
    }, [metricsData, avgWeeklySalesData]),
    loading: metricLoading || avgWeeklySalesLoading,
  }
}

export default useListStorePerformanceKpis
