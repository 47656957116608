import { useMemo } from 'react'

import { corporateGroupTableConfigs } from '../../../variables'
import buildMetricValuesHook from '../utils/buildMetricValuesHook'

const commonSalesmanshipDataConfigs = {
  checkAverage: 'price',
  itemAttachmentRateDesserts: 'percent',
  itemAttachmentRateBeverages: 'percent',
  itemAttachmentRateBacon: 'percent',
  itemAttachmentRateCheese: 'percent',
  itemAttachmentRateJalapeno: 'percent',
  itemAttachmentRateIcedCoffee: 'percent',
  itemAttachmentRateHotCoffee: 'percent',
  itemAttachmentRateShake: 'percent',
  itemAttachmentRateMalt: 'percent',
  itemSalesIcedCoffee: 'price',
  itemSalesHotCoffee: 'price',
  itemSalesShake: 'price',
  itemSalesMalt: 'price',
  itemSaleQuantityIcedCoffee: 'number',
  itemSaleQuantityHotCoffee: 'number',
  itemSaleQuantityShake: 'number',
  itemSaleQuantityMalt: 'number',
  upsizingCountSmall: 'number',
  upsizingCountMedium: 'number',
  upsizingCountLarge: 'number',
  upsizingCountTotal: 'number',
  salesPerLaborHour: 'price',
  upsizedCountPercentage: 'percent',
} as const

export const dhWbCorporateSalesmanshipConfigs = {
  ...corporateGroupTableConfigs,
  ...commonSalesmanshipDataConfigs,
} as const

const useCommonSalesmanshipData = buildMetricValuesHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: [
      'check_average',
      'sales_per_labor_hour',
      'upsized_count_percentage',
    ],
    groups: [
      'Expo Module - Item Sales',
      'Expo Module - Item Sale Quantity',
      'Expo Module - Item Attachment Rate',
      'Expo Module - Upsizing Count',
    ],
  },
})

const useDhWbSalesmanship = () => {
  const { data: commonData, loading } = useCommonSalesmanshipData()

  return {
    data: useMemo(() => {
      const keys = Object.keys(commonData?.[0] || {}) || []

      const keyInfo = keys
        .filter((k) => Object.keys(commonSalesmanshipDataConfigs).includes(k))
        .reduce(
          (r, k) => {
            const kpiData = commonData?.map((cd) => cd[k] as number) || []
            const sortedData = [...kpiData].sort((a, b) => a - b)

            return {
              ...r,
              [k]: {
                sortedData,
              },
            }
          },
          {} as {
            [k: string]: {
              sortedData: number[]
            }
          },
        )

      const newData =
        commonData?.map((cd) => {
          return {
            ...cd,
            ...Object.keys(cd).reduce((r, c) => {
              if (Object.keys(commonSalesmanshipDataConfigs).includes(c)) {
                const sorted = keyInfo[c].sortedData
                const rank =
                  sorted.findIndex((sortedItem) => sortedItem === cd[c]) + 1
                return {
                  ...r,
                  [`${c}Heatmap`]:
                    ((sorted.length - rank) / (sorted.length - 1)) * 100,
                }
              } else {
                return r
              }
            }, {}),
          }
        }) || []

      return newData
    }, [commonData, loading]),
    loading,
  }
}

export default useDhWbSalesmanship
