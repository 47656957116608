import useDhWbListLocationGroupGuestData, {
  dhWbListLocationGroupGuestDataConfigs,
} from './guest/useListLocationGroupGuestData'
import useDhWbListLocationGuestCommentData, {
  dhWbListLocationGuestCommentDataConfigs,
} from './guest/useListLocationGuestCommentData'
import useDhWbListEmployeeDiscountData, {
  dhWbListEmployeeDiscountDataConfigs,
} from './lossPrevention/useListEmployeeDiscountData'
import useDhWbListEmployeeLossPreventionData, {
  dhWbListEmployeeLossPreventionDataConfigs,
} from './lossPrevention/useListEmployeeLossPreventionData'
import useDhWbLocationOpsCompsBreakdownData, {
  dhWbLocationOpsCompsBreakdownDataConfigs,
} from './lossPrevention/useLocationOpsCompsBreakdownData'
import useDhWbLossPrevention, {
  dhWbLossPreventionConfigs,
} from './lossPrevention/useLossPrevention'
import useDhWbCorporateSalesmanship, {
  dhWbCorporateSalesmanshipConfigs,
} from './useCorporateSalesmanship'
import useDhWbFlash, { dhWbFlashConfigs } from './useFlash'
import useDhWbListEmployeeDeliveryData, {
  dhWbListEmployeeDeliveryDataConfigs,
} from './useListEmployeeDeliveryData'
import useDhWbListEmployeeSalesmanshipData, {
  dhWbListEmployeeSalesmanshipDataConfigs,
} from './useListEmployeeSalesmanshipData'
import useDhWbListLocationDeliveryDayTrendsData, {
  dhWbListLocationDeliveryDayTrendsDataConfigs,
} from './useListLocationDeliveryDayTrendsData'
import useDhWbListLocationGroupDeliveryData, {
  dhWbListLocationGroupDeliveryDataConfigs,
} from './useListLocationGroupDeliveryData'
import useDhWbListManagerOnDutyData, {
  dhWbListManagerOnDutyDataConfigs,
} from './useListManagerOnDutyData'
import useDhRankings, { dhRankingsConfigs } from './useRankings'
import useDhWbStorePerformance, {
  dhWbStorePerformanceConfigs,
} from './useStorePerformance'
import useDhWbSystemwideOverview, {
  dhWbSystemwideOverviewConfigs,
} from './useSystemwideOverview'

export const configs = {
  dhWbSystemwideOverview: dhWbSystemwideOverviewConfigs,
  dhWbStorePerformance: dhWbStorePerformanceConfigs,
  dhWbFlash: dhWbFlashConfigs,
  dhRankings: dhRankingsConfigs,
  dhWbCorporateSalesmanship: dhWbCorporateSalesmanshipConfigs,
  dhWbListEmployeeSalesmanshipData: dhWbListEmployeeSalesmanshipDataConfigs,
  dhWbListManagerOnDutyData: dhWbListManagerOnDutyDataConfigs,
  dhWbListEmployeeDeliveryData: dhWbListEmployeeDeliveryDataConfigs,
  dhWbListLocationDeliveryDayTrendsData:
    dhWbListLocationDeliveryDayTrendsDataConfigs,
  dhWbListLocationGroupDeliveryData: dhWbListLocationGroupDeliveryDataConfigs,
  dhWbLossPrevention: dhWbLossPreventionConfigs,
  dhWbListEmployeeLossPreventionData: dhWbListEmployeeLossPreventionDataConfigs,
  dhWbListEmployeeDiscountData: dhWbListEmployeeDiscountDataConfigs,
  dhWbLocationOpsCompsBreakdownData: dhWbLocationOpsCompsBreakdownDataConfigs,
  dhWbListLocationGroupGuestData: dhWbListLocationGroupGuestDataConfigs,
  dhWbListLocationGuestCommentData: dhWbListLocationGuestCommentDataConfigs,
}

export const api = {
  dhWbSystemwideOverview: useDhWbSystemwideOverview,
  dhWbStorePerformance: useDhWbStorePerformance,
  dhWbFlash: useDhWbFlash,
  dhRankings: useDhRankings,
  dhWbCorporateSalesmanship: useDhWbCorporateSalesmanship,
  dhWbListEmployeeSalesmanshipData: useDhWbListEmployeeSalesmanshipData,
  dhWbListManagerOnDutyData: useDhWbListManagerOnDutyData,
  dhWbListEmployeeDeliveryData: useDhWbListEmployeeDeliveryData,
  dhWbListLocationDeliveryDayTrendsData:
    useDhWbListLocationDeliveryDayTrendsData,
  dhWbListLocationGroupDeliveryData: useDhWbListLocationGroupDeliveryData,
  dhWbLossPrevention: useDhWbLossPrevention,
  dhWbListEmployeeDiscountData: useDhWbListEmployeeDiscountData,
  dhWbListEmployeeLossPreventionData: useDhWbListEmployeeLossPreventionData,
  dhWbLocationOpsCompsBreakdownData: useDhWbLocationOpsCompsBreakdownData,
  dhWbListLocationGroupGuestData: useDhWbListLocationGroupGuestData,
  dhWbListLocationGuestCommentData: useDhWbListLocationGuestCommentData,
}
