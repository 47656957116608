import CheckIcon from '@mui/icons-material/Check'
import * as d3Interpolate from 'd3-interpolate'
import * as d3 from 'd3-scale'
import _ from 'lodash'
import { useMemo } from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import {
  toPercentString,
  toPercentageString,
  toUsdString,
} from 'pared/utils/number'

import { useVariables } from '../variables'
import Link, { IPropsType as ILinkPropsType } from './Link'
import Stars, { IPropsType as IStarsPropsType } from './Stars'
import ChangeLocationButton from './components/administration/ChangeLocationButton'
import ChangeLocationGroupButton from './components/administration/ChangeLocationGroupButton'
import EditLocationButton from './components/administration/EditLocationButton'

export interface IBasePropsType {
  value: unknown
  onClick?: string
  row?: any
  refetch?: any
}

type IColorType =
  | string
  | {
      range: number[]
      colors: string[]
      value?: `<%- ${string} %>`
    }

export type IPropsType =
  | (IBasePropsType & {
      type: 'price'
      decimal?: number
      color?: IColorType
      unit?: 'dollar' | 'cent'
    })
  | (IBasePropsType & {
      type: 'percent'
      decimal?: number
      color?: IColorType
      isDecimal?: boolean
    })
  | (IBasePropsType & {
      type: 'number'
      decimal?: number
      color?: IColorType
      format?: `<%- ${string} %>`
    })
  | (IBasePropsType & {
      type: 'string'
      useDangerouslySetInnerHTML?: boolean
    })
  | (IBasePropsType & {
      type: 'link'
    })
  | (IBasePropsType & {
      type: 'boolean'
    })
  | (IBasePropsType & {
      type: 'stars'
    })
  // FIXME
  | (IBasePropsType & {
      type: 'change-location-button'
    })
  // FIXME
  | (IBasePropsType & {
      type: 'change-location-group-button'
    })
  // FIXME
  | (IBasePropsType & {
      type: 'edit-location-button'
    })

const Text = styled.span<{
  color?: string
}>`
  ${({ color }) => (!color ? '' : `color: ${color};`)}
`

const getColor = (
  color: IColorType,
  template: ReturnType<typeof useVariables>['template'],
  external: Record<string, unknown>,
) => {
  if (!color) return

  if (typeof color === 'string') return template(color, { external })

  const value = parseFloat(
    template(color.value || '<%- value %>', { external }),
  )

  if (isNaN(value)) return

  return d3
    .scaleLinear(color.range, color.colors)
    .interpolate(d3Interpolate.interpolateHsl)(value)
}

const Format = ({ value, onClick, refetch, ...props }: IPropsType) => {
  const { variables, template } = useVariables()
  const defaultProps = useMemo(
    () => ({
      onClick: () => {
        const action = onClick && _.get(variables, onClick)

        if (action) action(value)
      },
    }),
    [value, onClick, variables],
  )

  if (value === '-' || value === undefined || value === null) return <>-</>

  switch (props.type) {
    case 'price': {
      const numberValue =
        typeof value === 'string' ? parseFloat(value) : (value as number)

      return (
        <Text
          {...defaultProps}
          color={getColor(
            props.color || '<%- value < 0 ? colors.Pomodoro : "" %>',
            template,
            { value: numberValue, colors: COLORS },
          )}
        >
          {toUsdString(
            numberValue / (props.unit === 'dollar' ? 1 : 100),
            props.decimal,
          )}
        </Text>
      )
    }

    case 'percent':
      return (
        <Text
          {...defaultProps}
          color={getColor(
            props.color || '<%- value < 0 ? colors.Pomodoro : "" %>',
            template,
            { value, colors: COLORS },
          )}
        >
          {props.isDecimal
            ? toPercentageString(value as string, props.decimal)
            : toPercentString(value as string, props.decimal)}
        </Text>
      )

    case 'number': {
      const format =
        props.format ||
        '<%- value < 0 ? "(" + Math.abs(valueStr) + ")" : valueStr %>'

      if (typeof value === 'string') {
        const numberValue = parseFloat(value)
        const valueStr = numberValue.toLocaleString('en-US', {
          minimumFractionDigits: props.decimal || 0,
          maximumFractionDigits: props.decimal || 0,
        })

        return (
          <Text
            {...defaultProps}
            color={getColor(
              props.color || '<%- value < 0 ? colors.Pomodoro : "" %>',
              template,
              { value, colors: COLORS },
            )}
          >
            {template(format, {
              external: {
                value: numberValue,
                valueStr,
              },
            })}
          </Text>
        )
      }

      const valueStr = (value as number).toLocaleString('en-US', {
        minimumFractionDigits: props.decimal || 0,
        maximumFractionDigits: props.decimal || 0,
      })

      return (
        <Text
          {...defaultProps}
          color={getColor(
            props.color || '<%- value < 0 ? colors.Pomodoro : "" %>',
            template,
            { value, colors: COLORS },
          )}
        >
          {template(format, {
            external: {
              value,
              valueStr,
            },
          })}
        </Text>
      )
    }

    case 'string':
      if (props.useDangerouslySetInnerHTML)
        return (
          <Text
            {...defaultProps}
            dangerouslySetInnerHTML={{ __html: value as string }}
          />
        )
      return <Text {...defaultProps}>{value}</Text>

    case 'link':
      if (!value) return null

      return <Link {...defaultProps} {...(value as ILinkPropsType)} />

    case 'boolean':
      if (value !== true) return null
      return <CheckIcon color="primary" />

    case 'stars':
      if (!value || typeof value !== 'number') return null
      return <Stars {...defaultProps} numberOfStars={value} />

    // FIXME
    case 'change-location-button':
      return <ChangeLocationButton row={props.row} refetch={refetch} />

    // FIXME
    case 'change-location-group-button':
      return <ChangeLocationGroupButton row={props.row} refetch={refetch} />

    // FIXME
    case 'edit-location-button':
      return <EditLocationButton row={props.row} refetch={refetch} />

    default:
      return null
  }
}

export default Format
