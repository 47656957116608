import { useMemo } from 'react'

import { corporateGroupTableConfigs } from '../../../variables'
import { IApiType, INestedDataType } from '../../types'
import buildItemMetricValuesHook from '../utils/buildItemMetricValuesHook'

const commonInventoryConfigs = {
  itemName: 'string',
  displayParentCategoryName: 'string',
  locationId: 'string',
  actualCogs: 'price',
  theoreticalCogs: 'price',
  totalNetSales: 'price',
  actualCogsPercent: 'percent',
  theoreticalCogsPercent: 'percent',
  cogsVariance: 'price',
  cogsVariancePercent: 'percent',
} as const

export const inventoryConfigs = {
  ...corporateGroupTableConfigs,
  ...commonInventoryConfigs,
}

type ItemData = INestedDataType & {
  itemName: string
  displayParentCategoryName: string
  locationId: string
  actualCogs: number
  theoreticalCogs: number
  totalNetSales: number
  actualCogsPercent: number
  theoreticalCogsPercent: number
}

const useInventory = ({
  startDate,
  endDate,
  locationGroupIds,
}: {
  startDate?: string
  endDate?: string
  locationGroupIds?: number[]
} = {}): IApiType => {
  const metricCodes = [
    'actual_cogs',
    'theoretical_cogs',
    'total_net_sales',
    'actual_cogs_percent',
    'theoretical_cogs_percent',
    'cogs_variance',
    'cogs_variance_percent',
  ]

  const useItemMetrics = buildItemMetricValuesHook({
    metrics: metricCodes,
    startDate,
    endDate,
    locationGroupIds,
    hasSummary: true,
    includeLocations: true,
  })

  const { data: itemData, loading: itemLoading } = useItemMetrics()

  const loading = itemLoading

  return {
    data: useMemo(() => {
      if (!itemData) return null
      const final = (itemData as ItemData[]).map((n) => {
        return {
          ...n,
        }
      })

      return final
    }, [itemData]),
    loading,
  }
}

export default useInventory
