import _ from 'lodash'
import moment from 'moment'
import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useAsync } from 'react-use'

import getDateRanges, { IDateRange } from 'pared/data/getDateRanges'
import { getBrandDefaultCalendarStartDate } from 'pared/utils/brand'

import { IDateOptionNodeType, IDateOptionType, IDateType } from './types'
import useDateOptions from './useDateOptions'
import useGetCalendar from './useGetCalendar'
import useInfos from './useInfos'
import useSetDate from './useSetDate'

export interface IOptionType {
  types?: IDateType[]
  defaultType?: IDateType
  startDate?: string | 'current_business_year_start'
  endDate?: string | 'finished_week'
}

export interface IDateDataType {
  value: IDateOptionNodeType
  options: IDateOptionType[]
  setDate: (date: IDateOptionNodeType) => void
  startDate?: moment.Moment
  endDate?: moment.Moment
  getInfo: (diff: number) =>
    | undefined
    | {
        displayName: string
        dateRange: IDateOptionNodeType['dateRange']
        yoy?: {
          displayName?: string
          dateRange?: IDateOptionNodeType['dateRange']
        }
      }
  getCalendar: ReturnType<typeof useGetCalendar>
}

export interface IDataType {
  date?: IDateDataType
}

const useDate = ({
  types = ['week', 'period', 'quarter', 'year', 'yesterday', 'trailing_7_days'],
  defaultType = types.includes('period') ? 'period' : types[0],
  startDate: customStartDate,
  endDate: customEndDate,
}: IOptionType) => {
  /* FIXME: we need to clearify how to get the date data */
  const brandDefaultCalendarStartDate = getBrandDefaultCalendarStartDate()
  const { search } = useLocation()
  const startDateStr = useMemo(() => {
    if (customStartDate && customStartDate !== 'current_business_year_start') {
      if (/-/.test(customStartDate))
        return moment.utc(customStartDate, 'YYYY-MM-DD').format('MM/DD/YYYY')

      return customStartDate
    }

    return brandDefaultCalendarStartDate || '01/01/2019'
  }, [customStartDate, brandDefaultCalendarStartDate])
  const endDateStr = useMemo(() => {
    if (!customEndDate || customEndDate === 'finished_week') return

    if (/-/.test(customEndDate))
      return moment.utc(customEndDate, 'YYYY-MM-DD').format('MM/DD/YYYY')

    return customEndDate
  }, [customEndDate])
  const state = useAsync(
    () => getDateRanges(startDateStr, endDateStr),
    [search, startDateStr, endDateStr],
  )
  const { dateRangeMap, startDate, endDate } = useMemo(() => {
    const dateRangeMap = state.value?.dateRangeMap

    if (!dateRangeMap) return { dateRangeMap: null }

    const startDate =
      customStartDate !== 'current_business_year_start'
        ? moment.utc(startDateStr, 'MM/DD/YYYY')
        : Object.values(dateRangeMap).find(
            (d) => d.type === 'year' && d.year === moment().year(),
          )?.startDate
    const endDate = (() => {
      if (customEndDate === 'finished_week')
        return Object.values(dateRangeMap).find(
          (d) => d.type === 'week' && d.endDate.isSameOrBefore(moment.utc()),
        )?.endDate

      if (!endDateStr) return

      return moment.utc(endDateStr, 'MM/DD/YYYY')
    })()

    return {
      dateRangeMap: Object.keys(dateRangeMap).reduce((result, key) => {
        const dateRange = dateRangeMap[key] as IDateRange

        if (
          customStartDate === 'current_business_year_start' &&
          startDate &&
          dateRange.startDate.isBefore(startDate)
        )
          return result

        if (customEndDate === 'finished_week' && endDate) {
          if (dateRange.type === 'year' && types.includes('week')) {
            if (dateRange.startDate.isAfter(endDate)) return result
          } else if (dateRange.endDate.isAfter(endDate)) return result
        }

        return {
          ...result,
          [key]: dateRange,
        }
      }, {}),
      startDate,
      endDate,
    }
  }, [types, customStartDate, startDateStr, customEndDate, endDateStr, state])
  /* FIXME end */

  const { currentDate, setDate } = useSetDate(types, dateRangeMap)
  const dateOptions = useDateOptions(types, currentDate, dateRangeMap)
  const infos = useInfos(types, currentDate, dateOptions)
  const getCalendar = useGetCalendar(dateRangeMap)

  useEffect(() => {
    if (!dateOptions || infos.date) return

    const option = dateOptions.find(
      (o) => 'type' in o && o.type === defaultType,
    )

    if (option) setDate(option as IDateOptionNodeType)
  }, [defaultType, dateOptions, infos, setDate])

  return {
    date: useMemo((): IDataType['date'] => {
      if (!dateOptions || !infos.date) return

      return {
        value: infos.date,
        options: dateOptions,
        setDate,
        startDate: moment.utc(startDate, 'MM/DD/YYYY'),
        endDate: endDate ? moment.utc(endDate, 'MM/DD/YYYY') : undefined,
        getInfo: (diff: number) => {
          const info = infos.dateByDiff[diff]

          if (!info) {
            if (
              infos.date?.type === 'custom_date' ||
              infos.date?.type === 'yesterday'
            ) {
              const date = infos.date.dateRange.startDate
                .clone()
                .add(diff, 'days')

              return {
                displayName: date.format('M/D/YY'),
                dateRange: {
                  startDate: date,
                  startDateStr: date.format('YYYY-MM-DD'),
                  endDate: date,
                  endDateStr: date.format('YYYY-MM-DD'),
                },
              }
            }

            return
          }

          const yoy = Object.values(infos.dateByDiff).find(
            (i) => info.year - 1 === i.year && info.id === i.id,
          )

          return {
            displayName: info.id,
            dateRange: info.dateRange,
            yoy: yoy && {
              displayName: yoy.id,
              dateRange: yoy.dateRange,
            },
          }
        },
        getCalendar,
      }
    }, [startDate, endDate, dateOptions, infos, setDate, getCalendar]),
  }
}

export default useDate
