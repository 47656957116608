import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { DATE_FORMAT } from 'pared/data/getDateRanges'
import useBrands from 'pared/layouts/hooks/useBrands'

import { useGroupFilter } from '../../groupFilter'
import { useVariables } from '../../variables'
import { IApiDataType } from '../types'
import { IGroupInfoType } from './useGroupInfoColumns'

interface IDirectorInfo {
  id: number
  given_name: string
  family_name: string
  preferred_name: string
}

interface ILocationInfo {
  id: number
  code: string
  name: string
  director: IDirectorInfo
}

interface ISmgMetrics {
  smg_dissatisfaction_percentage: number
  smg_wingyou_score: number
  smg_digital_osat_five_star_percentage: number
  smg_delivery_osat_five_star_percentage: number
  smg_non_digital_osat_five_star_percentage: number
}

interface ILocationSmgMetrics extends ILocationInfo, ISmgMetrics {}

interface ICorporateSmgSummaryDataType {
  locations: ILocationSmgMetrics[]
  systemwideAverage: ISmgMetrics
}

interface ISmgSummaryType {
  corporateUSmgSummary: {
    nodes: ICorporateSmgSummaryDataType[]
  }
}

const smgMetricDetails: {
  code: keyof ISmgMetrics
  name: string
  isHigherBetter: boolean
}[] = [
  {
    code: 'smg_dissatisfaction_percentage',
    name: 'Dissatisfaction',
    isHigherBetter: false,
  },
  { code: 'smg_wingyou_score', name: 'Wing You', isHigherBetter: true },
  {
    code: 'smg_digital_osat_five_star_percentage',
    name: 'Digital OSAT',
    isHigherBetter: true,
  },
  {
    code: 'smg_delivery_osat_five_star_percentage',
    name: 'Delivery OSAT',
    isHigherBetter: true,
  },
  {
    code: 'smg_non_digital_osat_five_star_percentage',
    name: 'Non-Digital OSAT',
    isHigherBetter: true,
  },
]

const query = gql`
  query GetCorporateSmgSummary(
    $iLocationGroupId: Int!
    $iBrandLocationGroupId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    corporateUSmgSummary(
      iLocationGroupId: $iLocationGroupId
      iBrandLocationGroupId: $iBrandLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        locations
        systemwideAverage
      }
    }
  }
`

export const corporateExpoAiSmgSummaryConfigs = {
  metricCode: 'string',
  metricName: 'string',
  metricValueInPercent: 'percent',
  storeCount: 'string',
} as const

const createCorporateExpoAiSmgSummaryHook = (isLocationDetails: boolean) => {
  const useCorporateExpoAiSmgSummaryData = () => {
    const { variables } = useVariables()
    const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
    const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
    const { groupFilter } = useGroupFilter()
    const locationGroupId = groupFilter?.ids[0] || 0
    const { brand } = useBrands()
    const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
    const { data, loading } = useQuery<ISmgSummaryType>(query, {
      variables: {
        iLocationGroupId: locationGroupId,
        iBrandLocationGroupId: brandLocationGroupId,
        iStartDate: startDate,
        iEndDate: endDate,
      },
      skip: !startDate || !endDate || !groupFilter,
    })

    return {
      data: useMemo((): IApiDataType => {
        if (isLocationDetails) {
          const summary = data?.corporateUSmgSummary?.nodes?.[0]
          const locations = summary?.locations
          const systemwideAverage = summary?.systemwideAverage
          if (!locations || !systemwideAverage) return null

          const metricCode = variables?.aiSummary['uSmg']
            ?.clickedId as keyof ISmgMetrics
          const metric = smgMetricDetails.find((metric) => {
            return metric.code === metricCode
          })
          if (!metric) return null

          const locationsWithProblem = locations.filter((location) => {
            const locationMetricValue = location?.[metricCode] ?? null
            const systemwideAvgMetricValue =
              systemwideAverage?.[metricCode] ?? null
            if (
              locationMetricValue !== null &&
              systemwideAvgMetricValue !== null
            ) {
              if (metric.isHigherBetter) {
                if (locationMetricValue < systemwideAvgMetricValue) {
                  return true
                }
              } else {
                if (locationMetricValue > systemwideAvgMetricValue) {
                  return true
                }
              }
            }
            return false
          })

          return {
            source:
              locationsWithProblem.map((location) => {
                const locationDetail = groupFilter?.list?.find(
                  (g) => g.id == location.id,
                )

                const groupInfo = {
                  ...locationDetail,
                  link: locationDetail?.link?.replace('store_detail', 'guest'),
                } as IGroupInfoType

                return {
                  groupInfo,
                  metricValueInPercent: (location[metricCode] || 0) * 100.0,
                }
              }) || [],
          }
        } else {
          const summary = data?.corporateUSmgSummary?.nodes?.[0]
          const locations = summary?.locations
          const systemwideAverage = summary?.systemwideAverage
          if (!locations || !systemwideAverage) return null

          const worseThanSystemwideAverageCounts: {
            [metricCode: string]: number
          } = {}
          locations.forEach((location) => {
            smgMetricDetails.forEach((metric) => {
              const metricCode = metric.code
              const locationMetricValue = location?.[metricCode] ?? null
              const systemwideAvgMetricValue =
                systemwideAverage?.[metricCode] ?? null
              if (
                locationMetricValue !== null &&
                systemwideAvgMetricValue !== null
              ) {
                if (metric.isHigherBetter) {
                  if (locationMetricValue < systemwideAvgMetricValue) {
                    const existingCount =
                      worseThanSystemwideAverageCounts[metricCode] || 0
                    worseThanSystemwideAverageCounts[metricCode] =
                      existingCount + 1
                  }
                } else {
                  if (locationMetricValue > systemwideAvgMetricValue) {
                    const existingCount =
                      worseThanSystemwideAverageCounts[metricCode] || 0
                    worseThanSystemwideAverageCounts[metricCode] =
                      existingCount + 1
                  }
                }
              }
            })
          })

          const source: {
            metricCode: string
            metricName: string
            storeCount: string
          }[] = []
          smgMetricDetails.forEach((metric) => {
            const locationCount = worseThanSystemwideAverageCounts[metric.code]
            if (locationCount) {
              source.push({
                metricCode: metric.code,
                metricName: metric.name,
                storeCount: `${locationCount} stores`,
              })
            }
          })

          return {
            source,
          }
        }
      }, [data, variables, groupFilter]),
      loading,
    }
  }

  return useCorporateExpoAiSmgSummaryData
}

export const useCorporateExpoAiSmgSummary =
  createCorporateExpoAiSmgSummaryHook(false)
export const useCorporateExpoAiSmgLocationDetails =
  createCorporateExpoAiSmgSummaryHook(true)
