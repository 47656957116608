import { IConfigsType } from '../../types'

const bibibopConfig: IConfigsType = {
  'bibibop-ops-dashboard-61747303': {
    '/:brand/ops-dashboard': {
      variables: [
        'allStores',
        'bbbConstants',
        {
          type: 'date',
          types: ['week'],
          startDate: 'current_business_year_start',
          endDate: 'finished_week',
        },
      ],
      dateFilter: {},
      download: {
        title: 'Ops Dashboard',
        type: 'download-csv',
        api: 'bbbOpsDashboard',
        message: 'Download as CSV',
        fileName: 'OpsDashboard',
        fields: [
          {
            key: 'opsDashboardName',
            title: '<%- date?.value.displayName %>',
          },
          {
            key: 'opsDashboardValue',
            title: ' ',
          },
        ],
      },
      opsDashbaord: {
        type: 'table-v2',
        api: 'bbbOpsDashboard',
        columns: [
          {
            key: 'opsDashboardName',
            title: '<%- date?.value.displayName %>',
          },
          {
            key: 'opsDashboardValue',
            title: ' ',
            align: 'center',
          },
        ],
      },
    },
  },
}

export default bibibopConfig
