import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import {
  IGroupFilterType,
  IGroupFilterVariablesNameType,
  useVariables,
} from '../../../variables'
import { IApiDataType } from '../../types'

type IMetricDataType = Record<
  string,
  {
    name?: string
    unit: 'CENT' | 'PERCENTAGE' | 'DOLLAR' | 'COUNT'
    value: number
    locations?: {
      [locationId: string]: number
    }
  }
>

type ListItemMetricValue = {
  itemName: string
  displayParentCategoryName: string
  metricData?: IMetricDataType
  metricSummaryData?: IMetricDataType
}

type IDataType = {
  listItemMetricValues: {
    nodes: ListItemMetricValue[]
  }
}

const itemQuery = gql`
  query listItemMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iQueryType: String!
    $iFilter: JSON!
  ) {
    listItemMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iQueryType: $iQueryType
      iFilter: $iFilter
    ) {
      nodes {
        itemName
        displayParentCategoryName
        metricData
        metricSummaryData
      }
    }
  }
`

const buildItemMetricValuesHook = (
  options: {
    metrics: string[]
    itemCodes?: string[]
    startDate?: string
    endDate?: string
    locationGroupIds?: number[]
    hasSummary?: boolean
    variablesName?: IGroupFilterVariablesNameType
    includeLocations?: boolean
  },
  handler: (data: Record<string, unknown>) => Record<string, unknown> = (
    data,
  ) => data,
) => {
  // FIXME: we should use variables name in the future, not usre group filter
  const variablesName = options.variablesName || 'corporateGroup'
  const hasSummary = options.hasSummary || false
  const includeLocations = options.includeLocations || false
  const useItemMetricValues = () => {
    const { variables } = useVariables()
    const variablesData = useMemo(
      () =>
        variables[variablesName] as IGroupFilterType<
          'listLocationGroup' | 'listLocation' | 'location'
        >,
      [variables],
    )

    const selectedItemOrCategory = useMemo(
      () => variables.items?.value,
      [variables],
    )

    const locations =
      variablesData && 'locations' in variablesData
        ? variablesData.locations
        : undefined
    const locationGroups =
      variablesData && 'locationGroups' in variablesData
        ? variablesData.locationGroups
        : undefined

    const dateFilter = useDateFilter()
    const { groupFilter } = useGroupFilter()

    const startDate = options.startDate || dateFilter.startDate
    const endDate = options.endDate || dateFilter.endDate
    const locationGroupIds = options.locationGroupIds || groupFilter?.ids || []

    const itemCategoryIds = parseInt(selectedItemOrCategory?.[0]?.[0]) || null
    const iFilter = {
      ...(!includeLocations ? {} : { use_location_details: true }),
      item_codes: itemCategoryIds ? [] : options.itemCodes || ['ALL ITEMS'],
      item_category_ids: itemCategoryIds ? [itemCategoryIds] : [],
      metrics: options.metrics,
      location_group_ids: locationGroupIds,
    }

    const { data, loading } = useQuery<IDataType>(itemQuery, {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iQueryType: 'ITEM',
        iFilter,
      },
      skip:
        !startDate || !endDate || !groupFilter || options.metrics.length === 0,
    })

    const processedData = useMemo<IApiDataType>(() => {
      if (!data) return null

      const listItemMetricValues = data.listItemMetricValues.nodes

      const itemLocations: {
        [itemName: string]: { [locationId: string]: Record<string, number> }
      } = {}
      const summary = { itemName: 'TOTAL' }
      const summaryLocations: { [locationId: string]: Record<string, number> } =
        {}
      const newData = listItemMetricValues.map((n) => {
        if (n.metricSummaryData && hasSummary) {
          const metricSummaryData = Object.entries(
            n.metricSummaryData || {},
          ).reduce((acc, [key, value]) => {
            const camelCaseKey = _.camelCase(key)
            acc[camelCaseKey] = value?.value || 0
            const locations = value?.locations
            for (const locationId in locations) {
              if (!(locationId in summaryLocations))
                summaryLocations[locationId] = {}
              summaryLocations[locationId][camelCaseKey] = locations[locationId]
            }
            return acc
          }, {} as Record<string, number>)

          Object.assign(summary, metricSummaryData)
        }

        const metricData = Object.entries(n.metricData || {}).reduce(
          (acc, [key, value]) => {
            const camelCaseKey = _.camelCase(key)
            acc[camelCaseKey] = value?.value || 0

            const locations = value?.locations
            for (const locationId in locations) {
              if (!(n.itemName in itemLocations)) itemLocations[n.itemName] = {}
              if (!(locationId in itemLocations[n.itemName]))
                itemLocations[n.itemName][locationId] = {}
              itemLocations[n.itemName][locationId][camelCaseKey] =
                locations[locationId]
            }

            return acc
          },
          {} as Record<string, number>,
        )

        const formattedData = handler({
          itemName: n.itemName,
          displayParentCategoryName: n.displayParentCategoryName,
          ...metricData,
        })

        return {
          ...formattedData,
          id: n.itemName,
          parentId: 'root',
        }
      })

      const itemLocationRows = _.flatten(
        Object.keys(itemLocations).map((itemName) => {
          return Object.keys(itemLocations[itemName]).map((locationId) => {
            const tableRow =
              (locations || locationGroups)?.[parseInt(locationId)]?.tableRow ??
              {}
            return {
              ...tableRow,
              ...handler(itemLocations[itemName][locationId]),
              locationId,
              id: `${itemName}-${locationId}`,
              parentId: itemName,
            }
          })
        }),
      )

      const metricSummaryLocations = Object.keys(summaryLocations).map(
        (locationId) => {
          const tableRow = (locations || locationGroups)?.[parseInt(locationId)]
            .tableRow
          return {
            ...tableRow,
            ...handler(summaryLocations[locationId]),
            locationId,
            id: `summary-${locationId}`,
            parentId: 'summary',
          }
        },
      )

      return [
        ...(!hasSummary
          ? []
          : [
              {
                ...handler(summary),
                id: 'summary',
                parentId: 'root',
              },
              ...(includeLocations ? metricSummaryLocations : []),
            ]),
        ...newData,
        ...(includeLocations ? itemLocationRows : []),
      ]
    }, [data, handler])

    return {
      data: processedData,
      loading,
    }
  }

  return useItemMetricValues
}

export default buildItemMetricValuesHook
