import { useMemo, useState } from 'react'

export interface IDataType {
  farwestFinancialSssCharts?: {
    options: {
      id: string
      parentId: string
      displayName: string
    }[]
    value: string[][]
  }
}

const useFinancialSssCharts = (): IDataType => {
  const farwestFinancialSssChartsHook = useState([['sss', 'net_sales']])

  return {
    farwestFinancialSssCharts: useMemo(() => {
      const options = [
        {
          id: 'sss',
          parentId: 'root',
          displayName: 'SSS%',
        },
        {
          id: 'sst',
          parentId: 'root',
          displayName: 'SST%',
        },
        {
          id: 'ssc',
          parentId: 'root',
          displayName: 'SSC%',
        },
        {
          id: 'net_sales',
          parentId: 'sss',
          displayName: 'Total',
        },
        {
          id: 'olo_take_out',
          parentId: 'sss',
          displayName: 'Online (Pick Up)',
        },
        {
          id: 'online_dispatch_sales',
          parentId: 'sss',
          displayName: 'Online (Dispatch)',
        },
        {
          id: 'walk_in_dine_in',
          parentId: 'sss',
          displayName: 'Dine In - Walk In',
        },
        {
          id: 'walk_in_to_go',
          parentId: 'sss',
          displayName: 'Take Out - Walk In',
        },
        {
          id: 'call_in_to_go',
          parentId: 'sss',
          displayName: 'Take Out - Call in/Call Center',
        },
        {
          id: 'check_count',
          parentId: 'sst',
          displayName: 'Total',
        },
        {
          id: 'check_average',
          parentId: 'ssc',
          displayName: 'Total',
        },
      ]

      return {
        value: farwestFinancialSssChartsHook[0],
        options: options,
        onChange: farwestFinancialSssChartsHook[1],
      }
    }, [farwestFinancialSssChartsHook]),
  }
}

export default useFinancialSssCharts
