import { useMemo } from 'react'

import { IApiDataType } from '../../types'
import buildLocationEmployeeMetricValuesHook from '../utils/buildLocationEmployeeMetricValuesHook'

const managerOnDutyDataConfigs = {
  onDutyTotalSales: 'price',
  onDutyTotalLaborPercent: 'percent',
} as const

export const dhWbListManagerOnDutyDataConfigs = {
  ...managerOnDutyDataConfigs,
  employeeName: 'string',
  employeeRole: 'string',
} as const

const useManagerOnDutyData = buildLocationEmployeeMetricValuesHook(
  {
    codes: ['on_duty_total_sales', 'on_duty_total_labor_percent'],
  },
  undefined,
  undefined,
  true,
)

const useListManagerOnDutyDataData = () => {
  const { data: commonData, loading } = useManagerOnDutyData()

  return {
    data: useMemo((): IApiDataType => {
      if (!commonData) return null

      const cleanedCommonData =
        commonData.filter((cd) => {
          if (cd?.onDutyTotalSales) {
            return true
          }
          return false
        }) || []

      const keys = cleanedCommonData.reduce((sum, cd) => {
        const rowKeys = Object.keys(cd || {}) || []
        const newKeys = rowKeys?.filter((rk) => !sum.includes(rk))
        return [...sum, ...newKeys]
      }, [] as string[])

      const keyInfo = keys
        .filter((k) => Object.keys(managerOnDutyDataConfigs).includes(k))
        .reduce(
          (r, k) => {
            const kpiData =
              cleanedCommonData?.map((cd) => cd[k] as number) || []
            const sortedData = [...kpiData]?.sort((a, b) => a - b) || []

            return {
              ...r,
              [k]: {
                sortedData,
              },
            }
          },
          {} as {
            [k: string]: {
              sortedData: number[]
            }
          },
        )

      const newData =
        cleanedCommonData?.map((cd: any) => {
          return {
            ...cd,
            ...Object.keys(cd).reduce((r, c) => {
              if (Object.keys(managerOnDutyDataConfigs).includes(c)) {
                const sorted = keyInfo[c].sortedData
                const rank =
                  sorted.findIndex((sortedItem) => sortedItem === cd[c]) + 1
                return {
                  ...r,
                  [`${c}Heatmap`]: ((rank - 1) / (sorted.length - 1)) * 100,
                }
              } else {
                return r
              }
            }, {}),
          }
        }) || []

      return newData
    }, [commonData, loading]),
    loading: loading,
  }
}

export default useListManagerOnDutyDataData
