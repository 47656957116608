import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { BRAND_ID, BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { getBusinessLabel } from 'pared/customer'
import useBrands from 'pared/layouts/hooks/useBrands'

import { IApiDataType, IApiOptionType } from '../types'

interface IQueryDataType {
  listDirectors: {
    nodes: {
      employeeId: number
      locationGroupId: number
      firstName: string
      lastName: string
    }[]
  }
  listLocationDetails: {
    nodes: {
      id: number
      code: string
      name: string
      locationGroups: {
        id: number
        name: string
        type: string
        start_ate: string
        end_date: string
      }[]
    }[]
  }
  getLocationAccessGroupsForBrand: {
    nodes: {
      id: number
      name: string
      type: string
    }[]
  }
}

interface IQueryVariablesType {
  iFilter: {
    location_group_ids: number[]
    brand_ids: [number]
  }
  iStartDate?: string
  iEndDate?: string
  iBrandId: number
}

const query = gql`
  query corporateGroupFilter(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $iBrandId: Int!
  ) {
    listDirectors(iFilter: $iFilter) {
      nodes {
        employeeId
        locationGroupId
        firstName
        lastName
      }
    }

    listLocationDetails: listLocationDetailsV2(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        id
        code
        name
        locationGroups
      }
    }

    getLocationAccessGroupsForBrand: getLocationAccessGroupsForBrandV2(
      iBrandId: $iBrandId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        id
        name
        type
      }
    }
  }
`

const LOCAITON_GROUP_TYPES: Record<string, string[]> = {
  bibibop: [
    'Comp or Non-Comp',
    'District Manager',
    'Region',
    'Market',
    'Multi-Unit Manager',
    'State',
    'City',
  ],
}
const DISABLE_GROUP_BY_LINK = [
  'ghai_pop',
  'bibibop',
  'fw_wingstop',
  'burgerworks',
]
const HAS_COMPANY = ['mwb']
const WIDTHS = {
  bibibop: ['250px', '150px', '250px'],
} as Record<string, [string, string, string]>

export const useCorporateGroupFilterQuery = (
  variables: IQueryVariablesType,
  skip: boolean,
) =>
  useQuery<IQueryDataType, IQueryVariablesType>(query, {
    variables,
    skip,
  })

const useCorporateGroupFilter = ({ date }: IApiOptionType) => {
  const { brand } = useBrands()
  const directorLabel = getBusinessLabel('director')
  const {
    data: newData,
    loading,
    previousData,
  } = useCorporateGroupFilterQuery(
    {
      iFilter: {
        location_group_ids: [BRAND_LOCATION_GROUP_ID[brand]],
        brand_ids: [BRAND_ID[brand]],
      },
      iStartDate: date?.value.dateRange.startDateStr,
      iEndDate: date?.value.dateRange.endDateStr,
      iBrandId: BRAND_ID[brand],
    },
    !date,
  )
  const data = newData || previousData
  const { search } = useLocation()

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return null

      const locationAccessGroupsForBrand = (
        data?.getLocationAccessGroupsForBrand?.nodes || []
      ).filter(
        (d) =>
          d.type !== 'Brand' &&
          d.type !== 'Custom' &&
          d.type !== 'null' &&
          d.type !== null,
      )
      // FIXME: should use locationAccessGroupsForBrand for all brands
      const locationGroups =
        locationAccessGroupsForBrand.length !== 0
          ? locationAccessGroupsForBrand.filter(
              (l) => LOCAITON_GROUP_TYPES[brand]?.includes(l.type) ?? true,
            )
          : data.listDirectors.nodes.map((director) => ({
              id: director.locationGroupId,
              name: `${director.firstName} ${director.lastName}`,
              type: directorLabel,
            }))
      const locationGroupTypes = locationGroups.reduce(
        (result, { type }) =>
          result.includes(type) ? result : [...result, type],
        [] as string[],
      )

      const allStores = data.listLocationDetails.nodes.reduce((result, l) => {
        const directors = data.listDirectors.nodes
          .filter((d) =>
            l.locationGroups.some((lg) => lg.id === d.locationGroupId),
          )
          .sort((a, b) => {
            const locationGroupA = l.locationGroups.find(
              (lg) => lg.id === a.locationGroupId,
            )
            const locationGroupB = l.locationGroups.find(
              (lg) => lg.id === b.locationGroupId,
            )

            if (!locationGroupA || !locationGroupB) return 0

            return moment
              .utc(locationGroupA.end_date)
              .isSameOrBefore(moment.utc(locationGroupB.end_date))
              ? 1
              : -1
          })
        const director = directors[0]
        const queryObject = new URLSearchParams(search)

        queryObject.set('store', l.id.toString())

        const locationInfo = {
          id: l.id,
          code: l.code,
          name: l.name,
          displayName: `${l.code} - ${l.name}`,
          link: `/${brand}/store_detail?${queryObject.toString()}`,
          groupBy: !director
            ? undefined
            : {
                id: director.employeeId,
                displayName: `${director.firstName} ${director.lastName}`,
                link: DISABLE_GROUP_BY_LINK.includes(brand)
                  ? undefined
                  : `/${brand}/employee_profile/${director.employeeId}`,
              },
        }

        return {
          ...result,
          [locationInfo.id]: {
            ...locationInfo,
            tableRow: {
              location: {
                id: locationInfo.id,
                label: locationInfo.displayName,
                link: locationInfo.link,
              },
              groupBy: !locationInfo.groupBy
                ? undefined
                : {
                    id: locationInfo.groupBy.id,
                    label: locationInfo.groupBy.displayName,
                    link: locationInfo.groupBy.link,
                  },
            },
          },
        }
      }, {})

      const values = [
        ...(HAS_COMPANY.includes(brand)
          ? [
              {
                id: 'Brand Breakdown',
                parentId: 'root',
                displayName: 'Company',
                type: 'locationGroup' as const,
                locationGroupId: BRAND_LOCATION_GROUP_ID[brand],
                locationGroup: {
                  id: BRAND_LOCATION_GROUP_ID[brand],
                  displayName: 'Company',
                },
              },
            ]
          : []),
        {
          id: 'all-stores',
          parentId: 'root',
          displayName: 'All Stores',
          type: 'listLocation' as const,
          tableColumns: [
            {
              key: 'location',
              title: 'Stores',
              onClick: 'track.locationClicked',
              width: WIDTHS?.[brand]?.[0] || '200px',
            },
            {
              key: 'groupBy',
              title: directorLabel,
              onClick: 'track.groupByClicked',
              width: WIDTHS?.[brand]?.[1] || '200px',
            },
          ] as const,
          locationGroupIds: [BRAND_LOCATION_GROUP_ID[brand]],
          locations: allStores,
        },
        ...locationGroupTypes.map((locationGroupType) => [
          {
            id: locationGroupType,
            parentId: 'root',
            displayName: locationGroupType,
          },
          {
            id: `${locationGroupType} Breakdown`,
            parentId: locationGroupType,
            displayName: `Breakdown By ${locationGroupType}`,
            type: 'listLocationGroup' as const,
            tableColumns: [
              {
                key: 'locationGroup',
                title: locationGroupType,
                width: WIDTHS?.[brand]?.[0] || '200px',
              },
            ] as const,
            locationGroupIds: locationGroups
              .filter((l) => l.type === locationGroupType)
              .map((d) => d.id),
            locationGroups: locationGroups
              .filter((l) => l.type === locationGroupType)
              .reduce((result, d) => {
                const director = data.listDirectors.nodes.find(
                  (l) => l.locationGroupId === d.id,
                )
                const locationGroupInfo = {
                  id: d.id,
                  displayName: d.name,
                  link:
                    !director || DISABLE_GROUP_BY_LINK.includes(brand)
                      ? undefined
                      : `/${brand}/employee_profile/${director.employeeId}`,
                }

                return {
                  ...result,
                  [locationGroupInfo.id]: {
                    ...locationGroupInfo,
                    tableRow: {
                      locationGroup: {
                        label: locationGroupInfo.displayName,
                        link: locationGroupInfo.link,
                      },
                    },
                  },
                }
              }, {}),
          },
        ]),
        locationGroups.map(({ id, type, name }) => ({
          id: id.toString(),
          parentId: type,
          displayName: name,
          type: 'listLocation' as const,
          tableColumns: [
            {
              key: 'location',
              title: 'Stores',
              onClick: 'track.locationClicked',
              width: WIDTHS?.[brand]?.[0] || '200px',
            },
            {
              key: 'groupBy',
              title: directorLabel,
              onClick: 'track.groupByClicked',
              width: WIDTHS?.[brand]?.[1] || '200px',
            },
          ] as const,
          locationGroupIds: [id],
          locations: data.listLocationDetails.nodes
            .filter((l) => l.locationGroups.some((lg) => lg.id === id))
            .reduce((result, l) => {
              const director = data.listDirectors.nodes.find((d) =>
                l.locationGroups.some((lg) => lg.id === d.locationGroupId),
              )
              const queryObject = new URLSearchParams(search)

              queryObject.set('store', l.id.toString())

              const locationInfo = {
                id: l.id,
                code: l.code,
                name: l.name,
                displayName: `${l.code} - ${l.name}`,
                link: `/${brand}/store_detail?${queryObject.toString()}`,
                groupBy: !director
                  ? undefined
                  : {
                      id: director.employeeId,
                      displayName: `${director.firstName} ${director.lastName}`,
                      link: DISABLE_GROUP_BY_LINK.includes(brand)
                        ? undefined
                        : `/${brand}/employee_profile/${director.employeeId}`,
                    },
              }

              return {
                ...result,
                [locationInfo.id]: {
                  ...locationInfo,
                  tableRow: {
                    location: {
                      id: locationInfo.id,
                      label: locationInfo.displayName,
                      link: locationInfo.link,
                    },
                    groupBy: !locationInfo.groupBy
                      ? undefined
                      : {
                          id: locationInfo.groupBy.id,
                          label: locationInfo.groupBy.displayName,
                          link: locationInfo.groupBy.link,
                        },
                  },
                },
              }
            }, {}),
        })),
      ].flat()

      return {
        values:
          locationGroupTypes.length !== 1
            ? values
            : values
                .filter(({ id }) => id !== locationGroupTypes[0])
                .map(({ parentId, ...value }) => ({
                  ...value,
                  parentId:
                    parentId === locationGroupTypes[0] ? 'root' : parentId,
                })),
        defaultValue: [values[0].id],
      }
    }, [data, brand, directorLabel, search]),
    loading,
  }
}

export default useCorporateGroupFilter
