import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { DATE_FORMAT } from 'pared/data/getDateRanges'
import useBrands from 'pared/layouts/hooks/useBrands'

import { useGroupFilter } from '../../groupFilter'
import { useVariables } from '../../variables'
import { IApiDataType } from '../types'

interface IDirectorInfo {
  id: number
  given_name: string
  family_name: string
  preferred_name: string
}

interface ILocationInfo {
  id: number
  code: string
  name: string
  director: IDirectorInfo
}

interface IDeliveryMetrics {
  inaccurate_order_percent?: number
  cancelled_order_percent?: number
  delayed_order_percent?: number
}

interface ILocationDeliveryMetrics extends ILocationInfo, IDeliveryMetrics {}

interface ICorporateDeliverySummaryDataType {
  locations: ILocationDeliveryMetrics[]
  type: string
}

interface IDeliverySummaryType {
  corporateUDeliverySummary: {
    nodes: ICorporateDeliverySummaryDataType[]
  }
}

const query = gql`
  query GetCorporateDeliverySummary(
    $iLocationGroupId: Int!
    $iBrandLocationGroupId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    corporateUDeliverySummary(
      iLocationGroupId: $iLocationGroupId
      iBrandLocationGroupId: $iBrandLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        type
        locations
      }
    }
  }
`

const formatIssueTypeString = (typeStr: string) => {
  switch (typeStr) {
    case 'inaccurate':
      return 'Accuracy'
    case 'cancelled':
      return 'Cancellations'
    case 'delayed':
      return 'Lateness'

    default:
      return typeStr
  }
}

export const corporateExpoAiDeliverySummaryConfigs = {
  issueType: 'string',
  storeCount: 'string',
  issuePercent: 'percent',
} as const

const createCorporateExpoAiDeliverySummaryHook = (
  isLocationDetails: boolean,
) => {
  const useCorporateExpoAiDeliverySummaryData = () => {
    const { variables } = useVariables()
    const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
    const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
    const { groupFilter } = useGroupFilter()
    const locationGroupId = groupFilter?.ids[0] || 0
    const { brand } = useBrands()
    const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
    const { data, loading } = useQuery<IDeliverySummaryType>(query, {
      variables: {
        iLocationGroupId: locationGroupId,
        iBrandLocationGroupId: brandLocationGroupId,
        iStartDate: startDate,
        iEndDate: endDate,
      },
      skip: !startDate || !endDate || !groupFilter,
    })

    return {
      data: useMemo((): IApiDataType => {
        if (isLocationDetails) {
          const summary = data?.corporateUDeliverySummary?.nodes
          const issueType = variables?.aiSummary['uDelivery']?.clickedId
          const locationDetails = summary?.find(
            (s) => formatIssueTypeString(s.type) === issueType,
          )

          return {
            source:
              locationDetails?.locations?.map((l) => {
                const groupInfo = groupFilter?.list?.find((g) => g.id == l.id)

                return {
                  groupInfo,
                  issuePercent:
                    (l.inaccurate_order_percent ||
                      l.cancelled_order_percent ||
                      l.delayed_order_percent ||
                      0) * 100.0,
                }
              }) || [],
          }
        } else {
          const summary = data?.corporateUDeliverySummary?.nodes

          return {
            source:
              summary?.map((s) => ({
                ...s,
                issueType: formatIssueTypeString(s.type),
                storeCount: `${s.locations.length} stores`,
              })) || [],
          }
        }
      }, [data, variables, groupFilter]),
      loading,
    }
  }

  return useCorporateExpoAiDeliverySummaryData
}

export const useCorporateExpoAiDeliverySummary =
  createCorporateExpoAiDeliverySummaryHook(false)
export const useCorporateExpoAiDeliveryLocationDetails =
  createCorporateExpoAiDeliverySummaryHook(true)
