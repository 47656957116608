import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import navigator from 'pared/Routes/navigator'
import { track } from 'pared/analytics/user'
import COLORS from 'pared/constants/colors'
import ParedLogo from 'pared/images/Expo/expo-logo-blue.png'
import { generateEnterKeyPressedHandler } from 'pared/utils/keyboard'

interface IProps {
  isLoading: boolean
  onSignIn: (email: string, password: string) => Promise<void>
  errorMessage: string
  onClickContinue: (email: string) => Promise<boolean>
  brand: string
  isFirstPage: boolean
  onSetIsFirstPage: (shouldDisplayFirstPage: boolean) => void
  onSetErrorMessage: (message: string) => void
}

function WebLargeMain({
  isLoading,
  onSignIn,
  errorMessage,
  onClickContinue,
  brand,
  isFirstPage,
  onSetIsFirstPage,
  onSetErrorMessage,
}: IProps) {
  const [email, setEmail] = useState<string>('')
  const location = useLocation()

  const onEmailChange = (event: any) => {
    setEmail(event.target.value)
  }

  const onClickContinueButton = async () => {
    const continueResult = await onClickContinue(email)

    if (continueResult) {
      onSetIsFirstPage(false)
    }

    track.forgotPasswordEmailContinueClicked(email)
  }

  const onClickReEnterEmail = () => {
    onSetIsFirstPage(true)
    setEmail('')
    onSetErrorMessage('')
  }

  const onEnterKeyPressed = generateEnterKeyPressedHandler(
    onClickContinueButton,
  )

  const content = isFirstPage ? (
    <>
      <TitleContainer>Log In Without Password</TitleContainer>
      <br />
      <SubtitleText>
        We'll email you a link for password-free sign in
      </SubtitleText>
      <br />
      <br />
      <SignInForm>
        <StyledTextField
          type="email"
          label="Email"
          size="medium"
          fullWidth={true}
          onChange={onEmailChange}
          onKeyPress={onEnterKeyPressed}
        />
        <br />
        <br />
        <br />
        {isLoading ? (
          <SubmittingButton>Sending ...</SubmittingButton>
        ) : (
          <ContinueButton disabled={isLoading} onClick={onClickContinueButton}>
            Continue
          </ContinueButton>
        )}
      </SignInForm>
      {errorMessage ? (
        <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
      ) : null}
    </>
  ) : (
    <>
      <TitleContainer>Check your email!</TitleContainer>
      <br />
      <SubtitleText>
        {'To login password-free, tap the button in the email we sent to '}
        <SubtitleBoldText>{email}</SubtitleBoldText>
      </SubtitleText>
      <br />
      <br />
      <SubtitleText>
        {'Wrong email address? Please '}
        <SubtitleLinkText onClick={onClickReEnterEmail}>
          {'re-enter email address'}
        </SubtitleLinkText>
      </SubtitleText>
      <br />
      <br />
    </>
  )

  return (
    <OuterContainer>
      <InnerContainer>
        {content}

        {/* <SignUpLinkDiv>
          <StyledLink to={navigator.signUp()}>Sign Up</StyledLink>
        </SignUpLinkDiv> */}

        <BackToSignInDiv>
          <StyledLink
            to={{
              pathname: navigator.signIn(brand),
              state: location.state,
            }}
          >
            Back To Sign In
          </StyledLink>
        </BackToSignInDiv>

        <PowerdByContainer>
          <PowerdByText>powered by</PowerdByText>
        </PowerdByContainer>

        <ParedLogoContainer>
          <ParedLogoImg src={ParedLogo} alt="Pared" />
        </ParedLogoContainer>
      </InnerContainer>
    </OuterContainer>
  )
}

const StyledTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#0071E3',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0071E3',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#0071E3',
      },
    },
  },
})(TextField)

const StyledLink = styled(Link)`
  color: #07a4c7;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`

const OuterContainer = styled.div`
  margin: 0;
  padding-left: 30px;
  padding-right: 30px;
  width: 100vw;
  height: 100vh;
  display: table;
`

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
`

const TitleContainer = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
`

const SubtitleText = styled.text`
  font-family: Lexend-Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
`

const SubtitleBoldText = styled(SubtitleText)`
  font-family: Lexend-Semibold;
  font-weight: 700;
`

const SubtitleLinkText = styled(SubtitleText)`
  color: ${COLORS.Expo};
  cursor: pointer;
`

const SignInForm = styled.div`
  display: inline-block;
  width: 250px;
`

const ErrorMessageContainer = styled.div`
  color: red;
  padding: 20px 0 0 0;
`

const ContinueButton = styled.button`
  height: 41px;
  width: 141px;
  background-color: ${COLORS.Expo};
  color: ${COLORS.Salt};
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  border: none;
  cursor: pointer;
`

const SubmittingButton = styled.button`
  height: 41px;
  width: 141px;
  background-color: ${COLORS.Expo};
  color: ${COLORS.Salt};
  opacity: 0.7;
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  border: none;
`

const PowerdByContainer = styled.div`
  text-align: center;
  padding: 120px 0 0 0;
`

const PowerdByText = styled.div`
  display: inline-block;
  width: 300px;
  padding: 20px 0 0 0;
  border-top: 1px solid #bcbcbc;
  font-family: Lexend-Regular;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
`

const ParedLogoContainer = styled.div`
  padding: 8px 0 0 0;
`

const ParedLogoImg = styled.img`
  width: 80px;
`

const BackToSignInDiv = styled.div`
  padding: 60px 0 0 0;
  font-size: 12px;
  color: ${COLORS.Steel};
`

const ForgetPasswordTitle = styled.div`
  font-weight: 600;
`

const ForgetPasswordBody = styled.div`
  padding: 10px 0 0 0;
`

export default WebLargeMain
