import { IConfigsType } from '../../types'

const burgerworksInventory: IConfigsType = {
  burgerworks: {
    '/:brand/corporate_inventory': {
      variables: [
        'corporateGroup',
        {
          type: 'items',
          initialSelection: ['1', 'category-1'],
          categoriesOnly: true,
          root: 'EXPO_COGS_CATEGORY_ROOT',
        },
      ],
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: ['last_week', 'week'],
        defaultType: 'last_week',
      },
      title: {
        type: 'title',
        title: 'COGS Tracker',
      },
      kpis: {
        type: 'list',
        api: 'burgerworksListItemMetricValues',
        fieldWidth: '160px',
        fields: [
          {
            content: {
              key: 'actualCogs',
            },
            bottom: 'Actual COGS',
          },
        ],
      },
      categoryFilter: {
        type: 'grid',
        children: {
          title: {
            type: 'title',
            title: 'Inventory',
          },
          columns: {
            type: 'select',
            value: 'items.value',
            onChange: 'items.onChange',
            options: 'items.options',
            label: 'Category',
          },
        },
      },
      breakdownByStore: {
        type: 'table-v2',
        api: 'inventory',
        sorting: [
          {
            id: '<%- corporateGroup?.tableColumns[0].key %>',
            desc: true,
          },
        ],
        rowPinning: {
          top: ['summary'],
        },
        columns: [
          {
            key: 'itemName',
            title: 'Item',
            sortable: true,
          },
          {
            key: 'displayParentCategoryName',
            title: 'Category',
            sortable: true,
          },
          {
            key: '<%- JSON(corporateGroup?.tableColumns) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            sortable: true,
          },
          {
            key: 'actualCogs',
            title: 'Actual COGS',
            sortable: true,
            unit: 'dollar',
          },
        ],
      },
    },
  },
}

export default burgerworksInventory
