import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { BRAND_ID } from 'pared/constants/brands'
import useBrands from 'pared/layouts/hooks/useBrands'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { IApiDataType } from '../../types'

interface IGuestSummaryNodeType {
  problemPercentage: number
  mostCommonProblem: string[]
  mostCommonDay: string[]
  mostCommonDaypart: string[]
}

interface IGuestSummaryType {
  locationGuestSummaryV2: {
    nodes: IGuestSummaryNodeType[]
  }
  listLocationGroupMetricValues: {
    nodes: {
      metricSummaryData: Record<
        string,
        {
          name: string
          unit: 'CENT' | 'PERCENTAGE' | 'DOLLAR' | 'COUNT' | 'SECONDS' | 'SCORE'
          value: number
        }
      >
    }[]
  }
  listLocationMetricValues: {
    nodes: {
      metricSummaryData: Record<
        string,
        {
          name: string
          unit: 'CENT' | 'PERCENTAGE' | 'DOLLAR' | 'COUNT' | 'SECONDS' | 'SCORE'
          value: number
        }
      >
    }[]
  }
}

const query = gql`
  query LocationGuestSummaryV2(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationId: Int!
    $iBrandId: Int!
  ) {
    locationGuestSummaryV2(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iLocationId: $iLocationId
    ) {
      nodes {
        problemPercentage
        mostCommonProblem
        mostCommonDay
        mostCommonDaypart
      }
    }

    listLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: {
        location_group_ids: [$iBrandId]
        metrics: ["smg_overall_satisfaction_rating"]
      }
    ) {
      nodes {
        metricSummaryData
      }
    }

    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: {
        location_ids: [$iLocationId]
        metrics: ["smg_overall_satisfaction_rating"]
      }
    ) {
      nodes {
        metricSummaryData
      }
    }
  }
`

const useLocationGuestAiSummary = (): IApiDataType => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const { brand } = useBrands()
  const { data, loading } = useQuery<IGuestSummaryType>(query, {
    variables: {
      iLocationId: groupFilter?.ids?.[0],
      iBrandId: BRAND_ID[brand],
      iStartDate: startDate,
      iEndDate: endDate,
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo(() => {
      const summary = data?.locationGuestSummaryV2.nodes[0]

      const summaryMessage = (() => {
        if (!summary)
          return 'Great job!  None of your SMG responses had a problem!'

        if (summary.problemPercentage)
          return `${summary.problemPercentage}% of your SMG responses had a problem.`

        const storeSmgOverallSatisfactionRating =
          data?.listLocationMetricValues.nodes[0]?.metricSummaryData
            ?.smg_overall_satisfaction_rating.value
        const corporateSmgOverallSatisfactionRating =
          data?.listLocationGroupMetricValues.nodes[0]?.metricSummaryData
            ?.smg_overall_satisfaction_rating.value

        if (
          typeof storeSmgOverallSatisfactionRating === 'number' &&
          typeof corporateSmgOverallSatisfactionRating === 'number'
        ) {
          return `The store's SMG Overall Satisfaction Rating is ${(() => {
            if (
              storeSmgOverallSatisfactionRating >
              corporateSmgOverallSatisfactionRating
            )
              return 'higher than'
            if (
              storeSmgOverallSatisfactionRating <
              corporateSmgOverallSatisfactionRating
            )
              return 'lower than'

            return 'the same as'
          })()} the systemwide average.`
        }

        return 'There are no SMG comments.'
      })()
      const improvementMessage = (() => {
        if (!summary) return null

        let topProblems = ''
        if (summary.mostCommonProblem?.length === 1) {
          topProblems = `The top problem was: ${summary.mostCommonProblem[0]}.`
        } else if (summary.mostCommonProblem?.length > 1) {
          topProblems = `The top problems were: ${summary.mostCommonProblem?.join(
            ', ',
          )}.`
        }
        const mostCommonDay = !summary.mostCommonDay
          ? ''
          : `${summary.mostCommonDay
              .map((day: string) => `${day}s`)
              .join(', ')} have the most negative reviews.`

        const mostCommonDaypart = !summary.mostCommonDaypart
          ? ''
          : `${summary.mostCommonDaypart.join(', ')} ${
              summary.mostCommonDaypart.length > 1 ? 'have' : 'has'
            } the most negative reviews.`

        return topProblems + mostCommonDay + mostCommonDaypart
      })()

      return {
        conclusion: summaryMessage,
        insight: improvementMessage,
      }
    }, [data]),
    loading,
  }
}

export default useLocationGuestAiSummary
