import { gql, useMutation } from '@apollo/client'
import _ from 'lodash'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { INavParams } from 'pared/Routes/navParams'
import { getBrandFromPathname } from 'pared/utils/brand'
import { signInUser } from 'pared/utils/user'

import Main from './Main'

const MUTATION_AUTHENTICATE_USER = gql`
  mutation AuthenticateUser($email: String!, $password: String!) {
    authenticate(input: { iEmail: $email, iPassword: $password }) {
      jwtToken
    }
  }
`

interface IProps {
  navParams: INavParams
}

function SignIn({ navParams }: IProps) {
  const pagePathname = _.get(navParams, 'pagePathname', '')
  const brand = getBrandFromPathname(pagePathname)
  const location = useLocation<{ signInMagicLinkError?: string }>()
  const [errorMessage, setErrorMessage] = useState<string>(
    location.state?.signInMagicLinkError || '',
  )
  const [authenticateUser, { loading, error }] = useMutation(
    MUTATION_AUTHENTICATE_USER,
  )

  const onSignIn = async (email: string, password: string) => {
    const formattedEmail = (email || '').trim().toLowerCase()

    if (!formattedEmail) {
      setErrorMessage('The email is required.')
      return false
    }

    if (!password) {
      setErrorMessage('The password is required.')
      return false
    }

    try {
      const response = await authenticateUser({
        variables: {
          password,
          email: formattedEmail,
        },
      })

      if (response.data?.authenticate?.jwtToken) {
        await signInUser(response.data.authenticate.jwtToken, formattedEmail)
        return true
      } else {
        setErrorMessage('The email or the password may be incorrect.')
        return false
      }
    } catch (error: any) {
      setErrorMessage(error || 'The email or the password may be incorrect.')
      return false
    }
  }

  return (
    <Main
      isLoading={loading}
      onSignIn={onSignIn}
      errorMessage={error?.message || errorMessage}
      brand={brand}
    />
  )
}

export default SignIn
